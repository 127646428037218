import React from 'react';

import './style.scss';
import DeleteAccountCard from '../DeleteAccountCard';

const DeleteAccountPopup = () => (
  <div className='pst-deleteAccountPopup'>
    <div className='pst-deleteAccountPopup__inner'>
      <DeleteAccountCard />
    </div>
  </div>
);

export default DeleteAccountPopup;
