import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { notNullString, passHasNumber, passHasUppercase, tooShortPass } from '@helpers/validationRules';
import { ValidationInput } from '@app/components/NEW_ui/Input';
import setLanguage from '@app/helpers/setLanguage';
import getQueryParam from '@app/helpers/parseQueryUrl';
import AuthFormContainer from './AuthFormContainer';
import { checkToken, setNewPass } from '../redux/actions';

class AuthRestore extends Component {
  static propTypes = {
    checkToken: PropTypes.func.isRequired,
    setNewPass: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { match: { params: { token } } } = props;
    const source = getQueryParam('source');

    this.state = {
      disabled: false,
      newPassword: '',
      token,
      source,
    };
  }

  async componentDidMount() {
    const { token } = this.state;
    const { history } = this.props;

    if (token) {
      try {
        await this.props.checkToken({ token });
      } catch (e) {
        history.push('/error/404');
      }
    }
  }

  handleError = (hasError) => {
    this.setState({ disabled: hasError });
  };

  resetPassword = async (formData) => {
    const { history, t } = this.props;
    const { token, source } = this.state;
    const { password } = formData;

    try {
      await this.props.setNewPass({ token, password });
      // eslint-disable-next-line no-alert
      await alert(t('auth.passwordChanged'));
    } finally {
      if (!source || source === 'desktop') {
        history.push('/');
      } else {
        history.push('/auth/use-app');
      }
    }
  };

  renderFields = fields => fields.map(i => <ValidationInput
    id={i.name}
    key={i.name}
    className='pst-login__field'
    {...i}
  />);

  handleChangeLanguage = (name, value) => {
    const { i18n } = this.props;
    setLanguage(i18n, value);
  };

  render() {
    const { t } = this.props;
    const { disabled } = this.state;

    const fields = [
      {
        name: 'password',
        type: 'password',
        label: t('auth.newPassword'),
        mode: 'normal',
        onChange: e => this.setState({ newPassword: e.target.value }),
        validators: [notNullString, tooShortPass, passHasUppercase, passHasNumber],
        errorMessages: [t('auth.fieldEmpty'), t('auth.tooShortPass'), t('auth.hasUppercase'), t('auth.hasNumber')],
      },
      {
        name: 'confirmPassword',
        type: 'password',
        label: t('auth.confirmPassword'),
        mode: 'normal',
        validators: [notNullString, tooShortPass, passHasUppercase, passHasNumber, value => value === this.state.newPassword],
        errorMessages: [t('auth.fieldEmpty'), t('auth.tooShortPass'), t('auth.hasUppercase'), t('auth.hasNumber'), t('auth.mismatch')],
      },
    ];

    return (
      <AuthFormContainer
        onSubmit={this.resetPassword}
        onError={this.handleError}
        changeLanguage={this.handleChangeLanguage}
        buttonDisabled={disabled}
        buttonText={t('auth.changePassword')}
        showPasswordNotice
      >
        {this.renderFields(fields)}
      </AuthFormContainer>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ checkToken, setNewPass }, dispatch);
export default withTranslation()(connect(null, mapDispatchToProps)(AuthRestore));
