import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getClassName from '@helpers/getClassName';
import { Input, ValidationInput } from '../Input';

export default class FormComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    onError: PropTypes.func,
    checkOnSubmit: PropTypes.bool,
  };

  static defaultProps = {
    checkMode: 'change',
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      isDisabledSubmit: false,
      hasError: false,
    };

    this.inputsRef = {};
  }

  changeHandler = (e) => {
    const { onError, checkOnSubmit } = this.props;
    const { name, value } = e.target;
    const { isDisabledSubmit } = this.state;

    const updatedControls = {
      ...this.state.formData,
      ...{ [name]: value },
    };

    if ((checkOnSubmit && isDisabledSubmit) || checkOnSubmit === false) {
      const hasError = Object.keys(this.inputsRef)
        .map(inputKey => this.inputsRef[inputKey].current.validate().error)
        .includes(true);

      if (hasError && checkOnSubmit) {
        Object.keys(this.inputsRef)
          .forEach((inputKey) => {
            this.inputsRef[inputKey].current.showError();
          });
      }

      this.setState({ hasError }, () => {
        if (onError) {
          onError(hasError);
        }
      });
    }

    this.setState({
      formData: updatedControls,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, onError, checkOnSubmit } = this.props;
    const { formData } = this.state;

    const submit = () => onSubmit && onSubmit(formData);

    if (checkOnSubmit) {
      const hasError = Object.keys(this.inputsRef)
        .map(inputKey => this.inputsRef[inputKey].current.validate().error)
        .includes(true);

      this.setState({ hasError }, () => {
        if (onError) {
          onError(hasError);
        }
      });

      this.setState({ isDisabledSubmit: hasError });

      if (hasError) {
        Object.keys(this.inputsRef)
          .forEach((inputKey) => {
            this.inputsRef[inputKey].current.showError();
          });
      } else {
        submit();
      }
    } else {
      submit();
    }
  };

  renderChildren() {
    const mapChildren = children => React.Children.map(children, (child) => {
      // it might be better to use react-children-utilities
      if (!React.isValidElement(child)) return child;

      let newChildren;
      if (child.props && Array.isArray(child.props.children)) {
        newChildren = mapChildren(child.props.children);
      }

      if (child.type === Input || child.type === ValidationInput) {
        const ref = React.createRef();
        // Store links to fields. The key is the field name
        this.inputsRef[child.props.name] = ref;

        return React.cloneElement(child, {
          ref,
          showError: !this.props.checkOnSubmit,
          onChange: (e) => {
            this.changeHandler(e);
            if (child.props.onChange) child.props.onChange(e);
          },
          ...newChildren ? { children: newChildren } : {},
        });
      }

      return newChildren ? React.cloneElement(child, { children: newChildren }) : child;
    });

    return mapChildren(this.props.children);
  }

  render() {
    const { style, className } = this.props;
    return (
      <form
        className={getClassName(['pst-form', className])}
        onSubmit={this.handleSubmit}
        style={style}>
        {this.renderChildren()}
      </form>
    );
  }
}
