import { useState, useEffect } from 'react';

const useTouch = (timeout = 3000, exclude = []) => {
  const isClient = typeof window === 'object';
  const [touch, setTouch] = useState(true);
  let timerId;
  let secondTimerId;

  function handleTouch(e) {
    if (exclude.length && exclude.find(item => item.current && item.current.contains(e.target))) return;
    clearTimeout(timerId);
    setTouch(!touch);

    if (touch) {
      timerId = setTimeout(() => {
        setTouch(false);
      }, timeout);
    }
  }

  useEffect(() => {
    if (!isClient) return false;

    secondTimerId = setTimeout(() => {
      setTouch(false);
    }, timeout);

    window.addEventListener('mousedown', handleTouch);
    return () => {
      window.removeEventListener('mousedown', handleTouch);
      clearTimeout(timerId);
      clearTimeout(secondTimerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touch]);
  return touch;
};

export default useTouch;
