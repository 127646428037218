import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import Icon from '../Icon';
import Text from '../Text';
import withClassname from '../../../helpers/hocs/withClassname';

const BackButton = ({ children, className, link, onClick }) => {
  const Component = link ? Link : 'div';
  return (
    <Component to={link} className={`pst-backButton ${className}`} onClick={onClick}>
      <Icon name="BackArrow" size="sm" className="pst-backButton__icon" />
      <Text weight="700" level="6" template="grey">{children}</Text>
    </Component>
  );
};
export default withClassname(BackButton);
