import React from 'react';
import PropTypes from 'prop-types';

import RoundButton from '@app/components/NEW_ui/RoundButton';
import PaymentTicket from './components/PaymentTicket';
import StoryTypeSelect from './components/StoryTypeSelect';

import './style.scss';

const PaymentPopup = ({ priceMonth, discountMonth, priceYear, discountYear, closePopup, actions, /* isBought, storyId, */ buyAction }) => {
  // /*...no interactive tasks yet...*/
  // const wasShown = localStorage.getItem(`ticket_${storyId}`);
  // const initialShow = isBought ? false : !wasShown;
  // const [showTicket, handleShowTicket] = useState(initialShow);
  // /*...no interactive tasks yet...*/

  const handleClose = () => {
    // /*...no interactive tasks yet...*/
    // localStorage.setItem(`ticket_${storyId}`, true);
    // handleShowTicket(false);
    // /*...no interactive tasks yet...*/
    closePopup();
  };

  const handleBuyAction = (unit) => {
    buyAction(unit);
  };

  return (
    <div className="pst-paymentPopup">
      <div className="pst-paymentPopup__content">
        <div className="pst-paymentPopup__selectType">
          <StoryTypeSelect story={actions.story} interactive={actions.interactive} />
        </div>
        <PaymentTicket priceMonth={priceMonth} discountMonth={discountMonth} priceYear={priceYear} discountYear={discountYear} buyAction={handleBuyAction} handleClose={handleClose} />
      </div>

      <div className="pst-paymentPopup__closeBtn">
        <RoundButton name="Close" size="sm" onClick={closePopup} />
      </div>
    </div>
  );
};

PaymentPopup.defaultProps = {
  priceMonth: 0,
  discountMonth: 0,
  priceYear: 0,
  discountYear: 0,
  buyAction: () => { },
  actions: {
    story: () => { },
    interactive: () => { },
  },
};

PaymentPopup.propTypes = {
  priceMonth: PropTypes.number,
  discountMonth: PropTypes.number,
  priceYear: PropTypes.number,
  discountYear: PropTypes.number,
  buyAction: PropTypes.func,
  actions: PropTypes.exact({
    story: PropTypes.func,
    interactive: PropTypes.func,
  }),
  isBought: PropTypes.bool,
  storyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PaymentPopup;
