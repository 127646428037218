import genderMap from '@app/store/constants/genderMap';
import { replaceTtsTags } from './replaceTtsTags';
import getNameDeclension from './getNameDeclension';

export default (html, contains, content, topic) => {
  const { gender } = topic;
  const detectedGender = genderMap[gender] || 'androgynous';
  let result = html;

  for (let j = 0; j < contains.length; j++) {
    const item = contains[j];
    // If value is defined by default in topic.json, use it
    let _value = item.value;
    let _template = item.template;

    // If value is not defined by default in topic.json obtain value from answers
    if (!_value && item.param) {
      const param = content.find(_i => _i.id === item.param);

      if (param) _value = param.value;
    }

    // If template is defined by default in topic.json obtain values from answers and push in template
    if (_template && !_value && !item.param) {
      _template = replaceTtsTags(_template);
      const variables = _template.match(/%{(.*?)}%/g);

      if (variables && variables.length) {
        variables.forEach((matchValue) => {
          const parsedParam = matchValue.replace(/%{|}%/g, '');

          const hasCase = parsedParam.split(/__/);
          if (hasCase.length > 1) {
            const paramValue = hasCase[0];
            const caseName = hasCase[1];
            const parsedGender = hasCase[2];

            const contValue = content.find(({ id }) => id === paramValue);
            const replacement = contValue ? contValue.value : '';
            const finalGender = parsedGender || detectedGender;

            const nameDeclension = getNameDeclension(finalGender, replacement, caseName);

            _template = _template.replace(new RegExp(`%{${parsedParam}}%`, 'g'), nameDeclension);
          } else {
            const contValue = content.find(({ id }) => id === parsedParam);
            const replacement = contValue ? contValue.value : '';
            _template = _template.replace(new RegExp(`%{${parsedParam}}%`, 'g'), replacement);
          }
        });
      }

      _value = _template;
    }

    if (_value) result = result.replace(new RegExp(`{%${item.field}%}`, 'g'), _value);
  }

  return result;
};
