import React from 'react';

export default () => (
  <svg
    height="15"
    className="icon icon-angle"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="icon__zone"
      d="M7 2.00002L18 12.1064L7 22"
      stroke="#721FD9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
