import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TabPane } from '../Tabs';
import Icon from '../Icon';
import Button from '../Button';
import Switcher from '../Input/Switcher';

import './style.scss';

const FixedMenu = ({ showTab, changeTab, addTag, removeTag, tags, selectedTags }) => {
  const { t } = useTranslation();
  const [showTags, setShowTags] = useState(false);

  const handleFilterTag = (title) => {
    if (!selectedTags.includes(title)) {
      addTag(title);
    } else {
      removeTag(title);
    }
  };

  const handleChangeTab = (value) => {
    if (!value) changeTab('tab_1');
    else if (value) changeTab('tab_2');
  };

  return (
    <div className='pst-fixedMenu'>
      <div className='pst-fixedMenu__switcher'>
        <Switcher
          initValue={showTab === 'tab_2'}
          labels={{
            first: t('fixedMenu.new'),
            second: t('fixedMenu.my'),
          }}
          onClick={handleChangeTab}
        />
      </div>

      {(showTab === 'tab_1' && tags.length > 0) && <div>
        <div className='pst-fixedMenu__tagsToggle'>
          <TabPane
            onClick={() => setShowTags(!showTags)}
            active={false}
          >
            <span className='pst-fixedMenu__toggleText'>{`${t('fixedMenu.tags')} ${selectedTags.length ? `(${selectedTags.length})` : ''}`}</span>
            <Icon name='Arrow' direction={`${showTags ? 'up' : 'down'}`} size='sm' template='gray' />
          </TabPane>
        </div>

        {showTags && <div className='pst-fixedMenu__tagsList'>
          {tags && tags.map((item, index) => (
            <div
              className='pst-fixedMenu__tag'
              key={index}
            >
              <Button
                template='select-small'
                onClick={() => handleFilterTag(item.title)}
                active={selectedTags.includes(item.title)}
              >
                {item.title}
              </Button>
            </div>
          ))}
        </div>}
      </div>}
    </div>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  selectedTags: dashboard.filters.tags,
});

export default connect(mapStateToProps)(FixedMenu);
