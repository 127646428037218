import React from 'react';

import {
  FacebookShareButton,
  TelegramShareButton,
  VKShareButton,
  OKShareButton,
} from 'react-share';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';

import SocialBtn from '@app/components/NEW_ui/SocialBtn';
import Icon from '../Icon';

import './style.scss';

const socialBtns = [
  { EL: VKShareButton, icon: 'Vk', color: '#5181B8', title: 'Индивидуальные сказки для любимых детей' },
  { EL: FacebookShareButton, icon: 'Facebook', color: '#4267B2', quote: 'Индивидуальные сказки для любимых детей' },
  { EL: TelegramShareButton, icon: 'Telegram', color: '#419FD9', title: 'Индивидуальные сказки для любимых детей' },
  { EL: OKShareButton, icon: 'Ok', color: '#EE8208', title: 'Индивидуальные сказки для любимых детей' },
];

export default ({ useMixpanel, user }) => {
  const shareUrl = window.location.origin;

  const handleShare = (social) => {
    if (useMixpanel) {
      MixpanelService.track(`${mixpanelMap.SHARING_FEEDBACK_FORM} - ${social}`, {
        social,
        userEmail: user.email,
        userName: user.name,
      });
    }
  };

  return (
    <div className='pst-sharings'>
      {socialBtns.map((item, i) => {
        const { EL, icon, color } = item;

        const props = { ...item };
        delete props.EL;
        delete props.icon;

        return (
          <div className='pst-sharings__item' key={i} >
            <SocialBtn template='rounded' onClick={() => handleShare(icon)} bgColor={color}>
              <EL
                className='pst-sharings__button'
                url={shareUrl}
                {...props}
              >
                <Icon name={icon} size='sm' />
              </EL>
            </SocialBtn>
          </div>
        );
      })}
    </div>
  );
};
