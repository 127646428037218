import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWindowScroll from '@app/helpers/hooks/useWindowScroll';

import { retinaDetected } from '@app/helpers/retinaDetected';
import avatar1x from '@app/assets/images/Avatar.png';
import avatar2x from '@app/assets/images/Avatar2x.png';
import Logo from '../Logo/index';
import Text from '../Text';
import AvatarDialog from '../AvatarDialog';
import BackButton from '../BackButton';

import FixedMenu from '../fixedMenu';

import './style.scss';

const retina = retinaDetected(window);
const avatar = retina ? avatar2x : avatar1x;

const LogoPanel = ({
  auth,
  showBackBtn,
  className,
  showFixedMenu,
  showTab,
  changeTab,
  addTag,
  removeTag,
  tags,
  history,
  full,
  showAvatar,
  template,
  fixedScroll,
  mod,
}) => {
  const { t } = useTranslation();
  const [slides, setSlides] = useState([t('logoPanel.slide_1'), t('logoPanel.slide_2'), t('logoPanel.slide_3'), t('logoPanel.slide_4'), t('logoPanel.slide_5')]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const scroll = useWindowScroll();

  const slideUp = () => {
    if (currentSlide !== 0) {
      const spentSlide = slides[currentSlide - 1];
      const newSlides = slides.slice();

      newSlides.push(spentSlide);
      newSlides[currentSlide - 1] = null;

      setSlides(newSlides);
    }
    setCurrentSlide(currentSlide + 1);
  };

  useEffect(() => {
    let timer;

    if (slides.length > 1) {
      timer = setInterval(() => {
        slideUp();
      }, 5000);
    }

    return function cleanup() {
      clearInterval(timer);
    };
    /* eslint-disable-next-line */
  });

  const { user } = auth;
  const userAvatar = user ? user.avatar : undefined;

  return (
    <div className={`pst-logoPanel ${className || ''} ${mod ? `pst-logoPanel--${mod}` : ''}`}>
      <div
        className={`
          pst-logoPanel__inner
          ${(fixedScroll && scroll >= fixedScroll) ? 'pst-logoPanel__inner--fixed' : ''}
          ${mod ? `pst-logoPanel__inner--${mod}` : ''}
        `}
      >
        <div className={`pst-logoWrap pst-logoWrap--${template}`}>
          <Logo className='pst-logoWrap__logo' template={template} />
          {(!showFixedMenu) && <div className='pst-logoWrap__rectangle' />}

          {(!showFixedMenu) && <div className={`pst-logoCarusel pst-logoCarusel--${template}`}>
            {/* eslint-disable-next-line array-callback-return */}
            {slides.map((item, index) => {
              if (item) {
                let offset;
                if (index === currentSlide) offset = 0;
                else if (index > currentSlide) offset = 1;
                else if (index < currentSlide) offset = -1;

                return (
                  <li key={index} className='pst-logoCarusel__carusel-item' style={{ top: `${offset * 20}px` }}>
                    <Text level='5' template={template}>{item}</Text>
                  </li>
                );
              }
            })}
          </div>}

          {showFixedMenu && <FixedMenu showTab={showTab} tags={tags} changeTab={changeTab} addTag={addTag} removeTag={removeTag} />}

          {showBackBtn && <BackButton className="pst-logoWrap__backButton" onClick={() => history.goBack()}>{t('logoPanel.backBtn')}</BackButton>}

        </div>
        {showAvatar && <AvatarDialog size="sm" userAvatarUrl={userAvatar || avatar} full={full} />}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(LogoPanel));
