import JSZip from 'jszip';

export default (stream, isDataUrl) => {
  const zip = new JSZip();

  return zip.loadAsync(stream, { base64: !!isDataUrl }).then((archive) => {
    const _structure = [
      'topic.json', // Topic data with questions
      'meta.json', // Meta data of pastylla
      'content.json', // Content with variables
      'template.json', // Template structure
    ];

    const promises = _structure.map(_file => archive.file(_file).async('text'));

    return Promise.all(promises).then((data) => {
      const [topic, meta, content, template] = data;
      const pastylla = {
        topic: JSON.parse(topic),
        meta: JSON.parse(meta),
        content: JSON.parse(content),
        template: JSON.parse(template),
      };

      const { structure } = pastylla.template;
      const slides = [];

      for (let i = 0; i < structure.length; i++) {
        const slide = structure[i];

        slides.push(archive.file(`templates/${slide._id}.html`).async('text'));
      }

      return Promise.all(slides).then((htmls) => {
        for (let i = 0; i < pastylla.template.structure.length; i++) {
          const slide = pastylla.template.structure[i];

          slide.html = htmls[i];
        }

        return pastylla;
      });
    });
  });
};
