import React from 'react';

export default ({ template }) => {
  let color;

  switch (template) {
    case 'white':
      color = '#ffffff';
      break;
    case 'gray':
      color = '#80868B';
      break;
    case 'black':
      color = '#000000';
      break;
    case 'purple':
      color = '#7421D9';
      break;
    default:
      color = '#7421D9';
  }

  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 15L1 8L8 1" stroke={`${color}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
