import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const RoundLoader = ({ classNames, size, color }) => {
  const className = `pst-roundLoader pst-roundLoader--size_${size} pst-roundLoader--color_${color} ${classNames}`;

  return (
    <div className={className}>
      <div className='pst-roundLoader__loader'></div>
    </div>
  );
};

RoundLoader.defaultProps = {
  classNames: '',
  color: 'gray',
  size: 'small',
};

RoundLoader.propTypes = {
  classNames: PropTypes.string,
  color: PropTypes.oneOf(['white', 'gray', 'reddish-pink', 'purple']),
  size: PropTypes.oneOf(['small', 'medium', 'big', 'large']),
};

export default RoundLoader;
