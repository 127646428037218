import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M12.9165 2.5L17.4998 7.08333" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.50016 12.9167L0.833496 19.1667L7.0835 17.5L2.50016 12.9167ZM2.50016 12.9167L12.9168 2.50004L13.5418 1.87504C14.8075 0.609389 16.8595 0.609388 18.1252 1.87504V1.87504C19.3908 3.14069 19.3908 5.19272 18.1252 6.45837L17.5002 7.08337L10.0002 14.5834" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
