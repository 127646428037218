export default {
  START_USER_SESSION: 'Start User Session.',
  END_USER_SESSION: 'End User Session.',
  REGISTER_USER: 'Reister new user.',
  ADD_TAG: 'Add tag. Type',
  REMOVE_TAG: 'Remove tag. Type',
  CONTINUE_PRESENTATION: 'Story was continued.',
  COMPLETE_PRESENTATION: 'Story has been completed.',
  DOWNLOAD_PRESENTATION: 'Download Story.',
  SELECT_BRIEF: 'Select brief. Type',
  CREATE_PROJECT: 'Create new story.',
  ABORT: 'Abort story.',
  STORY_EDITING: 'Editing Story. Type',
  PAYMENT_STORY: 'Payment Story. Type',
  VIEWING_STORY: 'Viewing Story. Type',
  IDENTIFY_USER: 'IDENTIFY_USER. Type',
  CLOSE_FEEDBACK_FORM: 'Close Feedback Form',
  OPEN_FEEDBACK_FORM: 'Open Feedback Form',
  FEEDBACK_FORM_RATING: 'Feedback Form Change Rating. Rating',
  SUBMIT_FEEDBACK_FORM: 'Submit Feedback Form. Rating',
  SHARING_FEEDBACK_FORM: 'Sharing Feedback Form. Social',
  CHANGE_GENDER: 'Change gender:',
  VIEWER_OPENING: 'Viewer is open',
  BUYING_SUB: 'Buying a subscription to',
  CERTIFICATE_ACTIVATION: 'The certificate has been activated',
  DELETE_ACCOUNT: 'The account has been deleted',
  SHOW_POCHEMUSHA_CHAT: 'Show pochemusha chat',
  ASK_POCHEMUSHA: 'Ask pochemusha',
  USER_REGISTER: 'User register',
};
