import { fetchPresentationData } from '@app/services/PresentationService';
import { getPrice, payment, charge, order } from '@app/services/PaymentService';
import { createQuizAnswers } from '@app/services/QuizService';
import { fetchTopicById, fetchFreeTopicById } from '@app/services/TopicService';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';
import periodsMap from '@app/store/constants/periodsMap';
import isIos from '@helpers/isIos';

import { fetchRoistat } from '@app/services/RoistatService';
import CookiesService, { HIDE_LOCK_SCREEN_AFTER_LOGIN } from '@app/services/CookiesService';

import {
  GET_TOPIC,
  GET_PRESENTATION,
  GET_PRESENTATION_CHARGE,
  ERASE_DATA,
  SET_ORDER,
  SHOW_LOCK_SCREEN,
  FREE_TOPIC,
} from '@app/store/constants/actionTypes';
import { HIDE_QUIZ, SHOW_QUIZ } from '../../../store/constants/actionTypes';
import { incrementQuizCount } from '../../../services/QuizService';
import { FEEDBACK_QUIZ } from '../../../store/constants/quizTypes';

export const fetchQuiz = () => async () => {
  const response = await incrementQuizCount(FEEDBACK_QUIZ);
  return response;
};
export const fetchFreeTopic = topic => async (dispatch) => {
  const id = topic._id;
  const freeTopic = await fetchFreeTopicById(id);
  dispatch({ type: FREE_TOPIC, freeTopic });
};

export const getPresentation = id => async (dispatch) => {
  const presentation = await fetchPresentationData(id);
  dispatch({ type: GET_PRESENTATION, presentation });
  return presentation;
};

export const getTopic = id => async (dispatch) => {
  const topic = await fetchTopicById(id);
  dispatch({ type: GET_TOPIC, topic });
  return topic;
};

export const getPresentationCharge = () => async (dispatch) => {
  const chargeData = await getPrice(isIos(window));
  dispatch({ type: GET_PRESENTATION_CHARGE, chargeData });
};

export const hideLockScreen = () => (dispatch) => {
  dispatch({
    type: SHOW_LOCK_SCREEN,
    show: false,
  });
  CookiesService.setCookie(HIDE_LOCK_SCREEN_AFTER_LOGIN);
};

export const showLockScreen = () => async (dispatch) => {
  const price = await getPrice(isIos(window));
  dispatch({
    type: SHOW_LOCK_SCREEN,
    price,
    show: true,
  });
};

// eslint-disable-next-line no-unused-vars
export const saveQuizAnswer = answer => async (dispatch) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = await createQuizAnswers(answer);
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  // dispatch({
  //   type: HIDE_QUIZ,
  // });
};

export const hideQuiz = () => (dispatch) => {
  dispatch({
    type: HIDE_QUIZ,
  });
};

export const showQuiz = quiz => (dispatch) => {
  dispatch({
    type: SHOW_QUIZ,
    quiz,
  });
};

export const makePayment = (period, ios) => async (dispatch, getState) => {
  const { presentation } = getState().viewer;
  const topic = presentation?.topic;
  const { lang } = getState().auth.user;
  const { chargeOptions } = await charge(period, ios);
  const periodName = periodsMap[period];

  try {
    await payment(chargeOptions, lang);

    const { orders: [activeOrder] } = await order();
    dispatch({ type: SET_ORDER, order: activeOrder });

    fetchRoistat('payment', {
      periodName,
      price: chargeOptions.amount,
    });

    // Yandex ecommerce analitic
    window.dataLayer.push({
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: chargeOptions.invoiceId,
          },
          products: [
            {
              id: chargeOptions.invoiceId,
              name: chargeOptions.description,
              price: chargeOptions.amount,
            },
          ],
        },
      },
    });
    window.dataLayer.push({ event: 'subscription' });

    MixpanelService.track(`${mixpanelMap.PAYMENT_STORY} ${topic?.title ?? 'Payment without specifying a fairy tale'}`, {
      storyTitle: topic?.title,
    });
    MixpanelService.track(`${mixpanelMap.BUYING_SUB} ${periodName}`, {
      periodName,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const eraseData = () => (dispatch) => {
  dispatch({ type: ERASE_DATA });
};
