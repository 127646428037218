import React from 'react';

export default ({ alternative = false, template }) => {
  let color;

  switch (template) {
    case 'white':
      color = '#ffffff';
      break;
    case 'gray':
      color = '#80868B';
      break;
    case 'purple':
      color = '#7421D9';
      break;
    default:
      color = 'black';
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3L21 21" stroke={ alternative ? color : 'black' } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21 3L3 21" stroke={ alternative ? color : '#7421D9' } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
