import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Text from '@app/components/NEW_ui/Text';

import './style.scss';

const Switcher = ({ initValue, labels, classNames, onClick }) => {
  const [value, setValue] = useState(initValue);
  const className = `pst-switcher pst-switcher--${value ? 'second' : 'first'} ${classNames}`;

  const switchValue = () => {
    const switchedValue = !value;

    setValue(switchedValue);
    onClick(switchedValue);
  };

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  return (
    <div className={className} onClick={switchValue}>
      <div className="pst-switcher__piece">
        <Text
          inline
          level="6"
          weight={800}
          template={value ? 'default' : 'white'}
          className="pst-switcher__label"
        >{labels.first}</Text>
      </div>

      <div className="pst-switcher__piece pst-switcher__piece--second">
        <Text
          inline
          level="6"
          weight={800}
          template={value ? 'white' : 'default'}
          className="pst-switcher__label"
        >{labels.second}</Text>
      </div>

      <div className={`pst-switcher__indicator pst-switcher__indicator--${value ? 'second' : 'first'}`} />
    </div>
  );
};

Switcher.propTypes = {
  labels: PropTypes.exact({
    first: PropTypes.string,
    second: PropTypes.string,
  }),
  classNames: PropTypes.string,
  onClick: PropTypes.func,
  initValue: PropTypes.bool,
};

Switcher.defaultProps = {
  initValue: false,
  classNames: '',
  onClick: () => { },
};

export default Switcher;
