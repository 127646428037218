import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@app/i18n/en';
import ru from '@app/i18n/ru';
import getLanguage from '@app/helpers/getLanguage';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ...en,
      ...ru,
    },
    lng: getLanguage(),

    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
