import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Helmet } from 'react-helmet';
import withAuth from '@helpers/hocs/withAuth';
import setMetatags from '@helpers/setMetatags';
import routerNames from '@helpers/routerNames';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';
import addObserverPolyfill from '@helpers/addObserverPolyfill';

import './assets/scss/index.scss';
import '@helpers/cancelScaling';

const PROJECT_NAME = 'Myskazka';

const correctVhOnMobile = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
correctVhOnMobile();
setTimeout(correctVhOnMobile, 400);
setTimeout(correctVhOnMobile, 700);
setTimeout(correctVhOnMobile, 1100);

const mixpanelEndSession = () => {
  MixpanelService.track(mixpanelMap.END_USER_SESSION, { endSessionDate: new Date().toString() }, { transport: 'sendBeacon' });
};

window.addEventListener('unload', mixpanelEndSession);
window.addEventListener('resize', correctVhOnMobile);
addObserverPolyfill();
// фикс кодировки
export default withAuth(({ route }) => {
  const { pathname } = useLocation();
  const helmet = {
    titleTemplate: `%s | ${PROJECT_NAME}`,
    titleAttributes: {
      itemprop: 'name',
      lang: process.env.REACT_APP_DEFAULT_LANGUAGE,
    },
    meta: setMetatags(window),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <Helmet {...helmet} title={routerNames(pathname)} />
      {renderRoutes(route.routes)}
    </React.Fragment>
  );
});
