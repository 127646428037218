const parentPrefix = 'ah';
const childPrefix = 'ah__txt';

const textAutoHeight = (parents, childs) => {
  for (let i = 0, l = parents.length; i < l; i++) {
    parents[i].style.removeProperty('font-size');

    const baseFontSize = parseFloat(window.getComputedStyle(parents[i], null).getPropertyValue('font-size')) / 5.88;
    const parentMaxHeight = parseFloat(window.getComputedStyle(parents[i], null).getPropertyValue('max-height'));
    let curFontSize = baseFontSize;

    if (childs[i].clientHeight > parentMaxHeight) {
      while (childs[i].clientHeight > parentMaxHeight) {
        curFontSize -= 0.0697;
        parents[i].style.fontSize = `${curFontSize}rem`;
      }
    } else {
      parents[i].style.removeProperty('font-size');
    }
  }
};

export const update = (el) => {
  const parents = el.getElementsByClassName(parentPrefix);
  const childs = el.getElementsByClassName(childPrefix);

  textAutoHeight(parents, childs);
  setTimeout(() => textAutoHeight(parents, childs), 900);
};

const hiddeParents = (parents) => {
  for (let i = 0; i < parents.length; i++) {
    const el = parents[i];
    el.style.opacity = '0';
  }
};

const showParents = (parents) => {
  for (let i = 0; i < parents.length; i++) {
    const el = parents[i];
    el.style.transition = 'opacity 0.4s ease';
    el.style.opacity = '1';
  }
};

export const init = (el) => {
  const parents = el.getElementsByClassName(parentPrefix);
  const childs = el.getElementsByClassName(childPrefix);
  hiddeParents(parents);

  setTimeout(() => {
    textAutoHeight(parents, childs);
    showParents(parents);
  }, 300);
  setTimeout(() => textAutoHeight(parents, childs), 1000);
  setTimeout(() => textAutoHeight(parents, childs), 5000);

  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      for (let i = 0, l = parents.length; i < l; i++) {
        parents[i].style.removeProperty('font-size');
      }
      textAutoHeight(parents, childs);
    }, 250);
  });
};
