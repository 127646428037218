import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import './style.scss';

import checkDevice from '@helpers/checkDevice';
import { init } from '@helpers/adaptiveHeight';

const DEFAULT_SLIDE_ASPECT = 0.5625;
const DEFAULT_ASPECT_WIDTH = 1.7778;
const DEFAULT_ASPECT_HEIGHT = 0.5625;

const Slide = ({ width, height, children }) => {
  const iframe = useRef(null);
  const isMobile = checkDevice(window);
  const dimensions = isMobile && (document.body.clientWidth < document.body.clientHeight);

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: `0px ${document.body.clientWidth}px 0px 0px`, // need for preload slides that outside.
  });

  useEffect(() => {
    if (inView) {
      const _document = iframe.current.contentDocument;
      _document.body.innerHTML = children;
      init(_document.body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, inView]);

  const aspect = height / width;
  let frameWidth;
  let frameHeight;

  if (aspect < DEFAULT_SLIDE_ASPECT) {
    frameWidth = height * DEFAULT_ASPECT_WIDTH;
  } else {
    frameHeight = width * DEFAULT_ASPECT_HEIGHT;
  }

  return (
    <div
      style={{ width: `${frameWidth ? `${frameWidth}px` : '100%'}`, height: `${frameHeight ? `${frameHeight}px` : '100%'}` }}
      className={`pst-slideContainer pst-slideContainer--${dimensions ? 'mobile' : 'desktop'}`}
      ref={ref}
    >
      <iframe className="pst-slideContainer__slide" ref={iframe} scrolling='no' />
      <div className="pst-slideContainer__clickable"></div>
    </div>
  );
};

export default Slide;
