import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getComponentDisplayName from '@helpers/getComponentDisplayName';

export default function withValidationField(WrappedFieldComponent) {
  class WithValidationField extends Component {
    static propTypes = {
      mode: PropTypes.oneOf(['lazy', 'normal']),
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      validators: PropTypes.arrayOf(PropTypes.func),
      errorMessages: PropTypes.arrayOf(PropTypes.string),
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onError: PropTypes.func,
      showError: PropTypes.bool,
    };

    static defaultProps = {
      mode: 'lazy',
      validators: [],
      errorMessages: [],
      showError: true,
    };

    constructor(props) {
      super(props);

      this.state = { error: false, message: '', showError: props.showError };
      this.fieldRef = React.createRef();
    }

    showError = () => this.setState({ showError: true });

    validate = (cd) => {
      const { value } = this.fieldRef.current.state;
      const { validators, errorMessages } = this.props;

      const checkRules = () => {
        for (let i = 0; i < validators.length; i++) {
          const rule = validators[i];

          if (!rule(value)) {
            const message = errorMessages[i] || '';
            return { error: true, message };
          }
        }
        return { error: false, message: '' };
      };

      const { error, message } = checkRules();

      this.setState({ error, message }, () => {
        if (cd) cd({ error, message });
      });

      return { error, message };
    };

    handleValidation = (mode, callback) => (e) => {
      e.persist();
      const { onError, validators, mode: modeProps } = this.props;

      const callbackWrapper = cd => cd && cd(e, this.state);

      const handlerCallback = () => callbackWrapper(callback);
      const handlerError = () => callbackWrapper(onError);

      if (mode === modeProps && validators.length) {
        this.validate(({ error }) => {
          handlerCallback();
          if (error) handlerError();
        });
      } else {
        handlerCallback();
      }
    };

    render() {
      const { onChange, onBlur, error: parentError, ...extraProps } = this.props;
      const { error, message, showError } = this.state;

      const getError = () => {
        if (parentError) {
          return parentError;
        }

        if (showError) {
          return message || error;
        }
      };

      return (
        <WrappedFieldComponent
          ref={this.fieldRef}
          onChange={this.handleValidation('normal', onChange)}
          onBlur={this.handleValidation('lazy', onBlur)}
          error={getError()}
          {...extraProps}
        />
      );
    }
  }

  WithValidationField.displayName = `WithValidationField(${getComponentDisplayName(WrappedFieldComponent)})`;
  return WithValidationField;
}
