import React from 'react';

import insertValuesInHtml from '@helpers/insertValuesInHtml';
import checkActiveOrder from '@helpers/checkActiveOrder';

import ReadStoryButton from '@app/components/NEW_ui/ReadStoryButton';
import Slide from '../Slide';

import './style.scss';

export default ({
  slides,
  pastylla,
  handleOnTemplateSelect,
  slideId,
  order,
  freeTopic = false,
}) => {
  const { template, content, topic } = pastylla;
  const isBought = freeTopic || (order !== null && checkActiveOrder(order));
  return (
    <div className='pst-slidesPreview'>
      <div className='pst-slidesPreview__slidesContainer'>
        {slides && slides.map((slide, index) => {
          const first = index === 0;
          const params = {
            showActions: true,
            _id: slide._id,
            type: slide._type,
            templates: template.structure.filter(_s => _s._type === slide._type).map(_t => ({
              _id: _t._id,
              _type: _t._type,
              html: insertValuesInHtml(_t.html, slide.contains, content, topic),
            })),
            onTemplateSelect: handleOnTemplateSelect,
            designId: slide.designId,
            contains: slide.contains,
            template: 'slide',
            isBought,
          };

          return (
            <div className={`pst-slidesPreview__slide ${first ? 'pst-slidesPreview__slide_first' : ''}`} key={index}>
              <Slide {...params}>{slide.html}</Slide>
            </div>
          );
        })}
      </div>

      <div className='pst-slidesPreview__readStoryButton'>
        {slideId && <ReadStoryButton slideId={slideId} />}
      </div>
    </div>
  );
};
