import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const CardBriefIcon = ({ icon }) => {
  if (!icon) return false;
  return (
    <div className='pst-brieficon'>
      <img className='pst-brieficon__img' src={icon}/>
    </div>
  );
};

CardBriefIcon.defaultProps = {
  icon: undefined,
};

CardBriefIcon.propTypes = {
  icon: PropTypes.string,
};

export default CardBriefIcon;
