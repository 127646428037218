import React from 'react';

export default () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M0.390347 0H3.27683C3.49257 0 3.66746 0.174883 3.66746 0.390625C3.66746 0.606367 3.49257 0.78125 3.27683 0.78125H1.33341L4.46468 3.91254C4.61724 4.0651 4.61724 4.31242 4.46468 4.46496C4.31214 4.6175 4.06482 4.61752 3.91226 4.46496L0.780972 1.33367V3.27709C0.780972 3.49283 0.60609 3.66771 0.390347 3.66771C0.174605 3.66771 -0.000277519 3.49283 -0.000277519 3.27709V0.390625C-0.000277519 0.178027 0.172496 0 0.390347 0Z" fill="black" />
      <path d="M5.53504 5.53531C5.6876 5.38277 5.93492 5.38275 6.08746 5.53531L9.21875 8.6666V6.72318C9.21875 6.50744 9.39363 6.33256 9.60938 6.33256C9.82512 6.33256 10 6.50744 10 6.72318V9.60964C10 9.82187 9.82709 10.0003 9.60938 10.0003H6.72289C6.50715 10.0003 6.33227 9.82539 6.33227 9.60964C6.33227 9.3939 6.50715 9.21902 6.72289 9.21902H8.66633L5.53504 6.08773C5.38248 5.93517 5.38248 5.68785 5.53504 5.53531Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="10" fill="white" transform="matrix(-1 0 0 1 10 0)" />
      </clipPath>
    </defs>
  </svg>
);
