import React from 'react';

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='20'
    fill='none'
    viewBox='0 0 19 20'
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.7'
      d='M5.333 18.333h-2.5a1.667 1.667 0 01-1.666-1.666v-5.834a1.667 1.667 0 011.666-1.667h2.5M11.167 7.5V4.167a2.5 2.5 0 00-2.5-2.5l-3.334 7.5v9.166h9.4a1.667 1.667 0 001.667-1.416l1.15-7.5A1.669 1.669 0 0015.883 7.5h-4.716z'
    ></path>
  </svg>
);
