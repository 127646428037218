import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import Ripple from '@intereact/ripple';

import Icon from '@app/components/NEW_ui/Icon';
import Text from '@app/components/NEW_ui/Text';
import Button from '@app/components/NEW_ui/Button';
import RoundButton from '@app/components/NEW_ui/RoundButton';

import './style.scss';

// eslint-disable-next-line no-shadow
const LockScreenPromo = ({ fixed, priceMonth, priceYear, discountYear, onClick, onClose/* , activateSertificate */ }) => {
  const hotSaleYear = discountYear > 0;
  const { t } = useTranslation();
  const exitBtn = useRef(null);

  return (
    <div className={`pst-lockScreenPromo ${fixed ? 'pst-lockScreenPromo--fixed' : ''}`}>
      {onClose && <div className={'pst-viewerSidebar__exit pst-viewerSidebar__exit--show'} ref={exitBtn} >
        <RoundButton
          name="Close"
          size="md"
          onClick={onClose}
        />
      </div>}
      <div className='pst-lockScreenPromo__content'>
        <div className='pst-lockScreenPromo__lockWrap'>
          <Icon name='Lock' template='white' className='pst-lockScreenPromo__lockIcon' />
        </div>

        <div className='pst-lockScreenPromo__main'>
          <div className='pst-lockScreenPromo__title'>
            <Text level='1' weight={900} template='white' inline>Попробуй 7 дней за 1 рубль</Text>
          </div>

          <div className='pst-lockScreenPromo__message'>
            <Text level='4' inline>
              <div className='pst-lockScreen__item'>{t('lockScreen.message1')}</div>
              <div className='pst-lockScreen__item'>{t('lockScreen.message2')}</div>
              <div className='pst-lockScreen__item'>{t('lockScreen.message3')}</div>
              <div className='pst-lockScreen__info'>{t('lockScreen.message4')}</div>
            </Text>
          </div>

          {/* <div className="pst-lockScreenPromo__unitPrice"> */}
          {/*  <Text level="6" template="white" weight="800" inline>{priceMonth}&nbsp;{t('lockScreen.currency')}&nbsp;/&nbsp;{t('lockScreen.month')}</Text> */}
          {/* </div> */}

          {/* <Text level="6" className="pst-lockScreenPromo__orTxt" inline>или</Text> */}

          {/* <div className="pst-lockScreenPromo__unitPrice"> */}
          {/*  <Text level="6" template="white" weight="800" inline>{priceYear}&nbsp;{t('lockScreen.currency')}&nbsp;/&nbsp;{t('lockScreen.year')}</Text> */}
          {/*  {hotSaleYear && <Text level="6" weight="800" template="reddish-pink" inline>&nbsp;(-{discountYear}%)</Text>} */}
          {/* </div> */}
        </div>

        <div className='pst-lockScreenPromo__actionsWrapper'>
          <div className='pst-lockScreenPromo__buyButton pst-lockScreenPromo__buyButton--month'>
            <Ripple color='rgba(255, 255, 255, 0.4)'>
              {ripples => (
                <Button template='select-white' onClick={() => onClick(1)}>
                  {ripples}
                  {/* {t('lockScreen.subscriptionMonth')} */}
                  {priceMonth}&nbsp;{t('lockScreen.currency')}&nbsp;/&nbsp;{t('lockScreen.month')}
                </Button>
              )}
            </Ripple>
          </div>

          <div className='pst-lockScreenPromo__buyButton pst-lockScreenPromo__buyButton--year'>
            <Ripple color='rgba(255, 255, 255, 0.4)'>
              {ripples => (
                <Button template='select-white' onClick={() => onClick(12)}>
                  {ripples}
                  {/* {t('lockScreen.subscriptionYear')} */}
                  {priceYear}&nbsp;{t('lockScreen.currency')}&nbsp;/&nbsp;{t('lockScreen.year')}
                  {hotSaleYear && <span className="reddish-pink" inline>&nbsp;(-{discountYear}%)</span>}
                </Button>
              )}
            </Ripple>
          </div>
        </div>

      </div>
    </div>
  );
};

LockScreenPromo.defaultProps = {
  onClick: () => { },
  priceMonth: 0,
  priceYear: 0,
  discountYear: 0,
};

LockScreenPromo.propTypes = {
  onClick: PropTypes.func,
  priceMonth: PropTypes.number,
  discountMonth: PropTypes.number,
  priceYear: PropTypes.number,
  discountYear: PropTypes.number,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  // activateSertificate,
}, dispatch);

export default connect(null, mapDispatchToProps)(LockScreenPromo);
