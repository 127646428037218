import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { activateUser } from '../redux/actions';

class AuthActivateUser extends Component {
  static propTypes = {
    activateUser: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { match: { params: { code } } } = props;
    this.state = { code };
  }

  async componentDidMount() {
    const { code } = this.state;
    const { history, activateUser: activate } = this.props;

    try {
      await activate(code);
      history.push('/topics');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <div className='pst-authActivate'></div>;
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ activateUser }, dispatch);
export default connect(null, mapDispatchToProps)(AuthActivateUser);
