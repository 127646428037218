import insertValuesInHtml from '@app/helpers/insertValuesInHtml';

const renderSlides = ({ topic, content, template }) => {
  const { structure } = topic;

  return structure.map((slide) => {
    const meta = template.structure.find(({ _id }) => _id === slide.designId);
    const html = insertValuesInHtml(meta.html, slide.contains, content, topic);

    return { ...slide, html };
  });
};

export default renderSlides;
