import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Swipeable } from 'react-swipeable';
import { bindActionCreators } from 'redux';
import SplitLayout from '@app/components/NEW_ui/SplitLayout';
import LogoPanel from '@app/components/NEW_ui/LogoPanel';
import generateArchive from '@helpers/generateArchive';

import {
  fetchDesignTopic,
  fetchPresentation,
  updatePastylla,
  updatePastyllaLocal,
} from './redux/actions';

import TextEditing from './components/TextEditing';
import RightPanel from './components/RightPanel';
import MenuBottom from './components/MenuBottom';
import TabsDesign from './components/TabsDesign';

import { renderSlides, returnSlidesAfterTemplateSelect } from './helpers';
import { fetchFreeTopic } from '../Viewer/redux/actions';

class Design extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      slides: null,
      design: [],
      showStep: 'step1',
      touchSlider: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    // eslint-disable-next-line no-shadow
    const { fetchDesignTopic, fetchPresentation, fetchFreeTopic, match } = this.props;
    const { params } = match;
    const { pastyllaId } = params;

    if (pastyllaId) {
      await fetchPresentation(pastyllaId);

      const { pastylla } = this.props;
      const { topic } = pastylla;
      const { structure } = topic;

      const slides = renderSlides(pastylla);

      const counters = structure.find(({ contains }) => contains && contains.find(({ field, value }) => field === 'counter' && Number.isInteger(value)));

      if (this._isMounted) {
        this.setState({
          slides,
          pastyllaId,
          design: topic.design,
          showPagination: !!counters,
        });
      }

      // fetch designs...
      fetchDesignTopic(topic);
      fetchFreeTopic(topic);
    }

    window.addEventListener('touchmove', this.disableSwipe);
  }

  disableSwipe = (e) => {
    const isTouchSlider = e.target.classList.contains('pst-slider__clickable') || e.target.classList.contains('swiper-wrapper');

    if (!this.state.touchSlider && isTouchSlider) {
      this.setState({
        touchSlider: true,
      });
    }

    if (this.state.touchSlider && !isTouchSlider) {
      this.setState({
        touchSlider: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('touchmove', this.disableSwipe);
  }

  handleOnTemplateSelect = (slideId, designId) => {
    const { slides: slds, pastyllaId } = this.state;
    const { pastylla: pst, updatePastyllaLocal: updateLocal, updatePastylla: update } = this.props;
    const { topic } = pst;

    const [slides, pastylla] = returnSlidesAfterTemplateSelect(slideId, designId, slds, pst);

    this.setState({ slides }, () => updateLocal(pastylla));

    return generateArchive(pastylla).then((_presentation) => {
      const _form = new FormData();
      _form.append('file', _presentation, `${Date.now()}.pastylla`);

      return update(_form, pastyllaId, { type: 'change story image', title: topic.title });
    });
  };

  handleChangeText = (data) => {
    const fields = Object.keys(data);
    const { pastyllaId } = this.state;
    const { pastylla, updatePastyllaLocal: updateLocal, updatePastylla: update } = this.props;
    const { topic, content } = pastylla;

    for (let i = 0; i < content.length; i++) {
      const answer = content[i];
      if (fields.includes(answer.id)) {
        answer.value = data[answer.id] || ' ';
      }
    }

    const newSlides = renderSlides(pastylla);
    this.setState({ slides: newSlides }, () => updateLocal(pastylla));

    const _pastylla = { ...pastylla, ...topic };

    return generateArchive(_pastylla).then((_presentation) => {
      const _form = new FormData();
      _form.append('file', _presentation, `${Date.now()}.pastylla`);

      return update(_form, pastyllaId, { type: 'change text', title: topic.title });
    });
  }

  handleChangeStep = (stepName) => {
    if (!this.state.touchSlider) {
      window.scrollTo(0, 0);
      this.setState({
        showStep: stepName,
      });
    }
  }

  render() {
    const { slides, pastyllaId, showStep } = this.state;
    const { pastylla, order, freeTopic } = this.props;
    const { content } = pastylla;

    // need for show relevant answers...
    let questions;
    if (pastylla) {
      const { topic } = pastylla;

      if (topic) {
        questions = topic.questions;
      }
    }

    const swipeableConfig = {
      delta: 50,
      trackTouch: true,
    };

    return (
      <Swipeable
        onSwipedLeft={() => this.handleChangeStep('step2')}
        onSwipedRight={() => this.handleChangeStep('step1')}
        {...swipeableConfig}
      >
        <LogoPanel mod='design' showBackBtn showAvatar fixedScroll={115} />
        <TabsDesign
          forMobile
          showStep={showStep}
          changeStepHandler={this.handleChangeStep}
        />
        <SplitLayout
          isLeftSectionHideTablet={showStep === 'step2'}
          isRightSectionHideTablet={showStep === 'step1'}
          mod='design'
          left={
            slides && <TextEditing
              changeText={this.handleChangeText}
              contains={slides[0].contains}
              answers={content}
              questions={questions}
              title={pastylla.meta.title}
              handleNextStep={() => this.handleChangeStep('step2')}
            />
          }
          right={
            <RightPanel
              slides={slides}
              handleOnTemplateSelect={this.handleOnTemplateSelect}
              slideId={pastyllaId}
              pastylla={pastylla}
              order={order}
              freeTopic={freeTopic}
            />
          }
        />
        <MenuBottom
          showStep={showStep}
          changeStepHandler={this.handleChangeStep}
          slideId={pastyllaId}
        />
      </Swipeable>
    );
  }
}

const mapStateToProps = ({ design, subscriptions }) => ({
  pastylla: design.pastylla,
  designs: design.designs,
  order: subscriptions.order,
  freeTopic: subscriptions.freeTopic,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchDesignTopic,
  fetchFreeTopic,
  fetchPresentation,
  updatePastylla,
  updatePastyllaLocal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Design);
