import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Text from '@app/components/NEW_ui/Text';
import Button from '@app/components/NEW_ui/Button';
import Icon from '@app/components/NEW_ui/Icon';

import useOutsideClick from '@helpers/hooks/useOutsideClick';
import closeSertificatePopup from '../SertificatePopup/redux/actions';
import { handleDeleteAccount, handleChangeStatus } from './redux/actions';

import './style.scss';

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const DeleteAccountCard = ({
  closePopup,
  deleteAccount,
  status,
  changeStatus,
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [a, setA] = useState(randomIntFromInterval(1, 9));
  // eslint-disable-next-line no-unused-vars
  const [b, setB] = useState(randomIntFromInterval(1, 9));

  const inputChangeHandler = (e) => {
    setCode(e.target.value);

    if (status === 'error') {
      changeStatus('inactive');
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    deleteAccount(code);
  };

  const handleClosePopup = () => {
    closePopup();
    if (status === 'success') {
      window.location.reload();
    }
  };
  useOutsideClick(ref, handleClosePopup);

  return (
    <div className="pst-deleteAccountCard" ref={ref}>
      <button
        className="pst-deleteAccountCard__closeButton"
        onClick={handleClosePopup}
      >
        <Icon name="Close" size="sm" alternative template="gray" />
      </button>

      <div className="pst-deleteAccountCard__hanger">
        <div className="pst-deleteAccountCard__subtract"></div>
      </div>

      <div className="pst-deleteAccountCard__content">
        <div className="pst-deleteAccountCard__title">
          <Text level="2" weight={800} inline>
            {t('deleteAccountPopup.title')}
          </Text>
        </div>
        <form className="pst-deleteAccountCard__form">
          {(status === 'inactive' || status === 'error') && (
            <>
              <div className="pst-deleteAccountCard__message">
                <Text level="6" template="grey" inline>
                  {t('deleteAccountPopup.description', { a, b })}
                </Text>
              </div>

              <input
                className="pst-deleteAccountCard__input"
                placeholder={t('deleteAccountPopup.inputPlaceholder')}
                onChange={inputChangeHandler}
                value={code}
              />

              {status === 'error' && (
                <p className="pst-deleteAccountCard__error">
                  {t('deleteAccountPopup.error')}
                </p>
              )}

              <Button
                template="select-small"
                classNames="pst-deleteAccountCard__activate"
                active
                onClick={submitHandler}
                disabled={code !== `${a + b}` }
              >
                {t('deleteAccountPopup.confirm')}
              </Button>
            </>
          )}
          {status === 'success' && (
            <>
              <div className="pst-deleteAccountCard__message">
                <Text level="6" template="grey" inline>
                  {t('deleteAccountPopup.success')}
                </Text>
              </div>

              <Button
                template="select-small"
                classNames="pst-deleteAccountCard__activate"
                active
                onClick={handleClosePopup}
              >
                {t('deleteAccountPopup.goService')}
              </Button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ sertificate }) => ({
  status: sertificate.status,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    closePopup: closeSertificatePopup,
    deleteAccount: handleDeleteAccount,
    changeStatus: handleChangeStatus,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountCard);
