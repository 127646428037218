export const LANGUAGE_KEY = 'language';

const getLanguageFromBrowser = () => {
  const lang = navigator.language.substring(0, 2);
  return ['en', 'ru'].find(x => x === lang);
};

export default () => {
  const langFromStorage = localStorage.getItem(LANGUAGE_KEY);
  return langFromStorage || getLanguageFromBrowser() || process.env.REACT_APP_DEFAULT_LANGUAGE || 'en';
};
