import {
  COMPONENT_DESIGN_FETCH,
  COMPONENT_DESIGN_UPDATE_PASTYLLA,
} from '../constants/actionTypes';

const initialState = {
  designs: [],
  pastylla: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPONENT_DESIGN_FETCH:
      return { ...state, designs: action.designs };
    case COMPONENT_DESIGN_UPDATE_PASTYLLA:
      return { ...state, pastylla: action.pastylla };
    default:
      return state;
  }
};
