import React from 'react';

import Text from '@app/components/NEW_ui/Text';

import './style.scss';

const BaseLoader = ({ progress = 0, title }) => (
  <div className="pst-baseLoader">
    <div className="pst-baseLoader__container">
      <div className="pst-baseLoader__title">
        <Text weight={800} level='3' template='white' className='pst-imageEditing__title-text'>{title}</Text>
      </div>
      <div className="pst-baseLoader__progress-wrap">
        <div className="pst-baseLoader__progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  </div>
);

export default BaseLoader;
