import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import auth from './reducers/auth';
import dashboard from './reducers/dashboard';
import design from './reducers/design';
import subscriptions from './reducers/subscriptions';
import sertificate from './reducers/sertificate';
import viewer from './reducers/viewer';
import quizzes from './reducers/quizzes';

export default combineReducers({
  router: routerReducer,
  auth,
  dashboard,
  design,
  subscriptions,
  sertificate,
  viewer,
  quizzes,
});
