import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// import { routerMiddleware } from 'react-router-redux';
// import createHistory from 'history/createBrowserHistory';
import reducer from './reducer';

// export const history = createHistory();

const getMiddleware = () => {
  const middleware = [
    // routerMiddleware(history), // Build the middleware for intercepting and dispatching navigation actions
    thunk,
    ...process.env.NODE_ENV === 'production' ? [] : [createLogger()],
  ];
  return applyMiddleware(...middleware);
};

export default createStore(
  reducer, composeWithDevTools(getMiddleware()),
);
