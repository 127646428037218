export const defaultViewportContent = 'width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1.0, user-scalable=no';
export const specialViewportIPhone = 'viewport-fit=cover';
export const specialViewportAndroid = 'viewport-fit=contain';

const setMetatags = (window) => {
  const isIPhone = /iP(hone|od)/i.test(window.navigator.userAgent);
  const finalViewportContent = isIPhone ? `${defaultViewportContent}, ${specialViewportIPhone}` : `${defaultViewportContent}, ${specialViewportAndroid}`;

  return [
    { name: 'viewport', content: finalViewportContent },
  ];
};

export default setMetatags;
