import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Recommendations from '@app/components/NEW_ui/Recommendations';
import './style.scss';

import Text from '../Text';

const QuestionInfo = ({ heading, description, recommendations }) => {
  const id = `recommendation_${Math.random()}`;

  const renderRecommendation = () => (
    <div className='pst-questionInfo__recommendationContainer'>
      <div className='pst-questionInfo__recommendationIcon' data-tip data-for={id}>
        ?
      </div>
      <ReactTooltip
        className='pst-questionInfo__tooltip'
        place='top'
        type='light'
        id={id}
        aria-haspopup='false'
      >
        <Recommendations recommendations={recommendations} />
      </ReactTooltip>
    </div>
  );

  return (
    <div className='pst-questionInfo'>
      <Text level='1' weight='900' className='pst-questionInfo__heading'><span>{heading}</span>{renderRecommendation()}</Text>
      <Text level='3' weight='400' className='pst-questionInfo__description'>{description}</Text>
    </div>
  );
};

QuestionInfo.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
};

export default QuestionInfo;
