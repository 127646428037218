import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import CookiesService, { HIDE_PROBLEM_QUIZ } from '@app/services/CookiesService';

import './style.scss';
import { PROBLEMS_QUIZ } from '../../../store/constants/quizTypes';
import ProblemContent from './ProblemContent';
import RecommendationContent from './RecommendationContent';

const optionsMap = {
  'Страх темноты': 'Про трусливого барабашку',
  'Не хочет спать в своей кроватке': 'Медвежонок и кроватолёт',
  'Не хочет ложиться спать': 'Обезьянка и цирк',
  'Ссоры между детьми в семье': 'Про дружных львят и семечко раздора',
  'Неумение проигрывать (злится)': 'Самый быстрый зайчик',
  'Сильная грусть при неудачах': 'Семейный портрет лягушонка',
  'Нежелание ходить в садик': 'Лесной детский сад',
  'Лень и нежелание убираться': 'Как кенгурёнок перевоспитал Лентяя Лентяича',
  'Зависть игрушкам друзей': 'Ёжик и волшебная палочка',
  'Выпрашивание новых игрушек': 'Про мышонка и Хочумбу',
  'Ребёнок любит подраться': 'Как гномик превратился в сосиску',
  'Грусть от развода родителей': 'Енотик из двух королевств',
  'Обманывание родителей': 'Королевство врунов',
  'Не любит мыться и чистить зубы': 'О единорожке, которая не любила мыть гриву',
  'Зависимость от гаджетов': 'Как котёнок попал в страну компьютерных Чудиков',
  'Боязнь врачей и уколов': 'Как щенок победил волшебных хулиганов',
  'Боязнь парикмахеров': 'Эльф, который боялся стричься',
  'Ничего не беспокоит': '',
};

const options = Object.keys(optionsMap);

const lastOption = options[options.length - 1];

// eslint-disable-next-line no-shadow,no-unused-vars
const ProblemQuiz = ({ user, fixed, onClick, onClose }) => {
  const { t } = useTranslation();

  const [showRecommendations, setShowRecommendations] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const isSelected = item => !!selected.find(x => x === item);
  const handleSelect = (item) => {
    if (item === lastOption) {
      setSelected([lastOption]);
      return;
    }
    let arr = selected;
    // если выбрана последняя опция, то отключаем ее
    if (arr.includes(lastOption)) { arr = arr.filter(x => x !== lastOption); }

    if (arr.includes(item)) {
      setSelected([...arr.filter(x => x !== item)]);
    } else {
      setSelected([...arr, item]);
    }
  };

  const handleSubmit = () => {
    // задаем куку скрытия опроса на 24 часа
    if (selected.length === 1 && selected.includes(lastOption)) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      CookiesService.setCookie(HIDE_PROBLEM_QUIZ, new Date().toISOString(), { domain: 'myskazka.com', expires: tomorrow });
      onClose();
      return;
    }
    if (selected.length > 0) {
      onClick({
        createdAt: new Date().toISOString(),
        quizId: PROBLEMS_QUIZ,
        title: t('problemQuiz.title'),
        email: user.email,
        loginCount: user.loginCount,
        answers: selected.map((text, position) => ({ text, position: 1 + position })),
      });
      setShowRecommendations(true);
    }
  };

  const handleClose = () => {
    const never = new Date();
    never.setDate(never.getDate() + 36500);
    CookiesService.setCookie(HIDE_PROBLEM_QUIZ, new Date().toISOString(), { domain: 'myskazka.com', expires: never });
    onClose();
  };

  return (
    showRecommendations ? <RecommendationContent
      optionsMap={optionsMap }
      fixed={fixed}
      selected={selected}
      handleSubmit={handleClose}/>
      : <ProblemContent
        options={options}
        fixed={fixed}
        isSelected={isSelected}
        handleSelect={handleSelect}
        handleSubmit={handleSubmit} />
  );
};

ProblemQuiz.defaultProps = {
  onClick: () => {
  },
};

ProblemQuiz.propTypes = {
  user: PropTypes.object,
  onClick: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  // submitQuiz,
}, dispatch);

export default connect(null, mapDispatchToProps)(ProblemQuiz);
