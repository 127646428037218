import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './style.scss';
import Text from '../Text';

const SlideCounter = ({ count, max, className }) => {
  const { t } = useTranslation();
  const classes = `pst-slideCounter ${className || ''}`;

  return (
    <div
      className={classes}
    >
      <Text weight="800" template="white" level="2">{count}</Text>
      <Text weight="800" template="white" level="5">{`${t('slideCounter')} ${max}`}</Text>
    </div>
  );
};

SlideCounter.defaultProps = {
  count: 1,
  max: 20,
};

SlideCounter.propTypes = {
  count: PropTypes.number,
  max: PropTypes.number,
};

export default SlideCounter;
