import insertValuesInHtml from '@helpers/insertValuesInHtml';

export const renderSlides = (pastylla) => {
  const { topic, content, template } = pastylla;
  const { structure } = topic;
  const result = [];

  for (let i = 0; i < structure.length; i++) {
    const slide = structure[i];
    const { contains } = slide;

    const meta = template.structure.find(_s => _s._id === slide.designId);
    const html = insertValuesInHtml(meta.html, contains, content, topic);

    result.push({
      _type: slide._type,
      contains: slide.contains,
      _id: slide._id,
      designId: slide.designId,
      html,
    });
  }

  return result;
};

export const returnSlidesAfterTemplateSelect = (slideId, designId, slds, pst) => {
  const slides = [...slds];
  const pastylla = { ...pst };

  const { template, content, topic } = pastylla;
  const { structure } = topic;

  const slide = slides.find(({ _id }) => _id === slideId);

  if (slide) {
    const meta = template.structure.find(({ _id }) => _id === designId);

    if (meta) {
      const html = insertValuesInHtml(meta.html, slide.contains, content, topic);

      Object.assign(slide, {
        html,
        designId,
      });

      const slideInStructure = structure.find(({ _id }) => _id === slideId);

      if (slideInStructure) {
        Object.assign(slideInStructure, { designId });
      }

      return [slides, pastylla];
    }
  }
};

export const changeBg = (pastylla, background, slideId, size) => {
  const pst = { ...pastylla };
  const { topic } = pst;
  const { structure } = topic;

  for (let i = 0; i < structure.length; i++) {
    if (structure[i]._id === slideId) {
      const { contains } = structure[i];

      for (let j = 0; j < contains.length; j++) {
        const param = contains[j];

        if (param.field === 'background') param.value = background[size];
      }
    }
  }
  return pst;
};
