import React from 'react';

export default ({ template }) => {
  if (template === 'color') {
    return (
      <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.99984 3.5601C8.25692 3.5601 9.27945 4.58264 9.27945 5.83972C9.27945 7.09575 8.25666 8.11828 6.99984 8.11828C5.74355 8.11828 4.72075 7.09575 4.72075 5.83972C4.72049 4.58237 5.74381 3.5601 6.99984 3.5601ZM6.99984 11.3435C10.0363 11.3435 12.5057 8.8749 12.5057 5.83972C12.5057 2.80296 10.0366 0.333328 6.99984 0.333328C3.9636 0.333328 1.49397 2.80322 1.49397 5.83972C1.49397 8.8749 3.9636 11.3435 6.99984 11.3435ZM9.22727 15.8344C10.3599 15.5765 11.4408 15.1289 12.4243 14.5107C12.7863 14.2829 13.0431 13.9206 13.138 13.5035C13.2329 13.0865 13.1583 12.6487 12.9305 12.2867C12.8179 12.1072 12.6709 11.9518 12.4982 11.8291C12.3254 11.7065 12.1301 11.6192 11.9235 11.5721C11.7169 11.525 11.5031 11.5191 11.2942 11.5547C11.0854 11.5903 10.8856 11.6667 10.7063 11.7796C8.45062 13.1977 5.54747 13.1967 3.29368 11.7796C3.11442 11.6667 2.91464 11.5902 2.70579 11.5546C2.49693 11.519 2.28311 11.5249 2.07654 11.572C1.86997 11.6191 1.67471 11.7064 1.50194 11.8291C1.32917 11.9517 1.18228 12.1072 1.06968 12.2867C0.841822 12.6486 0.767071 13.0863 0.861862 13.5034C0.956653 13.9204 1.21322 14.2828 1.57514 14.5107C2.55853 15.1287 3.63921 15.5763 4.77161 15.8344L1.69374 18.9129C1.39127 19.2154 1.22138 19.6258 1.22146 20.0536C1.22153 20.4814 1.39156 20.8917 1.69413 21.1942C1.99671 21.4967 2.40705 21.6665 2.83488 21.6665C3.26271 21.6664 3.67299 21.4964 3.97546 21.1938L6.99931 18.1694L10.025 21.194C10.1746 21.3439 10.3523 21.4627 10.5479 21.5438C10.7434 21.6249 10.9531 21.6667 11.1648 21.6667C11.3765 21.6667 11.5862 21.6249 11.7818 21.5438C11.9773 21.4627 12.155 21.3439 12.3046 21.194C12.4547 21.0445 12.5737 20.8667 12.6549 20.6711C12.7362 20.4754 12.778 20.2656 12.778 20.0537C12.778 19.8418 12.7362 19.6321 12.6549 19.4364C12.5737 19.2407 12.4547 19.063 12.3046 18.9134L9.22727 15.8344Z"
          fill="#EE8208"/>
      </svg>
    );
  }

  return (
    <svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99956 4.84016C11.8852 4.84016 13.419 6.37396 13.419 8.25959C13.419 10.1436 11.8848 11.6774 9.99956 11.6774C8.11512 11.6774 6.58093 10.1436 6.58093 8.25959C6.58053 6.37357 8.11552 4.84016 9.99956 4.84016ZM9.99956 16.5152C14.5543 16.5152 18.2584 12.8124 18.2584 8.25959C18.2584 3.70444 14.5547 0 9.99956 0C5.4452 0 1.74076 3.70484 1.74076 8.25959C1.74076 12.8124 5.4452 16.5152 9.99956 16.5152ZM13.3407 23.2517C15.0397 22.8648 16.661 22.1934 18.1362 21.266C18.6793 20.9244 19.0644 20.3809 19.2068 19.7553C19.3492 19.1297 19.2373 18.4731 18.8956 17.93C18.7266 17.6609 18.5062 17.4276 18.247 17.2437C17.9878 17.0598 17.6949 16.9287 17.3851 16.8581C17.0752 16.7875 16.7544 16.7786 16.4411 16.832C16.1278 16.8855 15.8281 17.0001 15.5592 17.1695C12.1757 19.2966 7.82101 19.295 4.44033 17.1695C4.17143 17.0001 3.87176 16.8854 3.55848 16.8319C3.2452 16.7785 2.92446 16.7873 2.61461 16.858C2.30475 16.9286 2.01187 17.0597 1.75271 17.2436C1.49356 17.4276 1.27323 17.6608 1.10432 17.93C0.762535 18.473 0.650408 19.1294 0.792594 19.755C0.934781 20.3807 1.31964 20.9242 1.86252 21.266C3.3376 22.1931 4.95862 22.8644 6.65722 23.2517L2.04041 27.8693C1.5867 28.3232 1.33188 28.9387 1.33199 29.5804C1.3321 30.2222 1.58714 30.8376 2.041 31.2913C2.49486 31.745 3.11037 31.9998 3.75212 31.9997C4.39387 31.9996 5.00929 31.7446 5.46299 31.2907L9.99877 26.7541L14.5373 31.2911C14.7617 31.5158 15.0282 31.6941 15.3216 31.8157C15.615 31.9374 15.9294 32 16.247 32C16.5646 32 16.8791 31.9374 17.1725 31.8157C17.4658 31.6941 17.7323 31.5158 17.9567 31.2911C18.1818 31.0667 18.3604 30.8001 18.4822 30.5066C18.6041 30.2131 18.6668 29.8984 18.6668 29.5806C18.6668 29.2628 18.6041 28.9481 18.4822 28.6546C18.3604 28.361 18.1818 28.0945 17.9567 27.8701L13.3407 23.2517Z"
        fill="white"/>
    </svg>
  );
};
