import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M19.8016 10.2297C19.8016 9.54989 19.7465 8.86644 19.6289 8.19769H10.1966V12.0485H15.598C15.3739 13.2905 14.6537 14.3891 13.5991 15.0873V17.5859H16.8216C18.714 15.8442 19.8016 13.2721 19.8016 10.2297Z"
        fill="#4285F4"/>
      <path
        d="M10.1966 20C12.8936 20 15.1681 19.1144 16.8253 17.5859L13.6028 15.0873C12.7062 15.6972 11.5488 16.0426 10.2003 16.0426C7.59141 16.0426 5.3794 14.2826 4.58572 11.9162H1.26035V14.492C2.95794 17.8688 6.41559 20 10.1966 20Z"
        fill="#34A853"/>
      <path
        d="M4.58204 11.9162C4.16316 10.6743 4.16316 9.32941 4.58204 8.08744V5.51166H1.26035C-0.15799 8.33731 -0.15799 11.6664 1.26035 14.492L4.58204 11.9162Z"
        fill="#FBBC04"/>
      <path
        d="M10.1966 3.95736C11.6223 3.93532 13.0002 4.47179 14.0327 5.45654L16.8878 2.60149C15.0799 0.903901 12.6805 -0.0294083 10.1966 -1.27572e-05C6.41559 -1.27572e-05 2.95794 2.13117 1.26035 5.51165L4.58204 8.08744C5.37205 5.71742 7.58774 3.95736 10.1966 3.95736Z"
        fill="#EA4335"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19.6032" height="20" fill="white" transform="translate(0.198425)"/>
      </clipPath>
    </defs>
  </svg>

);
