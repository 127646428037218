import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';

import './style.scss';

const LeftPanel = () => {
  const [showLoader, handler] = useState(false);

  const transition = useTransition(showLoader, null, {
    trail: 200,
    from: { transform: 'translateX(-100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' },
  });

  useEffect(() => {
    handler(true);
    setTimeout(() => {
      handler(false);
    }, 800);
  }, []);

  return (
    <div className='pst-leftPanel'>
      <video className='pst-leftPanel__video' autoPlay loop muted playsInline>
        <source src='https://app.myskazka.com/img/toy.mp4' type='video/mp4' />
      </video>

      {transition.map(({ item, key, props }) => item && <animated.div className='pst-leftPanel__loader' key={key} style={props} />)}
    </div>
  );
};

export default LeftPanel;
