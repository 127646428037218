import { fetchTopicList, fetchAccountData } from '@app/services/AccountService';
import getCookie from '@app/helpers/getCookie';
import { deletaPresentation, createPresentation as create } from '@app/services/PresentationService';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';

import generateArchive from '@app/helpers/generateArchive';
import { fetchDesign, fetchSlideDesign } from '@app/services/DesignService';

import {
  COMPONENT_DASHBOARD_FETCH,
  COMPONENT_DASHBOARD_ADDTAG,
  COMPONENT_DASHBOARD_DELETETAG,
  COMPONENT_MYPRESENTATION_FETCH,
} from '@app/store/constants/actionTypes';

export const getTopicsByLanguage = (topics) => {
  const enTopics = topics.filter(({ language }) => language === 'English');
  const ruTopics = topics.filter(({ language }) => language === 'Russian');

  return { enTopics, ruTopics };
};

export const fetchTopic = () => async (dispatch) => {
  const items = await fetchTopicList();
  const { enTopics, ruTopics } = getTopicsByLanguage(items);
  const topics = { items, enTopics, ruTopics };

  dispatch({ type: COMPONENT_DASHBOARD_FETCH, topics });
};

export const fetchMyPresentation = _id => async (dispatch) => {
  const data = await fetchAccountData(_id);
  const myPresentation = data.sort((a, b) => b.files[b.files.length - 1].updated_at - a.files[a.files.length - 1].updated_at);
  dispatch({ type: COMPONENT_MYPRESENTATION_FETCH, myPresentation });
};

export const addSelectedTagFilter = (tag) => {
  MixpanelService.track(`${mixpanelMap.ADD_TAG}: ${tag}`, { tag, date: new Date().toString() });
  return ({ type: COMPONENT_DASHBOARD_ADDTAG, tag });
};

export const removeSelectedTagFilter = (tag) => {
  MixpanelService.track(`${mixpanelMap.REMOVE_TAG}: ${tag}`, { tag, date: new Date().toString() });
  return ({ type: COMPONENT_DASHBOARD_DELETETAG, tag });
};

export const deletePastylla = _id => async (dispatch) => {
  const token = getCookie('token', document.cookie);
  await deletaPresentation(_id, token);

  const data = await fetchAccountData();
  const newPresentations = data.sort((a, b) => b.files[b.files.length - 1].updated_at - a.files[a.files.length - 1].updated_at);
  dispatch({ type: COMPONENT_MYPRESENTATION_FETCH, myPresentation: newPresentations });
};

const returnForm = (archive) => {
  const form = new FormData();
  form.append('file', archive, `${Date.now()}.pastylla`);
  return form;
};

export const createPresByTemplate = (topic, callback) => async () => {
  const { design, questions } = topic;
  const parsedDesign = design[0] ? await fetchDesign(topic.design[0]) : [];
  const fetchedSlides = await Promise.all(parsedDesign.structure.map(({ _id }) => fetchSlideDesign(parsedDesign._id, _id)));
  callback('designs');

  // need to analitics...
  MixpanelService.track(mixpanelMap.CREATE_PROJECT, {
    projectName: topic.title,
    description: topic.description,
    created_at: new Date().toString(),
  });

  const template = {
    ...parsedDesign,
    structure: parsedDesign.structure.map((parsedDesignSlide, index) => ({
      ...parsedDesignSlide,
      ...fetchedSlides[index],
    })),
  };

  const content = questions.map(({ recommendation, name }) => {
    const { value } = recommendation.filter(item => item._type === 'text')[1];
    return { id: name, value };
  });

  const data = {
    topic,
    content,
    template,
  };

  const presentation = await generateArchive(data);
  const form = returnForm(presentation);
  const response = await create(form);
  callback('created');

  return response;
};
