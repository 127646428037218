import {
  deleteUser,
} from '@app/services/UserService';
import { DELETE_ACCOUNT_STATUS } from '@app/store/constants/actionTypes';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';

export const handleChangeStatus = status => (dispatch) => {
  dispatch({ type: DELETE_ACCOUNT_STATUS, status });
};

export const handleDeleteAccount = () => async (dispatch) => {
  try {
    const data = await deleteUser();

    if (data.status === 200) {
      dispatch({ type: DELETE_ACCOUNT_STATUS, status: 'success' });

      MixpanelService.track(`${mixpanelMap.DELETE_ACCOUNT}`);
    } else {
      dispatch({ type: DELETE_ACCOUNT_STATUS, status: 'error' });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    dispatch({ type: DELETE_ACCOUNT_STATUS, status: 'error' });
  }
};
