import React from 'react';

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='20'
    fill='none'
    viewBox='0 0 19 20'
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.7'
      d='M13.667 1.667h2.225a1.925 1.925 0 011.941 1.666v5.834a1.925 1.925 0 01-1.941 1.667h-2.225M7.833 12.5v3.334a2.5 2.5 0 002.5 2.5l3.334-7.5V1.667h-9.4A1.667 1.667 0 002.6 3.083l-1.15 7.5A1.667 1.667 0 003.117 12.5h4.716z'
    ></path>
  </svg>
);
