/**
 * Concatenates an array of classes into a string
 * @param {Array<String>} classArray
 * @return {String}
 *
 * Note that it is better not to use that directly in components.
 * You should rely on withClassname HOC for that.
 */
export default (className) => {
  if (typeof className === 'string') {
    return className;
  }

  if (Array.isArray(className)) {
    return className.filter(Boolean).join(' ');
  }

  return '';
};
