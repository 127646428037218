import React from 'react';
import ReactImage from 'react-image';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Text from '@app/components/NEW_ui/Text';
import Button from '@app/components/NEW_ui/Button';
import popupBg from '@app/assets/images/popupBg.jpg';
import './style.scss';

export default withRouter(({ email, confirmationHandler, history }) => {
  const { t } = useTranslation();

  const onClickConfirmation = (e) => {
    confirmationHandler(e);
    history.push('/auth/login');
  };

  return (
    <div className='pst-emailConfirmationPopup'>
      <div className='pst-emailConfirmationPopup__container'>
        <ReactImage
          className='pst-emailConfirmationPopup__image'
          src={popupBg}
          alt='popup background'
          loader='Загрузка...'
        />
        <div className='pst-emailConfirmationPopup__content'>
          <Text className='pst-emailConfirmationPopup__title' weight='800' level='3'>{t('confirmationPopup.title')}</Text>
          <p className='pst-emailConfirmationPopup__text'>{t('confirmationPopup.sendText')} <span className='pst-emailConfirmationPopup__email'>{email}</span></p>
          <Button
            template='grayButton'
            onClick={onClickConfirmation}
          >
            {t('confirmationPopup.button')}
          </Button>
        </div>
      </div>
    </div>
  );
});
