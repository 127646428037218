import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 23L12 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 2C10.5551 2 9.15881 2.52137 8.06756 3.46831C6.9763 4.41525 6.26339 5.72417 6.05981 7.15459" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.9899 7.65374C17.9392 6.77655 17.6965 5.9212 17.2788 5.14814C16.8612 4.37508 16.2789 3.70318 15.573 3.17993" stroke="#7421D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.066 11.215C16.4121 12.0267 15.602 12.7245 14.7927 13.2833C13.931 13.8783 12.146 15.4581 12 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
