const declension = (number) => {
  const str = String(number);
  const lastSimbol = str[str.length - 1];

  // check the declination according to the number
  const constants = number < 20 && number > 10;
  const constants2 = lastSimbol === '0' || (lastSimbol >= 5 && lastSimbol <= 9);
  const constants3 = lastSimbol === '1';

  if (constants || constants2) return { simbols: 'symbol_1', rest: 'rest_1' };
  if (constants3) return { simbols: 'symbol_2', rest: 'rest_2' };
  return { simbols: 'symbol_3', rest: 'rest_1' };
};

export default declension;
