import React from 'react';

export default () => (
  <svg
    id="svg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0, 0, 400,462.3255813953488"
  >
    <g id="svgg">
      <path
        id="path0"
        d="M45.333 19.106 C 30.275 21.630,19.644 33.079,15.784 50.930 C 13.951 59.406,13.901 64.623,14.070 230.465 L 14.234 390.930 15.236 396.047 C 21.518 428.116,46.743 439.335,77.573 423.772 C 80.444 422.323,92.635 415.361,183.953 363.025 C 203.930 351.576,227.832 337.883,276.628 309.935 C 289.866 302.352,313.674 288.723,329.535 279.648 C 345.395 270.573,360.360 261.830,362.791 260.220 C 389.135 242.767,393.281 218.069,373.256 197.880 C 367.091 191.665,363.930 189.521,344.651 178.487 C 326.002 167.812,288.900 146.554,271.395 136.513 C 251.742 125.240,222.872 108.689,198.372 94.651 C 170.485 78.672,144.585 63.826,117.209 48.129 C 80.662 27.173,78.535 25.976,74.884 24.301 C 64.639 19.603,53.770 17.692,45.333 19.106 "
        stroke="none"
        fill="#000000"
        fillRule="evenodd"
      ></path>
    </g>
  </svg>
);
