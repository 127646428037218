import React from 'react';
import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';
// eslint-disable-next-line no-unused-vars
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
// eslint-disable-next-line no-unused-vars
import { fetchAnswer, fetchQuestions } from '@app/services/PochemushaService';

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import icon from './pochemusha2.jpg';
import close from './close.png';
import './style.scss';

const aiMessage = message => ({
  message,
  sentTime: new Date(),
  sender: 'Почемуша',
  avatar: <Avatar src={icon} />,
});

function PochemushaChat({ toggleChat }) {
  const [messages, setMessage] = React.useState([aiMessage('Привет, мой друг! Я с радостью отвечу на твои вопросы. Спрашивай, не стесняйся.')]);

  const loadAnswer = (question, list) => {
    try {
      // eslint-disable-next-line promise/catch-or-return
      fetchAnswer(question).then(res => setMessage([...list, aiMessage(res.answerText)]));
    } catch (e) {
      setMessage([...list, aiMessage('К сожалению я не могу ответить на этот вопрос сейчас')]);
    }
  };

  const handleClick = (question) => {
    MixpanelService.track(mixpanelMap.ASK_POCHEMUSHA);
    const list = [...messages, {
      message: question,
      sentTime: new Date(),
      sender: 'Я',
      direction: 'outgoing',
    }];
    setMessage(list);
    loadAnswer(question, list);
  };

  return (<div className="pst-why-chat">
    <a className='pst-why-chat__close' href="#" onClick={toggleChat}><img src={close}/></a>
    <MainContainer responsive={false}>
      <ChatContainer>
        <MessageList>
          {messages.map(x => <Message
            model={{
              ...x,
            }}
          >
            {x.avatar}
          </Message>)}
        </MessageList>
        <MessageInput placeholder="Задай вопрос Почемуше" onSend={handleClick} attachButton={false}/>
      </ChatContainer>
    </MainContainer>
  </div>);
}

export default PochemushaChat;
