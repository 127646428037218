import { useEffect, useCallback } from 'react';

function eqSet(as, bs) {
  if (as.size !== bs.size) return false;
  // todo: Remove eslint-disable
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const a of as) if (!bs.has(a)) return false;
  return true;
}

function useOnKeyPress(keys, callback, deps = []) {
  const memoizedCallback = useCallback(callback, deps);
  const targetKeys = new Set(keys);
  const pressedKeys = new Set();

  function onKeyPressed({ key }) {
    pressedKeys.add(key);

    if (eqSet(targetKeys, pressedKeys)) {
      memoizedCallback();
    }
  }

  function onKeyUp({ key }) {
    pressedKeys.delete(key);
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyPressed);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyPressed);
      window.removeEventListener('keydown', onKeyUp);
    };
  }, [callback, onKeyPressed, onKeyUp]);
}

export default useOnKeyPress;
