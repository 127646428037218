import React from 'react';

export default ({ alternative }) => (
  <svg
    width='24'
    height='24'
    className="icon icon-burger"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4C1.86739 4 1.74021 4.05268 1.64645 4.14645C1.55268 4.24021 1.5 4.36739 1.5 4.5C1.5 4.63261 1.55268 4.75979 1.64645 4.85355C1.74021 4.94732 1.86739 5 2 5H22C22.1326 5 22.2598 4.94732 22.3536 4.85355C22.4473 4.75979 22.5 4.63261 22.5 4.5C22.5 4.36739 22.4473 4.24021 22.3536 4.14645C22.2598 4.05268 22.1326 4 22 4H2ZM2 20C1.86739 20 1.74021 20.0527 1.64645 20.1464C1.55268 20.2402 1.5 20.3674 1.5 20.5C1.5 20.6326 1.55268 20.7598 1.64645 20.8536C1.74021 20.9473 1.86739 21 2 21H22C22.1326 21 22.2598 20.9473 22.3536 20.8536C22.4473 20.7598 22.5 20.6326 22.5 20.5C22.5 20.3674 22.4473 20.2402 22.3536 20.1464C22.2598 20.0527 22.1326 20 22 20H2Z"
      fill="black"
      stroke={alternative ? '#fff' : 'black'}
    />
    <path
      d="M22 11.5H2C1.44772 11.5 1 11.9477 1 12.5C1 13.0523 1.44772 13.5 2 13.5H22C22.5523 13.5 23 13.0523 23 12.5C23 11.9477 22.5523 11.5 22 11.5Z"
      fill={alternative ? '#fff' : '#721FD9'}
    />
  </svg>
);
