import React from 'react';
import Text from '../Text';

import './style.scss';

const Recommendations = ({ recommendations }) => {
  const examples = recommendations.slice(3);
  const showCounter = examples.length !== 1;

  return (
    recommendations.length > 1 && <div className="pst-recommendations">
      <div className='pst-recommendations__main'>
        <Text className='pst-recommendations__title' level="2" weight="800">
          <div dangerouslySetInnerHTML={{ __html: recommendations[0].value }}></div>
        </Text>
        <Text className='pst-recommendations__description' level="6">
          <div dangerouslySetInnerHTML={{ __html: recommendations[1].value }}></div>
        </Text>
      </div>
      <Text className='pst-recommendations__subtitle' level="4" weight="800">
        <div dangerouslySetInnerHTML={{ __html: recommendations[2].value }}></div>
      </Text>
      {examples.map(({ value }, index) => (
        <div className={`pst-recommendations__example pst-recommendations__example--${showCounter ? 'showCounter' : 'disableCounter'}`} key={index}>
          {showCounter && <div className='pst-recommendations__counter'>
            <Text level="6" weight="800">{index + 1}</Text>
          </div>}
          <Text level="6">
            <div dangerouslySetInnerHTML={{ __html: value }}></div>
          </Text>
        </div>
      ))}
    </div>
  );
};

export default Recommendations;
