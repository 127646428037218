// eslint-disable-next-line import/prefer-default-export
export const getFormattedDate = (date, options) => {
  const { withYear, withTime, withShortYear } = options;

  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  let month = (date.getMonth() + 1).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  let result = `${day}.${month}`;
  if (withYear) result = `${day}.${month}.${year}`;
  if (withShortYear) result = `${day}.${month}.${String(year).substr(-2, 2)}`;
  if (withTime) result = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;

  return result;
};
