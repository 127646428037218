import React from 'react';
import PropTypes from 'prop-types';
import getClassName from '@app/helpers/getClassName';

const TabPane = ({
  elementType,
  className,
  style,
  children,
  active,
  disabled,
  ...extraProps
}) => {
  const El = elementType;
  const classActive = active ? 'is-active' : '';
  const classDisabled = disabled ? 'is-disabled' : '';
  const _className = getClassName(['pst-tab', className, classActive, classDisabled]);

  return (
    <El
      className={_className}
      style={style}
      {...extraProps}>
      {children}
      <div className="pst-tab__background"></div>
    </El>
  );
};

TabPane.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  elementType: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

TabPane.defaultProps = {
  elementType: 'div',
  active: false,
  disabled: false,
};

export default TabPane;
