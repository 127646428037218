import React from 'react';

export default ({ template }) => {
  if (template === 'color') {
    return (
      <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.0683 21.6666V11.9354H11.3347L11.8237 8.143H8.0683V5.72167C8.0683 4.62371 8.37323 3.87539 9.94785 3.87539L11.9561 3.87446V0.482601C11.6086 0.436552 10.4166 0.333328 9.02976 0.333328C6.13435 0.333328 4.15209 2.1006 4.15209 5.34628V8.14311H0.877274V11.9355H4.15199V21.6667L8.0683 21.6666Z"
          fill="#4267B2"/>
      </svg>
    );
  }

  return (
    <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6025 31.9998V17.4031H16.502L17.2355 11.7145H11.6025V8.08252C11.6025 6.43557 12.0599 5.31309 14.4218 5.31309L17.4341 5.3117V0.223909C16.9129 0.154836 15.1249 0 13.0446 0C8.70154 0 5.72815 2.65091 5.72815 7.51942V11.7147H0.815918V17.4033H5.72799V32L11.6025 31.9998Z"
        fill="white"/>
    </svg>
  );
};
