import { SHOW_CANCEL_AUTOSUB_POPUP, AUTOSUB_CANCELLATION_STATUS, SET_ORDER } from '@app/store/constants/actionTypes';
import { deleteSub, getSubscriptions } from '@app/services/PaymentService';

export const closeAutosubPopup = () => (dispatch) => {
  dispatch({ type: AUTOSUB_CANCELLATION_STATUS, status: null });
  dispatch({ type: SHOW_CANCEL_AUTOSUB_POPUP, show: false });
};

export const handleCancelAutosub = () => async (dispatch, getState) => {
  try {
    const { order } = getState().subscriptions;
    const updOrder = { ...order };
    const subscriptions = await getSubscriptions();
    const activeSubs = subscriptions.filter(({ status }) => status === 'Active');

    if (activeSubs && activeSubs.length) {
      await Promise.all(activeSubs.map(({ id }) => deleteSub(id)));
      updOrder.canceled = true;
      dispatch({ type: SET_ORDER, order: updOrder });
    } else {
      throw Error('Empty subscriptions, nothing to cancel');
    }

    dispatch({ type: AUTOSUB_CANCELLATION_STATUS, status: 'success' });
  } catch (e) {
    dispatch({ type: AUTOSUB_CANCELLATION_STATUS, status: 'error' });
  }
};
