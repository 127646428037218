import React from 'react';
import Swiper from 'react-id-swiper';
import Icon from '@app/components/NEW_ui/Icon';

// eslint-disable-next-line import/no-cycle
import Slide from '../Slide';

import './style.scss';
import 'swiper/css/swiper.css';

const SlidesSlider = ({ templates, slideId, onClick, designId, isBought }) => {
  const handleTemplateSelect = (index, _id) => {
    onClick(slideId, _id);
  };

  const sliderParams = {
    slidesPerView: 'auto',
    spaceBetween: 8,
    mousewheel: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderNextButton: () => (
      <div className='pst-slider__slideRightBtn swiper-button-next'>
        <Icon name='Arrow' direction='left' size='sm' />
      </div>
    ),
    renderPrevButton: () => (
      <div className='pst-slider__slideLeftBtn swiper-button-prev'>
        <Icon name='Arrow' size='sm' />
      </div>
    ),
  };

  const renderSlides = () => templates && templates.map((item, index) => {
    const isActive = designId === item._id;

    const liParams = {
      key: item._id,
      className: `pst-slider__preview pst-slider__preview--${isActive ? 'active' : 'inactive'}`,
    };
    const params = {
      showActions: false,
      _type: item.type,
      template: 'preview',
      isActive,
      isBought,
    };

    return (
      <div {...liParams}>
        <Slide {...params}>{item.html}</Slide>
        <div className={'pst-slider__clickable'} onClick={() => handleTemplateSelect(index, item._id)} />
      </div>
    );
  });

  return (
    <div className='pst-slider'>
      <Swiper {...sliderParams}>
        {renderSlides()}
      </Swiper>
    </div>
  );
};

export default SlidesSlider;
