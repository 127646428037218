import React from 'react';
import ReadStoryButton from '@app/components/NEW_ui/ReadStoryButton';
import TabsDesign from '../TabsDesign';
import './style.scss';

export default ({ showStep, changeStepHandler, slideId }) => (
  <div className='pst-menuBottom'>
    <TabsDesign showStep={showStep} changeStepHandler={changeStepHandler} />
    <ReadStoryButton slideId={slideId} />
  </div>
);
