import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getClassName from '@app/helpers/getClassName';
import './style.scss';

const types = ['email', 'password', 'text'];

class Input extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    type: PropTypes.oneOf(types),
    label: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    elementType: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  };

  static defaultProps = {
    type: 'text',
    elementType: 'input',
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      name: props.name,
      isFocus: false,
      hasLabel: !!props.label,
    };
  }

  handleFocus = (e) => {
    const { onFocus } = this.props;

    this.setState({ isFocus: true });

    if (onFocus) onFocus(e);
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;

    this.setState({ isFocus: false });

    if (onBlur) onBlur(e);
  };

  handleChange = (e) => {
    e.persist();
    const { onChange } = this.props;

    this.setState({ value: e.target.value }, () => {
      if (onChange) onChange(e);
    });
  };

  isError() {
    return !!this.props.error;
  }

  hasError() {
    return typeof this.props.error === 'string';
  }

  renderLabel = () => {
    const { isFocus, value } = this.state;
    const { name, label } = this.props;

    const classFocus = isFocus ? 'is-focus' : '';
    const classFilled = value ? 'is-filled' : '';
    const className = getClassName(['pst-input__label', classFocus, classFilled]);

    return (
      <div className={className}>
        <label className='pst-input__label-inner' htmlFor={name}>{label}</label>
      </div>
    );
  };

  renderField = () => {
    const { elementType: El, type, name, onKeyPress } = this.props;
    const { value } = this.state;

    return (
      <div className='pst-input__field'>
        <El
          ref={(input) => { this.input = input; }}
          type={type}
          name={name}
          className='pst-input__field-inner'
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyPress={onKeyPress}
          value={value}
          onChange={this.handleChange}
        />
      </div>
    );
  };

  renderError = () => {
    const { error } = this.props;
    return (
      <div className='pst-input__error'>{this.hasError() ? error : ''}</div>
    );
  };

  render() {
    const { hasLabel } = this.state;
    const { className } = this.props;

    const classError = this.isError() ? 'is-error' : '';
    const _className = getClassName(['pst-input', className, classError]);

    return (
      <div className={_className}>
        {hasLabel && this.renderLabel()}
        {this.renderField()}
        {this.isError() && this.renderError()}
      </div>
    );
  }
}

export default Input;
