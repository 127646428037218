import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormComponent from '@app/components/NEW_ui/FormComponent';
import Logo from '@app/components/NEW_ui/Logo';
// import TabsWithNav from '@app/components/NEW_ui/TabsWithNav';
import BigButton from '@app/components/NEW_ui/BigButton';
import Text from '@app/components/NEW_ui/Text';
import RadioGroup from '@app/components/NEW_ui/Input/radioGroup';
import Radio from '@app/components/NEW_ui/Input/radio';
import isEnglishLanguage from '@app/helpers/isEnglishLanguage';
import isRussianLanguage from '@app/helpers/isRussianLanguage';

export default ({ onSubmit, onError, buttonText, buttonDisabled, children, showRestore, changeLanguage, showPrivacyConfirm, showPasswordNotice }) => {
  const { t } = useTranslation();

  // const tabs = [
  //   { name: t('auth.login'), to: '/login' },
  //   { name: t('auth.signup'), to: '/signup' },
  // ];

  const isEnglish = isEnglishLanguage();

  return (
    <FormComponent className='pst-login' onSubmit={onSubmit} onError={onError} checkOnSubmit>
      <div className='pst-login__wrapper'>
        <Logo className='pst-login__title' />
        <div className='pst-login__subtitle'>{t('auth.title')}</div>
        {/* <TabsWithNav className='pst-login__nav' startPathWith='/auth' tabs={tabs} /> */}

        <div className='pst-login__fields-container'>
          {children}
          <Text
            level="6"
            template="grey"
            inline
            className="pst-login__password-notice"
          >
            {showPasswordNotice && t('auth.passwordNotice')}
          </Text>
        </div>
        {showRestore && <Link className='pst-login__restore-link' to='/auth/restore'>{t('auth.restore')}</Link>}

        <div className='pst-login__intl'>
          <RadioGroup
            className='pst-login__radio-group'
            name='language'
            onChange={changeLanguage}
          >
            <Radio value='en' selected={isEnglish} ucFirst />
            <Radio value='ru' selected={isRussianLanguage()} ucFirst />
          </RadioGroup>
        </div>

        {showPrivacyConfirm && <div className='pst-login__privacyConfirm'>
          <span className='pst-login__privacyText'>{t('auth.privacyConfirm')}</span>{' '}
          <a href={`https://myskazka.com/termsofuse${isEnglish ? '_en' : ''}`} target='_blank' className='pst-login__privacyLink'>
            {t('auth.termOfUse')}
          </a>{' '}
          <span className='pst-login__privacyText'>
            {t('auth.privacySeparator')}
          </span>{' '}
          <a href={`https://myskazka.com/policy${isEnglish ? '_en' : ''}`} target='_blank' className='pst-login__privacyLink'>
            {t('auth.privacyPolicy')}
          </a>
        </div>}

      </div>
      {buttonText && <div className='pst-login__footer'>
        <BigButton disabled={buttonDisabled}>{buttonText}</BigButton>
      </div>}
    </FormComponent>
  );
};
