import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getClassName from '@app/helpers/getClassName';
import Radio from './radio';

export default class RadioGroup extends Component {
  static childContextTypes = {
    radioGroup: PropTypes.object,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.inputsRef = {};
  }

  getChildContext() {
    const { name } = this.props;

    return {
      radioGroup: {
        name,
        onChange: this.onChange.bind(this),
      } };
  }

  onChange(selected, child, value) {
    const { onChange, name } = this.props;

    Object.keys(this.inputsRef)
      .map(inputKey => this.inputsRef[inputKey].current)
      .forEach((option) => {
        if (option !== child) {
          option.setSelected(!selected);
        }
      });

    if (onChange) onChange(name, value);
  }

  render() {
    const className = getClassName([this.props.className]);

    const children = React.Children.map(this.props.children, (child) => {
      // it might be better to use react-children-utilities
      if (!React.isValidElement(child)) return child;

      if (child.type !== Radio) return child;

      const ref = React.createRef();
      this.inputsRef[child.props.value] = ref;

      return React.cloneElement(child, {
        ref,
      });
    });

    return <div className={className}>{children}</div>;
  }
}
