import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactImage from 'react-image';

import Text from '@app/components/NEW_ui/Text';
import Icon from '@app/components/NEW_ui/Icon';
import Button from '@app/components/NEW_ui/Button';

import safariUrlBar from '@app/assets/images/safariUrlBar.png';

import './style.scss';

const FullscreenTooltip = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <div className="pst-fullscreenTooltip">
      <div className="pst-fullscreenTooltip__title">
        <Text level="6" weight={800} inline>{t('fullscreenTooltip.title')}</Text>
      </div>

      <div className="pst-fullscreenTooltip__firstItem">
        <div className="pst-fullscreenTooltip__itemFlex">
          <Text level="6" inline>{t('fullscreenTooltip.btnClick')}</Text>
          <div className="pst-fullscreenTooltip__AAIcon">
            <Icon name="AAIcon" size="sm" />
          </div>
          <Text level="6" inline>{t('fullscreenTooltip.btnPosition')}</Text>
        </div>
        <div className="pst-fullscreenTooltip__illustration">
          <div className="pst-fullscreenTooltip__interfaceBtn">
            <Icon name="AAIcon" size="md" />
          </div>
          <ReactImage
            className="pst-fullscreenTooltip__safariUrlBar"
            src={safariUrlBar}
            alt='Main Dashboard'
            loader='Загрузка...'
          />
        </div>
      </div>
      <div className="pst-fullscreenTooltip__secondItem">
        <div className="pst-fullscreenTooltip__itemFlex">
          <Text level="6" inline>{t('fullscreenTooltip.selectItem')}</Text>
          <div className="pst-fullscreenTooltip__AAIcon">
            <Icon name="ResizeArrows" size="sm" />
          </div>
        </div>
        <Text level="6" weight={800} inline>{t('fullscreenTooltip.comeBack')}</Text>
      </div>

      <Button template='select-white' classNames="pst-fullscreenTooltip__closeBtn" onClick={handleClose}>
        <Icon name="Close" template='gray' alternative size="sm" />
      </Button>
    </div>
  );
};

export default FullscreenTooltip;
