import {
  SHOW_CANCEL_AUTOSUB_POPUP,
  AUTOSUB_CANCELLATION_STATUS,
  SET_ORDER,
  SHOW_LOCK_SCREEN,
  FREE_TOPIC,
} from '@app/store/constants/actionTypes';

const initialState = {
  showCancelAutosubPopup: false,
  autosubCancellationStatus: null,
  order: null,
  showLockScreen: false,
  price: {},
  freeTopic: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CANCEL_AUTOSUB_POPUP:
      return { ...state, showCancelAutosubPopup: action.show };
    case SET_ORDER:
      return { ...state, order: action.order };
    case SHOW_LOCK_SCREEN:
      return { ...state, price: action.price, showLockScreen: action.show };
    case AUTOSUB_CANCELLATION_STATUS:
      return { ...state, autosubCancellationStatus: action.status };
    case FREE_TOPIC:
      return { ...state, freeTopic: action.freeTopic };
    default:
      return state;
  }
};
