import React from 'react';
import PropTypes from 'prop-types';
import Ripple from '@intereact/ripple';

import Text from '@app/components/NEW_ui/Text';
import CardBriefIcon from '@app/components/NEW_ui/CardBriefIcon';
import CardBriefSponsor from '@app/components/NEW_ui/CardBriefSponsor';

import './style.scss';

const CardBrief = ({ onClick, disabled, classNames, title, description, tags, free, icon, sponsorIcon }) => {
  const className = `pst-card-brief ${disabled && 'pst-card-brief--disable'} ${classNames}`;
  return (
    <Ripple color="rgba(156, 76, 255, 0.1)">
      {ripples => (
        <div
          className={className}
          onClick={onClick}
          disabled={disabled}
        >
          <div>{ripples}</div>
          <div className='pst-card-brief__metadata'>
            <CardBriefIcon icon={icon}/>
            <CardBriefSponsor sponsorIcon={sponsorIcon}/>
            { free && <div className='pst-card-brief__free'>Бесплатно</div> }
          </div>
          <div className='pst-card-brief__title'>
            <Text level="3" template="default" weight={800} inline>{title}</Text>
          </div>

          <div className='pst-card-brief__text-content'>
            <Text level="4" inline>{description}</Text>
          </div>

          <div className='pst-card-brief__link-container'>
            {tags.map(item => (
              <div className='pst-card-brief__link' key={item._id}>
                <Text level="4" template="purple" inline>#{item.title}</Text>
              </div>
            ))}
          </div>
        </div>
      )}
    </Ripple>
  );
};

CardBrief.defaultProps = {
  onClick: () => { },
  disabled: false,
  classNames: '',
  title: 'Показать опыт работы',
  description: 'Заполнение анкеты происходит в режиме вопрос-ответ',
  tags: ['Портфолио', 'Резюме'],
  free: false,
  icon: undefined,
  sponsorIcon: undefined,
};

CardBrief.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
  free: PropTypes.bool,
  icon: PropTypes.string,
  sponsorIcon: PropTypes.string,
};

export default CardBrief;
