import React from 'react';

export default () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="32"
    height="32"
    viewBox="0 0 486.547 486.547"
    style={{ enableBackground: 'new 0 0 486.547 486.547}' }}
    space="preserve"
  >
    <g>
      <g>
        <path
          d="M251.568,68.851c-6.199,0-11.56,2.264-16.083,6.787l-118.991,76.164H22.871c-6.2,0-11.561,2.265-16.083,6.787
            C2.258,163.117,0,168.472,0,174.665v137.217c0,6.199,2.264,11.561,6.787,16.083c4.522,4.529,9.884,6.787,16.083,6.787h93.624
            l118.998,76.157c4.522,4.529,9.883,6.787,16.083,6.787c6.193,0,11.549-2.265,16.078-6.787c4.521-4.522,6.787-9.884,6.787-16.083
            V91.721c0-6.193-2.266-11.555-6.787-16.083C263.117,71.121,257.762,68.851,251.568,68.851z"
        />
        <path
          d="M379.881,293.84c10.123-15.6,15.184-32.454,15.184-50.563c0-18.103-5.061-35.019-15.184-50.741
            c-10.129-15.722-23.525-26.8-40.203-33.232c-2.387-1.187-5.361-1.787-8.934-1.787c-6.201,0-11.555,2.203-16.078,6.609
            c-4.529,4.413-6.787,9.829-6.787,16.261c0,5,1.432,9.235,4.291,12.687c2.857,3.458,6.309,6.432,10.359,8.935
            c4.047,2.503,8.098,5.245,12.148,8.219c4.047,2.98,7.504,7.209,10.361,12.687c2.859,5.484,4.291,12.271,4.291,20.367
            c0,8.104-1.432,14.891-4.291,20.368c-2.857,5.483-6.314,9.712-10.361,12.687c-4.051,2.98-8.102,5.716-12.148,8.219
            c-4.051,2.503-7.502,5.483-10.359,8.936c-2.859,3.458-4.291,7.681-4.291,12.687c0,6.433,2.264,11.854,6.787,16.261
            c4.523,4.413,9.883,6.609,16.076,6.609c3.574,0,6.549-0.594,8.936-1.787C356.355,320.584,369.752,309.446,379.881,293.84z"
        />
        <path
          d="M375.773,74.97c-3.096-1.187-6.199-1.787-9.289-1.787c-6.199,0-11.561,2.265-16.084,6.787
            c-4.529,4.529-6.787,9.89-6.787,16.083c0,9.29,4.645,16.322,13.936,21.083c13.342,6.916,22.387,12.148,27.16,15.722
            c17.625,12.864,31.383,29.003,41.273,48.421c9.885,19.419,14.828,40.08,14.828,61.996c0,21.922-4.943,42.583-14.828,62.002
            c-9.891,19.419-23.646,35.557-41.273,48.421c-4.768,3.574-13.818,8.813-27.16,15.723c-9.291,4.768-13.936,11.793-13.936,21.083
            c0,6.2,2.266,11.562,6.787,16.084c4.523,4.528,10.006,6.787,16.439,6.787c2.857,0,5.832-0.594,8.934-1.787
            c33.348-14.052,60.148-36.506,80.398-67.356c20.246-30.852,30.375-64.499,30.375-100.95c0-36.45-10.129-70.092-30.375-100.949
            C435.922,111.476,409.121,89.028,375.773,74.97z"
        />
      </g>
    </g>
  </svg>
);
