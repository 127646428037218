import { fetchPresentationData, updatePresentation } from '@app/services/PresentationService';
import { fetchSlideDesign, fetchDesign } from '@app/services/DesignService';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';

import {
  COMPONENT_DESIGN_FETCH,
  COMPONENT_DESIGN_UPDATE_PASTYLLA,
} from '@app/store/constants/actionTypes';

export const fetchSlides = async (item, topic) => {
  const { structure, _id } = item;
  const needetSlide = structure.find(({ _type }) => _type === topic.structure[0]._type);

  const slide = await fetchSlideDesign(_id, needetSlide._id);
  return { ...item, slide };
};

export const fetchDesignTopic = topic => async (dispatch) => {
  const designsId = topic.design;

  return Promise.all(designsId.map(_id => fetchDesign(_id)))
    .then(res => Promise.all(res.map(item => fetchSlides(item, topic))))
    .then(designs => dispatch({ type: COMPONENT_DESIGN_FETCH, designs }))
    // eslint-disable-next-line no-console
    .catch(err => console.error(err));
};

export const fetchPresentation = id => async (dispatch) => {
  const pastylla = await fetchPresentationData(id);
  dispatch({ type: COMPONENT_DESIGN_UPDATE_PASTYLLA, pastylla });
};

export const updatePastyllaLocal = pastylla => (dispatch) => {
  dispatch({ type: COMPONENT_DESIGN_UPDATE_PASTYLLA, pastylla });
};

export const updatePastylla = (data, id, editOptions) => async () => {
  const response = await updatePresentation(data, id);

  const { type, title } = editOptions;
  MixpanelService.track(`${mixpanelMap.STORY_EDITING} ${type}`, { storyTitle: title });

  return response;
};

export const returnForm = (archive) => {
  const form = new FormData();
  form.append('file', archive, `${Date.now()}.pastylla`);
  return form;
};
