import React, { Component } from 'react';
import getClassName from '../getClassName';
// todo: выпилить
export default function (WrappedComponent) {
  return class extends Component {
    render() {
      const { className } = this.props;
      const classes = getClassName(className);

      return <WrappedComponent className={classes} {...this.props} />;
    }
  };
}
