import { useState, useEffect } from 'react';

const useWindowScroll = () => {
  const isClient = typeof window === 'object';

  const getScroll = () => (isClient ? window.pageYOffset : undefined);

  const [scroll, setScroll] = useState(getScroll);

  useEffect(() => {
    if (!isClient) return false;

    const handleScroll = () => {
      setScroll(getScroll());
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return scroll;
};

export default useWindowScroll;
