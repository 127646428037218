import React from 'react';
import PropTypes from 'prop-types';
import Ripple from '@intereact/ripple';
import getClassName from '@app/helpers/getClassName';

import './style.scss';

const BigButton = ({ onClick, disabled, className, text, subtitle, children, ...rest }) => (
  <Ripple color="rgba(116, 33, 217, 0.5)">
    {ripples => (
      <button
        className={getClassName(['pst-bigButton', className])}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {ripples}
        {children && <div className="pst-bigButton__text">{children}</div>}
        {subtitle && <div className="pst-bigButton__subtitle">{subtitle}</div>}
      </button>
    )}
  </Ripple>
);

BigButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: '',
  subtitle: null,
};

BigButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  subtitle: PropTypes.string,
};

export default BigButton;
