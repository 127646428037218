import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@app/components/NEW_ui/Icon';
import Text from '@app/components/NEW_ui/Text';

import './style.scss';

const InformAlert = ({ icon, message }) => (
  <div className="pst-informAlert">
    <div className="pst-informAlert__iconWrap">
      <Icon name={icon} className="pst-informAlert__icon" size='sm' />
    </div>

    <div className="pst-informAlert__message">
      <Text level="6" template='white' inline>{message}</Text>
    </div>
  </div>
);

InformAlert.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
};

InformAlert.defaultProps = {
  message: '',
};

export default InformAlert;
