import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import CardBriefIcon from '../CardBriefIcon';

const CardBriefSponsor = ({ sponsorIcon }) => {
  if (!sponsorIcon) return false;
  return (
    <div className='pst-briefsponsor'>
      <span className='pst-briefsponsor__text'>Совместно с</span> <CardBriefIcon icon={sponsorIcon}/>
    </div>
  );
};

CardBriefSponsor.defaultProps = {
  sponsorIcon: undefined,
};

CardBriefSponsor.propTypes = {
  sponsorIcon: PropTypes.string,
};

export default CardBriefSponsor;
