import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M15.5 3L21 8.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 15.5L1 23L8.5 21L3 15.5ZM3 15.5L15.5 3L16.25 2.25C17.7688 0.731216 20.2312 0.731218 21.75 2.25V2.25C23.2688 3.76878 23.2688 6.23122 21.75 7.75L21 8.5L18.75 10.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11 18.5L16 13.5" stroke="#7421D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
