import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import { socialLogin } from '@app/pages/Auth/redux/actions';
import Icon from '@app/components/NEW_ui/Icon/index';
import SocialBtn from '@app/components/NEW_ui/SocialBtn';
import GoogleBtn from '@app/components/NEW_ui/GoogleBtn';
import getLanguage from '@app/helpers/getLanguage';

import './style.scss';

const btns = {
  ru: [
    // { state: 'google', name: 'Google' },
    { state: 'facebook', name: 'Facebook' },
    { state: 'vkontakte', name: 'Vk' },
    // { state: 'odnoklassniki', name: 'Ok' },
  ],
  en: [
    // { state: 'google', name: 'Google' },
    { state: 'facebook', name: 'Facebook' },
    { state: 'vkontakte', name: 'Vk' },
    // { state: 'odnoklassniki', name: 'Ok' },
  ],
};
// фикс кодировки
const AuthSocialBlock = ({ socialLogin: cd }) => {
  const lang = getLanguage();
  const finalBtns = btns[lang];

  return (
    <div className="pst-authSocialBlock">
      <GoogleBtn onClick={cd('google')}/>
      {finalBtns.map(({ state, name }) => (
        <SocialBtn className="pst-authSocialBlock__btn" template="auth" bgColor="#80868b1a" onClick={cd(state)} key={state}>
          <Icon name={name} size="sm" template='color' />
        </SocialBtn>
      ))}
    </div>
  );
};

AuthSocialBlock.propTypes = {
  socialLogin: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ socialLogin }, dispatch);
export default withTranslation()(connect(null, mapDispatchToProps)(AuthSocialBlock));
