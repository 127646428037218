import React from 'react';

import Icon from '@app/components/NEW_ui/Icon';
import RoundLoader from '@app/components/NEW_ui/RoundLoader';

import './style.scss';

const AudioControlBtn = ({ size = 'small', iconName = '', classNames = '', loading = false, isActive = false, disabled = false, onClick }) => {
  const className = `pst-audioPlay pst-audioPlay--size_${size} ${isActive ? 'is-active' : ''} ${classNames}`;

  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      <div className="pst-audioPlay__iconWrap">
        {!loading && <Icon
          name={iconName}
          size="sm"
          className="pst-audioPlay__icon"
        />}

        {loading && <RoundLoader size='small' color='white' />}
      </div>
    </button>
  );
};

export default AudioControlBtn;
