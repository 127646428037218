import { createSelector } from 'reselect';
import isEnglishLanguage from '@app/helpers/isEnglishLanguage';

const getSelectedTags = state => state.dashboard.filters.tags;

// eslint-disable-next-line
const getTopicList = (state) => {
  const { enTopics, ruTopics } = state.dashboard;
  return isEnglishLanguage() ? enTopics : ruTopics;
};

const getVisibleTodos = createSelector(
  [getSelectedTags, getTopicList],
  (selectedTags, dashboardList) => dashboardList.filter((topic) => {
    if (!topic.gender) {
      return false;
    }
    const intersection = topic.tags.filter((tag) => {
      tag.template = 'default';
      if (selectedTags.includes(tag.title)) {
        tag.template = 'purple';
        return true;
      }
      return false;
    });
    if (!selectedTags.length) return true;
    // eslint-disable-next-line no-else-return
    else return intersection.length;
  }),
);

export default getVisibleTodos;
