import http from '@app/services/HttpService';

export const defaultOptions = {
  voice: 'Alexander',
  numChannels: 1,
  sampleRate: 22050,
  play: false,
};

class WsService {
  static getWs() {
    const ws = new WsService();
    return (function () {
      return ws;
    }());
  }

  constructor() {
    this.int16Array = null;
    this.wsTimer = null;
    this.options = defaultOptions;
    this.interrupted = false;
    this.textPartIndex = 0;
    this.textParts = [];
    this.onFinish = () => {
      // eslint-disable-next-line no-console
      console.log('All audio is loaded');
    };
    this.onReceiveData = () => {
      // eslint-disable-next-line no-console
      console.log('Audio loaded');
    };
  }

  // eslint-disable-next-line class-methods-use-this
  prepareParts(text) {
    return text.split(/([.\n])+(?!\s*$)/)
      .reduce((res, item) => {
        if (item.length === 1) {
          res[res.length - 1] += item;
          return res;
        }

        if (item.startsWith('<style')) {
          res.openTag = item.substr(0, item.indexOf('>') + 1);
          res.closeTag = `${item.substr(0, item.indexOf(' '))
            .replace('<', '</')}>`;
        }

        if (res.openTag) {
          res.hasOpenTag = item.includes(res.openTag);
          res.hasCloseTag = item.includes(res.closeTag);

          res.push(`${res.openTag}${item.replace(res.openTag, '')
            .replace(res.closeTag, '')}${res.closeTag}`);
        } else if (item) {
          res.push(item);
        }

        if (res.hasCloseTag) {
          res.hasOpenTag = undefined;
          res.hasCloseTag = undefined;
          res.openTag = undefined;
          res.closeTag = undefined;
        }
        return res;
      }, []);
  }

  sendSocket(text = '', options = defaultOptions) {
    this.textParts = this.prepareParts(text);
    this.options = options;

    if (this.textParts.length < 1) return;

    // eslint-disable-next-line no-console
    console.info(`sendSocket: ${JSON.stringify(this.textParts)}`);

    this.textPartIndex = 0;
    return this.synthesizeStream();
  }

  getTtsStreamUrl() {
    return http.post(`tts/stream/${this.options.voice}`)
      .then(({ data: { url, sessionId, transactionId } }) => {
        this.sessionId = sessionId;
        this.transactionId = transactionId;
        // eslint-disable-next-line no-console
        console.info(`sessionId: ${sessionId}, transactionId: ${transactionId}`);
        return url;
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  }

  getSocket() {
    try {
      return this.getTtsStreamUrl()
        .then((socketUrl) => {
          this.socket = new WebSocket(socketUrl);
          this.socket.binaryType = 'blob';
          this.retryGetSocket = 0;
          return this.socket;
        })
      // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    } catch (e) {
      this.retryGetSocket++;
      // eslint-disable-next-line prefer-promise-reject-errors
      if (this.retryGetSocket > 10) {
        this.retryGetSocket = 0;
        return new Promise((resolve, reject) => reject(new Error('Ошибка получения ссылки подключения')));
      }
      return this.getSocket();
    }
  }

  setInterrupted(interrupted) {
    this.interrupted = interrupted;
  }

  destroySocket() {
    return http.delete('tts/stream', {
      headers: {
        'x-session-id': this.sessionId,
        'x-transaction-id': this.transactionId,
      },
    })
      .then(() => {
        this.sessionId = null;
        this.transactionId = null;
        return true;
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  }

  // eslint-disable-next-line class-methods-use-this
  appendBuffer(buffer1, buffer2) {
    const tmp = new Int16Array(buffer1.byteLength / 2 + buffer2.byteLength / 2);
    tmp.set(new Int16Array(buffer1), 0);
    tmp.set(new Int16Array(buffer2), buffer1.byteLength / 2);
    return tmp;
  }

  synthesizeStream() {
    if (this.textPartIndex >= this.textParts.length) {
      return this.onFinish(true);
    }

    const currentText = this.textParts[this.textPartIndex++].trim();
    // eslint-disable-next-line no-console
    console.log(`\n\n\n ${currentText}`);
    this.int16Array = new Int16Array();
    this.packageIndex = 0;
    return this.getSocket()
      .then(socket => new Promise((resolve, reject) => {
        socket.onerror = err => reject(err);

        // eslint-disable-next-line no-console
        socket.onclose = () => console.info('Socket closed');

        socket.onmessage = (event) => {
          clearTimeout(this.wsTimer);

          if (this.interrupted) return reject();

          const reader = new FileReader();
          reader.addEventListener('loadend', () => {
            try {
              const data = reader.result;
              // eslint-disable-next-line no-console
              console.log(`Package ${++this.packageIndex} size: ${data.byteLength}`);
              this.int16Array = this.appendBuffer(this.int16Array, data);
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error('Error processing data package');
              // eslint-disable-next-line no-console
              console.error(e);
            }
          });
          reader.readAsArrayBuffer(event.data);

          this.wsTimer = setTimeout(() => {
            if (this.interrupted) return reject();
            this.onReceiveData(this.int16Array);

            this.destroySocket()
              .then(() => resolve(this.synthesizeStream()))
              // eslint-disable-next-line no-console
              .catch(err => console.error(err));
          }, 1000);
        };

        socket.onopen = () => socket.send(currentText);
      }))
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  }
}

export default WsService;
