import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import setLanguage from '@app/helpers/setLanguage';
import Text from '@app/components/NEW_ui/Text';

import AuthFormContainer from './AuthFormContainer';

class UseApp extends Component {
  handleChangeLanguage = (name, value) => {
    const { i18n } = this.props;
    setLanguage(i18n, value);
  };

  render() {
    const { t, history } = this.props;

    return (
      <AuthFormContainer
        onSubmit={() => {
          history.push('/');
        }}
        changeLanguage={this.handleChangeLanguage}
      >

        <div className='pst-login__fields-container'>
          <p>
            <Text
              level="5"
              template="grey"
              inline
            >
              {t('auth.passwordChanged')}
            </Text>
          </p>
        </div>
        <div>
          {t('auth.returnToApp')}
        </div>
      </AuthFormContainer>
    );
  }
}

export default withTranslation()(connect(null, null)(UseApp));
