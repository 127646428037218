import { logoutUser } from '@app/services/UserService';
import { SHOW_CANCEL_AUTOSUB_POPUP, SHOW_SERTIFICATE_POPUP, SHOW_DELETE_ACCOUNT_POPUP } from '@app/store/constants/actionTypes';
import { COMPONENT_USERMENU_OPEN_FEEDBACK } from './types';

export const logout = () => () => {
  logoutUser();
};

export const cancelAutosubscription = () => (dispatch) => {
  dispatch({ type: SHOW_CANCEL_AUTOSUB_POPUP, show: true });
};

export const activateSertificate = () => (dispatch) => {
  dispatch({ type: SHOW_SERTIFICATE_POPUP, show: true });
};
export const showDeleteAccount = () => (dispatch) => {
  dispatch({ type: SHOW_DELETE_ACCOUNT_POPUP, show: true });
};

export const showFeedback = (show = false) => (dispatch) => {
  dispatch({ type: COMPONENT_USERMENU_OPEN_FEEDBACK, show });
};
