// eslint-disable-next-line import/prefer-default-export
export const allTagsCollector = (topics) => {
  const tags = [];
  topics.map(item => tags.push(...item.tags));

  // take unique tags by tag title...
  const tagsTitles = tags.map(item => item.title);
  const uniqueTags = tags.filter((item, index) => index === tagsTitles.indexOf(item.title));

  return uniqueTags;
};
