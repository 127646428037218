import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@app/components/NEW_ui/Button';
import Icon from '@app/components/NEW_ui/Icon';
import PaymentStatusCard from '@app/components/NEW_ui/PaymentStatusCard';
import useOutsideClick from '@helpers/hooks/useOutsideClick';

import {
  closeAutosubPopup,
  handleCancelAutosub,
} from './redux/actions';
import './style.scss';

const CancelAutosubPopup = ({ closePopup, cancelAutosub, cancelStatus }) => {
  const { t } = useTranslation();
  const ref = useRef();
  useOutsideClick(ref, closePopup);

  return (
    <div className="pst-canselAutosubPopup">
      <div className="pst-canselAutosubPopup__inner" ref={ref}>
        <PaymentStatusCard title={t('canselAutosub.title')} description={t('canselAutosub.description')}>
          <div className="pst-canselAutosubPopup__cardChilds">
            <Button
              template="select-small"
              classNames="pst-canselAutosubPopup__leave"
              active
              onClick={closePopup}
            >
              {t('canselAutosub.leave')}
            </Button>
            <Button
              template="withoutBackground"
              classNames="pst-canselAutosubPopup__cancel"
              onClick={cancelAutosub}
            >
              {t('canselAutosub.cancel')}
            </Button>
          </div>
        </PaymentStatusCard>

        {cancelStatus !== null && (<div className="pst-canselAutosubPopup__cancellationStatus">
          <PaymentStatusCard
            isSuccess={cancelStatus === 'success'}
            title={t(`canselAutosub.${cancelStatus}.title`)}
            description={t(`canselAutosub.${cancelStatus}.description`)}
          >
            <div className={`pst-canselAutosubPopup__statusChild pst-canselAutosubPopup__statusChild--${cancelStatus}`}>
              {cancelStatus === 'success' && <div className="pst-canselAutosubPopup__successIcon">
                <Icon name="Done" size="sm" />
              </div>}

              {cancelStatus === 'error' && <div className="pst-canselAutosubPopup__errorIcon">
                <Icon name="Close" size="sm" />
              </div>}
            </div>
          </PaymentStatusCard>
        </div>)}
      </div>
    </div>
  );
};

const mapStateToProps = ({ subscriptions }) => ({ cancelStatus: subscriptions.autosubCancellationStatus });
const mapDispatchToProps = dispatch => bindActionCreators({ closePopup: closeAutosubPopup, cancelAutosub: handleCancelAutosub }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CancelAutosubPopup);
