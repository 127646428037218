import { useState, useEffect } from 'react';

const useMouseMove = (timeout = 3000) => {
  const isClient = typeof window === 'object';
  const [move, setMove] = useState(true);
  let timerId;
  let secondTimerId;

  useEffect(() => {
    if (!isClient) return false;

    secondTimerId = setTimeout(() => {
      setMove(false);
    }, timeout);

    function handleMouseMove() {
      clearTimeout(timerId);
      setMove(true);
      timerId = setTimeout(() => {
        setMove(false);
      }, timeout);
    }

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(timerId);
      clearTimeout(secondTimerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return move;
};

export default useMouseMove;
