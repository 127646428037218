import React from 'react';

export default () => (
  <svg
    height="24"
    width="24"
    className="icon icon-download"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.977 13.307C11.7292 13.3073 11.4914 13.2091 11.3159 13.0342C11.1404 12.8593 11.0416 12.6218 11.041 12.374V0.934001C11.041 0.685891 11.1396 0.447943 11.315 0.272502C11.4905 0.0970622 11.7284 -0.00149918 11.9765 -0.00149918C12.2246 -0.00149918 12.4626 0.0970622 12.638 0.272502C12.8135 0.447943 12.912 0.685891 12.912 0.934001V12.374C12.9115 12.6216 12.8128 12.8589 12.6375 13.0339C12.4622 13.2088 12.2247 13.307 11.977 13.307V13.307ZM9.94003 17.151L4.85403 12.001C4.76796 11.9137 4.69995 11.8103 4.65387 11.6967C4.60779 11.5831 4.58455 11.4615 4.58548 11.3389C4.5864 11.2163 4.61148 11.0951 4.65928 10.9822C4.70707 10.8694 4.77665 10.767 4.86403 10.681C5.04098 10.5072 5.2796 10.4107 5.52759 10.4126C5.77558 10.4145 6.01272 10.5146 6.18703 10.691L11.27 15.837C11.3567 15.9236 11.4596 15.9922 11.5729 16.0388C11.6862 16.0855 11.8075 16.1094 11.93 16.109C12.0528 16.1092 12.1745 16.0851 12.2879 16.0381C12.4014 15.9911 12.5044 15.9221 12.591 15.835L17.772 10.685C17.948 10.5099 18.1862 10.4117 18.4344 10.4121C18.6827 10.4125 18.9206 10.5114 19.096 10.687C19.1825 10.7738 19.2511 10.8769 19.2978 10.9902C19.3445 11.1036 19.3683 11.225 19.3681 11.3476C19.3678 11.4702 19.3434 11.5915 19.2962 11.7047C19.249 11.8178 19.1799 11.9206 19.093 12.007L13.913 17.157C13.6525 17.4171 13.3433 17.6232 13.003 17.7636C12.6627 17.9039 12.2981 17.9758 11.93 17.975C11.5616 17.9757 11.1966 17.9037 10.856 17.7632C10.5153 17.6227 10.2058 17.4163 9.94503 17.156L9.94003 17.151Z"
      fill="black"
    />
    <path
      d="M23.018 19.237C22.8952 19.2369 22.7736 19.2609 22.6601 19.3078C22.5466 19.3547 22.4435 19.4234 22.3566 19.5101C22.2697 19.5969 22.2007 19.6999 22.1536 19.8133C22.1065 19.9267 22.0822 20.0482 22.082 20.171C22.082 21.201 21.242 22.039 20.211 22.039H3.74302C3.24688 22.0393 2.77093 21.8426 2.41973 21.4921C2.06853 21.1417 1.87082 20.6661 1.87002 20.17C1.87002 19.9219 1.77146 19.6839 1.59602 19.5085C1.42058 19.3331 1.18263 19.2345 0.934523 19.2345C0.686413 19.2345 0.448465 19.3331 0.273025 19.5085C0.0975848 19.6839 -0.000976559 19.9219 -0.000976562 20.17C0.000877687 21.1615 0.396047 22.1117 1.09779 22.8122C1.79954 23.5126 2.75053 23.906 3.74202 23.906H20.21C21.2013 23.9057 22.1521 23.5122 22.8536 22.8118C23.5551 22.1114 23.9502 21.1613 23.952 20.17C23.9518 19.9222 23.8531 19.6846 23.6778 19.5095C23.5025 19.3344 23.2648 19.236 23.017 19.236L23.018 19.237Z"
      fill="#721FD9"
    />
  </svg>
);
