import i18n from '../../i18n';

export default (pathname) => {
  let page;

  switch (true) {
    case /\/topics/.test(pathname):
      page = i18n.t('titles.topics');
      break;
    case /\/my-presentations/.test(pathname):
      page = i18n.t('titles.my-presentations');
      break;
    case /viewer/.test(pathname):
      page = i18n.t('titles.viewer');
      break;
    case /design/.test(pathname):
      page = i18n.t('titles.design');
      break;
    case /auth/.test(pathname):
      page = i18n.t('titles.auth');
      break;
    default:
      page = i18n.t('titles.default');
      break;
  }

  return page;
};
