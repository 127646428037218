import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import CookiesService, { HIDE_FEEDBACK_QUIZ } from '@app/services/CookiesService';

import './style.scss';
import { FEEDBACK_QUIZ } from '../../../store/constants/quizTypes';

const options = [
  '🥰 — Улыбка до ушей',
  '🙂 — Стало чуть лучше',
  '😐 — Осталось таким же',
  '😔 — Стало чуть хуже',
  '😩 — Ухудшилось',
];

// eslint-disable-next-line no-shadow,no-unused-vars
const FeedbackQuiz = ({ user, fixed, onClick, onClose }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = React.useState([]);
  const [comment, setComment] = React.useState('');

  const isSelected = item => !!selected.find(x => x === item);
  const handleSelect = (item) => {
    if (selected.includes(item)) {
      setSelected([]);
    } else {
      setSelected([item]);
    }
  };

  const handleSubmit = () => {
    if (selected.length > 0) {
      const never = new Date();
      never.setDate(never.getDate() + 36500);
      CookiesService.setCookie(HIDE_FEEDBACK_QUIZ, new Date().toISOString(), { domain: 'myskazka.com', expires: never });
      onClick({
        createdAt: new Date().toISOString(),
        quizId: FEEDBACK_QUIZ,
        title: t('feedbackQuiz.title'),
        comment,
        email: user.email,
        loginCount: user.loginCount,
        answers: selected.map((text, position) => ({ text, position: 1 + position })),
      });
      onClose();
    }
  };

  return (
    <div className={`pst-feedbackQuiz ${fixed ? 'pst-feedbackQuiz--fixed' : ''}`}>
      <div className='pst-feedbackQuiz__content'>
        <div className='pst-feedbackQuiz__main'>
          <div className='pst-feedbackQuiz__title'>
            {t('feedbackQuiz.title')}
          </div>
          <div className='pst-feedbackQuiz__description'>
            {t('feedbackQuiz.description')}
          </div>
        </div>
      </div>

      <div className='pst-feedbackQuiz__optionsWrapper'>
        {options.map(item => <div
          className={`pst-feedbackQuiz__optionButton ${isSelected(item) && 'pst-feedbackQuiz__selected'}`}
          onClick={() => handleSelect(item)}>
          {item}
        </div>)}
      </div>
      <div className='pst-feedbackQuiz__textAreaWrapper'>
        <textarea className='pst-feedbackQuiz__textArea' placeholder={t('feedbackQuiz.textArea')} onChange={e => setComment(e.target.value)} />
      </div>

      <div className={'pst-feedbackQuiz__submitButton'} onClick={() => handleSubmit()}>
        {t('feedbackQuiz.submit')}
      </div>
    </div>
  );
};

FeedbackQuiz.defaultProps = {
  onClick: () => {
  },
};

FeedbackQuiz.propTypes = {
  user: PropTypes.object,
  onClick: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  // submitQuiz,
}, dispatch);

export default connect(null, mapDispatchToProps)(FeedbackQuiz);
