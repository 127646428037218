import NotFoundPage from '@app/pages/NotFoundPage';

// Auth Flow
import Auth from '@app/pages/Auth';
import AuthLogin from '@app/pages/Auth/components/AuthLogin';
// import AuthSignup from '@app/pages/Auth/components/AuthSignup';
import AuthSendTokenByEmail from '@app/pages/Auth/components/AuthSendTokenByEmail';
import AuthRestore from '@app/pages/Auth/components/AuthRestore';
import UseApp from '@app/pages/Auth/components/UseApp';
import AuthActivateUser from '@app/pages/Auth/components/AuthActivateUser';

// Presentation flow new
import Dashboard from '@app/pages/Dashboard';
import Design from '@app/pages/Design';
import Viewer from '@app/pages/Viewer';

// Public flow
import Public from '@app/pages/Public';
// Presentation flow
import Entrypoint from '@app';

export default [
  {
    component: Entrypoint,
    routes: [
      {
        path: '/auth',
        component: Auth,
        routes: [
          { path: '/auth/login', component: AuthLogin },
          // { path: '/auth/signup', component: AuthSignup },
          { path: '/auth/restore', component: AuthSendTokenByEmail, exact: true },
          { path: '/auth/restore/:token', component: AuthRestore },
          { path: '/auth/activate/:code', component: AuthActivateUser },
          {
            path: '/auth',
            component: Public,
            routes: [
              { path: '/auth/use-app', component: UseApp, exact: true },
            ],
          },
        ],
      },
      { path: '/:page?', component: Dashboard, exact: true },
      {
        path: '/public',
        component: Public,
        routes: [
          { path: '/public/:pastyllaId/viewer', component: Viewer, exact: true },
        ],
      },
      {
        path: '/:pastyllaId',
        component: Entrypoint,
        routes: [
          { path: '/:pastyllaId/design', component: Design, exact: true },
          { path: '/:pastyllaId/viewer', component: Viewer, exact: true },
        ],
      },
      {
        path: '*',
        component: NotFoundPage,
        exact: true,
      },
    ],
  },
];
