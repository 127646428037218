import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import isIos from '@helpers/isIos';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import checkActiveOrder from '@helpers/checkActiveOrder';
import checkActiveSubscription from '@helpers/checkActiveSubscription';
import { getFormattedDate } from '@helpers/dateFormatter';
import isRussianLanguage from '@helpers/isRussianLanguage';
import { showLockScreen } from '@app/pages/Viewer/redux/actions';

import Text from '../Text';
import { logout, cancelAutosubscription, activateSertificate, showFeedback, showDeleteAccount } from './redux/actions';

import './style.scss';
// фикс кодировки
// eslint-disable-next-line no-shadow
const UserMenu = ({ logout, classNames, userEmail, order, history, full = true, cancelAutosubscription, showDeleteAccount, handleClose, activateSertificate, showLockScreen /* , showFeedback , feedbackShown */ }) => {
  const { t } = useTranslation();
  const className = `pst-userMenu ${classNames}`;
  const showSubTillDate = order !== null && checkActiveOrder(order);
  const showCancelAutosubBtn = checkActiveSubscription(order);
  const isCouponValidTill = order !== null && !order.free && Object.prototype.hasOwnProperty.call(order, 'couponValidTill');
  const isFreePeriod = order !== null && order.free;
  const showPaymentBtn = isRussianLanguage() && (order === null || !checkActiveOrder(order) || isFreePeriod);

  const logoutUser = () => {
    logout();
    handleClose();
  };

  const cancelAutosub = () => {
    cancelAutosubscription();
    handleClose();
  };

  const handleActivateSertificate = () => {
    activateSertificate();
    handleClose();
  };

  const handleMakePayment = () => {
    showLockScreen();
    handleClose();
  };

  const handleShowDeleteAccountPopup = () => {
    showDeleteAccount();
    handleClose();
  };

  // const handleShowFeedback = () => {
  //   showFeedback(true);
  //   handleClose();
  // };

  const goTopics = () => {
    history.push('/topics');
    handleClose();
  };

  const goPresentations = () => {
    history.push('/my-presentations');
    handleClose();
  };

  return (
    <div className={className}>
      <div className="pst-userMenu__email">
        <Text inline level="5">
          {userEmail}
        </Text>
      </div>

      {isIos(window) && <div className="pst-userMenu__exit" onClick={handleShowDeleteAccountPopup}>
        <Text inline className="pst-userMenu__exit" weight="800" level="5">
          {t('userMenu.deleteAccount')}
        </Text>
      </div>}

      {showSubTillDate && <div className="pst-userMenu__subscriptionDate">
        <Text inline level="5" template='grey'>
          {t(`userMenu.${isFreePeriod ? 'freePeriod' : 'activeSub'}`, {
            date: getFormattedDate(new Date(order.validTill), { withShortYear: true }),
          })}
        </Text>
      </div>}

      {full && showPaymentBtn && <div
        className="pst-userMenu__exit"
        onClick={handleMakePayment}
      >
        <Text inline className="pst-userMenu__exitText" weight="800" level="5">
          {t('userMenu.payment')}
        </Text>
      </div>}

      {full && !isCouponValidTill && <div
        className="pst-userMenu__exit"
        onClick={handleActivateSertificate}
      >
        <Text inline className="pst-userMenu__exitText" weight="800" level="5">
          {t('userMenu.sertificate')}
        </Text>
      </div>}

      {/* {full && !feedbackShown && <div className="pst-userMenu__exit" onClick={handleShowFeedback}>
        <Text inline className="pst-userMenu__exitText" weight="800" level="5">
          {t('userMenu.feedback')}
        </Text>
      </div>} */}

      {full && showCancelAutosubBtn && <div
        className="pst-userMenu__exit"
        onClick={cancelAutosub}
      >
        <Text inline className="pst-userMenu__exitText" weight="800" level="5">
          {t('userMenu.subscription')}
        </Text>
      </div>}

      {full && <div
        className="pst-userMenu__exit"
        onClick={goTopics}
      >
        <Text inline className="pst-userMenu__exitText" weight="800" level="5">
          {t('userMenu.new')}
        </Text>
      </div>}

      {full && <div
        className="pst-userMenu__exit"
        onClick={goPresentations}
      >
        <Text inline className="pst-userMenu__exitText" weight="800" level="5">
          {t('userMenu.my')}
        </Text>
      </div>}

      <div className="pst-userMenu__exit" onClick={logoutUser}>
        <Text inline className="pst-userMenu__exitText" weight="800" level="5">
          {t('userMenu.logOut')}
        </Text>
      </div>
    </div>
  );
};

UserMenu.defaultProps = {
  logout: () => { },
  classNames: '',
  userEmail: 'you-mail@domain.ru',
};

UserMenu.propTypes = {
  logout: PropTypes.func,
  showLockScreen: PropTypes.func,
  classNames: PropTypes.string,
  userEmail: PropTypes.string,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  userEmail: state.auth.user.email,
  order: state.subscriptions.order,
  feedbackShown: state.dashboard.showFeedback,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  cancelAutosubscription,
  activateSertificate,
  showFeedback,
  showLockScreen,
  showDeleteAccount,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserMenu),
);
