import { SHOW_SERTIFICATE_POPUP, SERTIFICATE_STATUS, SHOW_DELETE_ACCOUNT_POPUP, DELETE_ACCOUNT_STATUS } from '../constants/actionTypes';

const initialState = {
  showCertificatePopup: false,
  showDeleteAccountPopup: false,
  status: 'inactive',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SERTIFICATE_POPUP:
      return { ...state, showCertificatePopup: action.show };
    case SHOW_DELETE_ACCOUNT_POPUP:
      return { ...state, showDeleteAccountPopup: action.show };
    case SERTIFICATE_STATUS:
    case DELETE_ACCOUNT_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
};
