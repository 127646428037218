import React from 'react';

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    fill='none'
    viewBox='0 0 20 20'
  >
    <path
      fill='#000'
      d='M1.466 14.67l2.8-8A1 1 0 015.209 6h.114a1 1 0 01.928.629l3.2 8A1 1 0 018.523 16H2.409a1 1 0 01-.943-1.33zM18.534 14.67l-2.8-8A1 1 0 0014.79 6h-.113a1 1 0 00-.928.629l-3.2 8A1 1 0 0011.477 16h6.113a1 1 0 00.944-1.33z'
    ></path>
    <ellipse cx='10' cy='10' fill='#FAE5C8' rx='5.5' ry='7'></ellipse>
    <path
      fill='#000'
      d='M10 3a6 6 0 00-6 6s2.4-2.143 6-2.143S16 9 16 9a6 6 0 00-6-6z'
    ></path>
    <circle cx='5' cy='6' r='2' fill='#000'></circle>
    <circle cx='15' cy='6' r='2' fill='#000'></circle>
  </svg>
);
