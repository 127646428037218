import { HIDE_QUIZ, SHOW_QUIZ } from '../constants/actionTypes';

const initialState = {
  quiz: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_QUIZ:
      return { ...state, quiz: action.quiz };
    case HIDE_QUIZ:
      return { ...state, quiz: undefined };
    default:
      return state;
  }
};
