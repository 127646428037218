import React from 'react';

export default ({ template }) => {
  if (template === 'color') {
    return (
      <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd" clipRule="evenodd"
          d="M21.1772 1.02307C21.3256 0.528626 21.1772 0.165207 20.4714 0.165207H18.1375C17.544 0.165207 17.2706 0.479113 17.1222 0.825361C17.1222 0.825361 15.9353 3.7182 14.2539 5.59731C13.71 6.14127 13.4627 6.31414 13.166 6.31414C13.0176 6.31414 12.8029 6.14127 12.8029 5.64682V1.02307C12.8029 0.429603 12.6307 0.165207 12.1361 0.165207H8.46856C8.09781 0.165207 7.87476 0.440605 7.87476 0.701666C7.87476 1.2643 8.71528 1.39399 8.80197 2.97636V6.41333C8.80197 7.16684 8.66594 7.30354 8.3692 7.30354C7.57802 7.30354 5.65357 4.39753 4.51197 1.07259C4.28825 0.426268 4.06387 0.165207 3.46739 0.165207H1.13351C0.466693 0.165207 0.333328 0.479113 0.333328 0.825361C0.333328 1.44351 1.12468 4.50956 4.01752 8.5645C5.9463 11.3335 8.66327 12.8348 11.1358 12.8348C12.6195 12.8348 12.8029 12.5014 12.8029 11.9271V9.83413C12.8029 9.16731 12.9434 9.03428 13.413 9.03428C13.7595 9.03428 14.3528 9.20732 15.7376 10.5425C17.32 12.1247 17.5807 12.8348 18.4709 12.8348H20.8048C21.4716 12.8348 21.805 12.5014 21.6126 11.8434C21.4022 11.1876 20.6467 10.2364 19.6442 9.10846C19.1002 8.46548 18.2842 7.77315 18.0371 7.42707C17.6909 6.98197 17.7897 6.78425 18.0371 6.38883C18.0371 6.38883 20.8805 2.38306 21.1772 1.02307Z"
          fill="#5181B8"/>
      </svg>
    );
  }

  return (
    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd" clipRule="evenodd"
        d="M31.2658 1.5346C31.4884 0.792931 31.2658 0.247803 30.2071 0.247803H26.7062C25.816 0.247803 25.4059 0.718662 25.1834 1.23803C25.1834 1.23803 23.403 5.5773 20.8809 8.39596C20.0649 9.21189 19.6941 9.47121 19.249 9.47121C19.0264 9.47121 18.7044 9.2119 18.7044 8.47022V1.5346C18.7044 0.644396 18.4461 0.247803 17.7041 0.247803H12.2028C11.6467 0.247803 11.3121 0.6609 11.3121 1.05249C11.3121 1.89644 12.5729 2.09098 12.703 4.46454V9.61999C12.703 10.7503 12.4989 10.9553 12.0538 10.9553C10.867 10.9553 7.98036 6.59628 6.26796 1.60887C5.93238 0.639394 5.59581 0.247803 4.7011 0.247803H1.20028C0.200047 0.247803 0 0.718662 0 1.23803C0 2.16525 1.18703 6.76433 5.52629 12.8467C8.41947 17.0002 12.4949 19.2522 16.2038 19.2522C18.4293 19.2522 18.7044 18.7521 18.7044 17.8907V14.7512C18.7044 13.751 18.9152 13.5514 19.6196 13.5514C20.1392 13.5514 21.0292 13.811 23.1064 15.8137C25.4799 18.187 25.871 19.2522 27.2064 19.2522H30.7072C31.7074 19.2522 32.2075 18.7521 31.919 17.7651C31.6034 16.7814 30.4701 15.3546 28.9663 13.6627C28.1503 12.6982 26.9263 11.6597 26.5557 11.1406C26.0363 10.4729 26.1846 10.1764 26.5557 9.58323C26.5557 9.58323 30.8207 3.57483 31.2658 1.53485V1.5346Z"
        fill="white"/>
    </svg>
  );
};
