/* eslint-disable security/detect-object-injection */
import React from 'react';
import PropTypes from 'prop-types';
import getClassName from '../../../helpers/getClassName';

import Pack from './Pack';
import './styles.scss';

const Icon = ({ name, template, direction, size, type, disabled, alternative, className }) => {
  const Component = Pack[name];
  const classes = getClassName(className);
  const modificators = [name, template, direction, size, type, alternative].filter(Boolean).map(m => `pst-icon--${m}`).join(' ');

  if (Component) {
    return (
      <i className={`pst-icon pst-icon__${name} ${modificators} ${classes} ${disabled ? 'pst-icon--disabled' : ''}`}>
        <Component disabled={disabled} alternative={alternative} template={template}/>
      </i>
    );
  }

  return (
    <i className={`pst-icon pst-icon__${name} ${modificators} ${classes}`} />
  );
};

Icon.propTypes = {
  template: PropTypes.string,
  direction: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  alternative: PropTypes.bool,
};

Icon.defaultProps = {
  template: 'default',
  direction: '',
  size: 'md',
  disabled: false,
  alternative: false,
};

export default Icon;
