import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import getQueryParam from '@app/helpers/parseQueryUrl';
import setLanguage from '@app/helpers/setLanguage';
import { checkEmail, notNullString } from '@helpers/validationRules';
import { ValidationInput } from '../../../components/NEW_ui/Input';
import AuthFormContainer from './AuthFormContainer';
import AuthSocialBlock from './AuthSocialBlock';
import { restore } from '../redux/actions';

class AuthSendTokenByEmail extends Component {
  static propTypes = {
    restore: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      hideSocials: false,
    };
  }

  componentDidMount() {
    const hideSocials = getQueryParam('showSocials') === 'false';
    this.setState({ hideSocials });
  }

  handleError = (hasError) => {
    this.setState({ disabled: hasError });
  };

  sendTokenByEmail = async (formData) => {
    const { history, t } = this.props;
    const { email } = formData;

    if (email) {
      try {
        await this.props.restore({ email });
        await alert(t('auth.linkSent'));
      } finally {
        history.push('/');
      }
    }
  };

  handleChangeLanguage = (name, value) => {
    const { i18n } = this.props;
    setLanguage(i18n, value);
  }

  renderFields = () => {
    const { t } = this.props;

    const fields = [
      {
        name: 'email',
        type: 'text',
        label: 'E-mail',
        mode: 'normal',
        validators: [notNullString, checkEmail],
        errorMessages: [t('auth.fieldEmpty'), t('auth.invalidEmail')],
      },
    ];

    return fields.map(i => <ValidationInput
      id={i.name}
      key={i.name}
      className='pst-login__field'
      {...i}
    />);
  }

  render() {
    const { t } = this.props;
    const { disabled, hideSocials } = this.state;

    return (
      <AuthFormContainer
        onSubmit={this.sendTokenByEmail}
        onError={this.handleError}
        changeLanguage={this.handleChangeLanguage}
        buttonDisabled={disabled}
        buttonText={t('auth.restoreBtn')}
        showPasswordNotice
      >
        {!hideSocials && <AuthSocialBlock/>}
        {this.renderFields()}
        <div className='pst-login__form-info'>{t('auth.restoreLink')}</div>
      </AuthFormContainer>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ restore }, dispatch);
export default withTranslation()(connect(null, mapDispatchToProps)(AuthSendTokenByEmail));
