import JSZip from 'jszip';

export default ({ template, topic, content }) => {
  const archive = new JSZip();

  const meta = {
    title: topic.title,
    description: topic.description,
    version: '0.0.1',
    topicId: topic._id,
    language: topic.language,
    author: 'Test User <test@pastila.io>',
    generator: 'Myskazka',
    created_at: Date.now(),
    updated_at: Date.now(),
  };

  topic.structure.map((slide) => {
    if (!slide.designId) {
      const _tpl = template.structure.find(_s => _s._type === slide._type);
      slide.designId = _tpl._id;
    }
    return slide;
  });

  archive.file('topic.json', JSON.stringify(topic));
  archive.file('content.json', JSON.stringify(content));
  archive.file('meta.json', JSON.stringify(meta));
  archive.folder('images');

  archive.file('template.json', JSON.stringify({
    title: template.title,
    updated_at: template.updated_at,
    structure: template.structure.map(slide => ({
      _id: slide._id,
      _type: slide._type,
      contains: slide.contains,
      designId: slide.designId,
    })),
  }));

  const templateFolder = archive.folder('templates');

  template.structure.map(slide => templateFolder.file(`${slide._id}.html`, slide.html));

  return archive.generateAsync({ type: 'blob' });
};
