import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Text from '@app/components/NEW_ui/Text';
import Icon from '@app/components/NEW_ui/Icon';

import './style.scss';

class CardPresentation extends Component {
  constructor() {
    super();

    this.state = {
      confirmation: false,
    };
  }

  handleDeletePastylla = (e) => {
    e.preventDefault();
    const { deleteMethod, _id } = this.props;
    deleteMethod(_id);
  }

  openConfirmation = (e) => {
    e.preventDefault();
    this.setState({ confirmation: true });
  }

  closeConfirmation = (e) => {
    e.preventDefault();
    this.setState({ confirmation: false });
  }

  render() {
    const { timeChange, title, classNames, onClick, _id, t } = this.props;
    const { confirmation } = this.state;
    const className = `pst-card-presentation ${classNames}`;
    const date = new Date(timeChange).toLocaleDateString();
    const time = new Date(timeChange).toLocaleTimeString();

    return (
      <div
        className={className}
        onClick={onClick}
      >
        <div className='pst-card-presentation__date-of-change'>
          <Text level="4" weight={400} inline>{`${t('cardPresentation.changed', { date })} ${t('cardPresentation.at', { time })}`}</Text>
        </div>

        <div className='pst-card-presentation__title'>
          <Text level="4" template="default" weight={800} inline>{title}</Text>
        </div>

        {confirmation && <div className='pst-card-presentation__confirmation-wrap'>
          <button
            className='pst-card-presentation__confirmation-delete pst-card-presentation__confirmation-actionBtn'
            onClick={this.handleDeletePastylla}
          >{t('cardPresentation.delete')}</button>
          <button
            className='pst-card-presentation__confirmation-cancel pst-card-presentation__confirmation-actionBtn'
            onClick={this.closeConfirmation}
          >{t('cardPresentation.cancel')}</button>
        </div>}

        {!confirmation && <div className='pst-card-presentation__icons-wrap'>
          <Link to={`/${_id}/viewer`}>
            <div className='pst-card-presentation__icon'>
              <Icon name='Play' size='sm' className='pst-card-presentation__iconSvg' />
              <div className='pst-card-presentation__iconBackground'></div>
            </div>
          </Link>

          <Link to={`/${_id}/design`}>
            <div className='pst-card-presentation__icon'>
              <Icon name='Edit' size='sm' alternative className='pst-card-presentation__iconSvg' />
              <div className='pst-card-presentation__iconBackground'></div>
            </div>
          </Link>

          <div className='pst-card-presentation__icon' onClick={this.openConfirmation}>
            <Icon name='Trash' size='sm' className='pst-card-presentation__iconSvg' />
            <div className='pst-card-presentation__iconBackground'></div>
          </div>
        </div>}
      </div>
    );
  }
}

CardPresentation.defaultProps = {
  onClick: () => { },
  classNames: '',
  title: 'TED. Особенности проектирования мобильных интерфейсов.',
  timeChange: new Date(),
  isBuy: false,
};

CardPresentation.propTypes = {
  onClick: PropTypes.func,
  classNames: PropTypes.string,
  title: PropTypes.string,
  timeChange: PropTypes.number,
};

export default withTranslation()(CardPresentation);
