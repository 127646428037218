import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Text from '@app/components/NEW_ui/Text';
import Button from '@app/components/NEW_ui/Button';
import Icon from '@app/components/NEW_ui/Icon';

import useOutsideClick from '@helpers/hooks/useOutsideClick';
import closeSertificatePopup from '../SertificatePopup/redux/actions';
import { handleActivateSertificate, handleChangeStatus } from './redux/actions';

import './style.scss';

const SertificateCard = ({
  closePopup,
  activateSertificate,
  status,
  changeStatus,
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const [inputSertificate, setInputSertificate] = useState('');

  const inputChangeHandler = (e) => {
    setInputSertificate(e.target.value);

    if (status === 'error') {
      changeStatus('inactive');
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    activateSertificate(inputSertificate);
  };

  const handleClosePopup = () => {
    closePopup();
    if (status === 'success') {
      window.location.reload();
    }
  };
  useOutsideClick(ref, handleClosePopup);

  return (
    <div className="pst-sertificateCard" ref={ref}>
      <button
        className="pst-sertificateCard__closeButton"
        onClick={handleClosePopup}
      >
        <Icon name="Close" size="sm" alternative template="gray" />
      </button>

      <div className="pst-sertificateCard__hanger">
        <div className="pst-sertificateCard__subtract"></div>
      </div>

      <div className="pst-sertificateCard__content">
        <div className="pst-sertificateCard__title">
          <Text level="2" weight={800} inline>
            {t('sertificate.title')}
          </Text>
        </div>

        <form className="pst-sertificateCard__form">
          {(status === 'inactive' || status === 'error') && (
            <>
              <input
                className="pst-sertificateCard__input"
                placeholder={t('sertificate.inputPlaceholder')}
                onChange={inputChangeHandler}
                value={inputSertificate}
              />

              {status === 'error' && (
                <p className="pst-sertificateCard__error">
                  {t('sertificate.error')}
                </p>
              )}

              <Button
                template="select-small"
                classNames="pst-sertificateCard__activate"
                active
                onClick={submitHandler}
              >
                {t('sertificate.activate')}
              </Button>
            </>
          )}

          {status === 'success' && (
            <>
              <div className="pst-sertificateCard__message">
                <Text level="6" template="grey" inline>
                  {t('sertificate.description')}
                </Text>
              </div>

              <Button
                template="select-small"
                classNames="pst-sertificateCard__activate"
                active
                onClick={handleClosePopup}
              >
                {t('sertificate.goService')}
              </Button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ sertificate }) => ({
  status: sertificate.status,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    closePopup: closeSertificatePopup,
    activateSertificate: handleActivateSertificate,
    changeStatus: handleChangeStatus,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SertificateCard);
