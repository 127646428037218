/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import renderSlides from '@app/helpers/renderSlides';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';

export default function usePresentation(
  getTopic,
  getPresentation,
  getPresentationCharge,
  eraseData,
  presentation,
  chargeData,
) {
  const { pastyllaId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const [pastylla] = await Promise.all([
          getPresentation(pastyllaId),
          getPresentationCharge(),
        ]);
        const {
          topic: { title },
        } = pastylla;

        MixpanelService.track(`${mixpanelMap.VIEWING_STORY} ${title}`, {
          storyTitle: title,
        });
        MixpanelService.track(`${mixpanelMap.VIEWER_OPENING}`, {
          storyTitle: title,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();
    return () => eraseData();
  }, [pastyllaId]);

  const topic = presentation && presentation.topic;
  const answers = presentation && presentation.content;
  const slides = presentation && renderSlides(presentation);
  const slidesTotal = presentation && slides.length;
  const chargeOptions = chargeData && chargeData;

  return [topic, answers, pastyllaId, slides, slidesTotal, chargeOptions];
}
