import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextareaAutosize from 'react-textarea-autosize';

import getClassName from '@app/helpers/getClassName';
import withClassname from '../../../helpers/hocs/withClassname';

import './style.scss';
import SymbolCounter from '../SymbolCounter';

const Textarea = ({
  onEnter,
  onChange,
  defaultValue,
  onKeyPress,
  placeholder,
  className,
  onFocus,
  onBlur,
  autoFocus,
  max,
  counter,
  value = '',
  label,
  name,
  withAutoSize,
}) => {
  const [isFocus, setFocus] = useState(false);
  const hasLabel = !!label;

  const EL = withAutoSize ? TextareaAutosize : 'textarea';

  const handleFocus = (e) => {
    setFocus(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e) => {
    setFocus(false);
    if (onBlur) onBlur(e);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      onEnter();
    }
    if (onKeyPress) onKeyPress();
  };

  const renderLabel = () => {
    const classFocus = isFocus ? 'is-focus' : '';
    const classFilled = value.trim() ? 'is-filled' : '';
    const classNames = getClassName(['pst-input__label', classFocus, classFilled]);

    return (
      <div className={classNames}>
        <label className='pst-input__label-inner' htmlFor={name}>{label}</label>
      </div>
    );
  };

  return (
    <div className={`pst-textarea ${hasLabel ? 'pst-textarea--withLable' : ''}`}>
      {hasLabel && renderLabel()}
      <EL
        name={name}
        id={name}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        onKeyPress={handleKeyPress}
        className={`pst-textarea__field ${className}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value.trim() ? value : ''}
      />
      {counter && max && <SymbolCounter count={(value.trim() ? value : '').length} max={max} />}
    </div>
  );
};

Textarea.propTypes = {
  onChange: PropTypes.func,
  withAutoSize: PropTypes.bool,
};

Textarea.defaultProps = {
  withAutoSize: false,
  onChange: () => { },
};

export default withClassname(Textarea);
