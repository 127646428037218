import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M5 16.5L5 20.0445C5 21.5822 6.66293 22.5447 7.99626 21.7787L11.0313 20.0352" stroke="#FA3E5F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 12L5 3.95705C5 2.41902 6.66361 1.45658 7.99694 2.22324L21.9847 10.2662C23.3221 11.0352 23.3221 12.9648 21.9847 13.7338L15 17.75" stroke="#7421D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
