import {
  checkSertificate,
  activateSertificate,
} from '@app/services/SertificateService';
import { SERTIFICATE_STATUS } from '@app/store/constants/actionTypes';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';

export const handleChangeStatus = status => (dispatch) => {
  dispatch({ type: SERTIFICATE_STATUS, status });
};

export const handleActivateSertificate = code => async (dispatch) => {
  try {
    const sertificateData = await checkSertificate(code);
    if (sertificateData.coupon.active) {
      await activateSertificate(code);
      dispatch({ type: SERTIFICATE_STATUS, status: 'success' });

      MixpanelService.track(`${mixpanelMap.CERTIFICATE_ACTIVATION}`);
    } else {
      dispatch({ type: SERTIFICATE_STATUS, status: 'error' });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    dispatch({ type: SERTIFICATE_STATUS, status: 'error' });
  }
};
