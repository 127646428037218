import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import getQueryParam from '@app/helpers/parseQueryUrl';
import setLanguage from '@app/helpers/setLanguage';
import { checkEmail, notNullString, tooShortPass, passHasUppercase, passHasNumber } from '@helpers/validationRules';
import { ValidationInput } from '../../../components/NEW_ui/Input';
import AuthFormContainer from './AuthFormContainer';
import AuthSocialBlock from './AuthSocialBlock';
import { login, signup } from '../redux/actions';
import RegisterQuestionPopup from './RegisterQuestionPopup';
import EmailConfirmationPopup from './EmailConfirmationPopup';

class AuthLogin extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    signup: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      serverErrors: {},
      showRegisterQuestionPopup: false,
      showConfirmationPopup: false,
      email: '',
      hideSocials: false,
    };
  }

  componentDidMount() {
    const hideSocials = getQueryParam('showSocials') === 'false';
    this.setState({ hideSocials });
  }

  handleError = (hasError) => {
    this.setState({
      disabled: hasError,
      serverErrors: {},
    });
  };

  handleSubmit = async (formData) => {
    if (typeof formData === 'object' && formData.email) {
      formData.email = formData.email.trim();
    }

    const { showRegisterQuestionPopup } = this.state;
    const { t, i18n } = this.props;

    try {
      if (showRegisterQuestionPopup) {
        const updFormData = { ...formData, lang: i18n.language };
        await this.props.signup(updFormData);
      } else {
        await this.props.login(formData);
      }
    } catch (e) {
      if (e.isAxiosError && e.response.status === 400) {
        this.setState({
          serverErrors: {
            email: ' ',
            password: t('auth.invalidData'),
          },
        });
      } else if (e.isAxiosError && e.response.status === 403) {
        this.setState({
          serverErrors: {
            email: '',
            password: t('auth.confirmUser'),
          },
        });
      } else if (e.isAxiosError && e.response.status === 422) {
        this.setState({
          serverErrors: {
            email: t('auth.existingEmail'),
            password: '',
          },
        });
      } else if (e.isAxiosError && e.response.status === 404) {
        this.setState({
          showRegisterQuestionPopup: true,
          email: formData.email,
          serverErrors: {
            email: t('auth.emailNotRegistered'),
            password: '',
          },
        });
      }
    }
  };

  handleChangeLanguage = (name, value) => {
    const { i18n } = this.props;
    setLanguage(i18n, value);
  }

  getServerError(fieldName) {
    if (Object.prototype.hasOwnProperty.call(this.state.serverErrors, fieldName)) {
      return this.state.serverErrors[fieldName];
    }
    return false;
  }

  handleInputFocus = () => {
    window.scrollTo(0, document.documentElement.clientHeight);
  }

  handleCloseQuestion = (e) => {
    e.preventDefault();

    this.setState({
      showRegisterQuestionPopup: false,
    });
  }

  handleConfirmation = (e) => {
    e.preventDefault();

    this.setState({
      showConfirmationPopup: false,
    });
  }

  ignoreEnter = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  }

  renderFields() {
    const { t } = this.props;

    const inputs = [
      {
        name: 'email',
        type: 'text',
        label: 'E-mail',
        mode: 'normal',
        validators: [notNullString, checkEmail],
        errorMessages: [t('auth.fieldEmpty'), t('auth.invalidEmail')],
        onKeyPress: this.ignoreEnter,
      },
      {
        name: 'password',
        type: 'password',
        mode: 'normal',
        label: t('auth.password'),
        validators: [notNullString, tooShortPass, passHasUppercase, passHasNumber],
        errorMessages: [t('auth.fieldEmpty'), t('auth.tooShortPass'), t('auth.hasUppercase'), t('auth.hasNumber')],
        onKeyPress: this.ignoreEnter,
      },
    ];

    return inputs.map(i => <ValidationInput
      id={i.name}
      key={i.name}
      error={this.getServerError(i.name)}
      className='pst-login__field'
      onFocus={this.handleInputFocus}
      {...i}
    />);
  }

  render() {
    const { t } = this.props;
    const { disabled, showRegisterQuestionPopup, email, showConfirmationPopup, hideSocials } = this.state;

    return (
      <AuthFormContainer
        onSubmit={this.handleSubmit}
        onError={this.handleError}
        changeLanguage={this.handleChangeLanguage}
        buttonDisabled={disabled}
        buttonText={t('auth.loginBtn')}
        showRestore
        showPrivacyConfirm
        showPasswordNotice
      >
        {!hideSocials && <AuthSocialBlock />}

        {this.renderFields()}

        {showRegisterQuestionPopup && (
          <RegisterQuestionPopup
            email={email}
            onClose={this.handleCloseQuestion}
          />
        )}
        {showConfirmationPopup && (
          <EmailConfirmationPopup
            email={email}
            confirmationHandler={this.handleConfirmation}
          />
        )}
      </AuthFormContainer>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    login,
    signup,
  },
  dispatch,
);

export default withTranslation()(connect(null, mapDispatchToProps)(AuthLogin));
