import React from 'react';
import Icon from '@app/components/NEW_ui/Icon';

import './style.scss';

const GoogleBtn = ({ onClick }) => (
  <div
    className={'pst-googleBtn'}
    onClick={onClick}
  >
    <Icon name='GoogleSignin' size="sm" template='color'/>
  </div>
);

export default GoogleBtn;
