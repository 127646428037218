import React from 'react';

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='17'
    fill='none'
    viewBox='0 0 25 17'
  >
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeWidth='2.5'
      d='M2 8.5h21m0 0l-7-7m7 7l-7 7'
    ></path>
  </svg>
);
