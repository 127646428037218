import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@app/components/NEW_ui/Text';
import Icon from '@app/components/NEW_ui/Icon';
import Button from '@app/components/NEW_ui/Button';
import './style.scss';

export default ({ email, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className='pst-registerQuestionPopup'>
      <div className='pst-registerQuestionPopup__container'>
        <button
          className='pst-registerQuestionPopup__closeButton'
          onClick={onClose}
        >
          <Icon
            name='Close'
            size='sm'
            alternative
            template='gray'
          />
        </button>
        <Text className='pst-registerQuestionPopup__title' weight='800' level='3'>{t('registerQuestionPopup.title')}</Text>
        <p className='pst-registerQuestionPopup__text'>
          {t('registerQuestionPopup.subtitleBefore')}<br />
          <strong>{email}</strong><br />
          {t('registerQuestionPopup.subtitleAfter')}
        </p>
        <Button
          classNames='pst-registerQuestionPopup__button'
          template='bigGreen'
          active
        >
          {t('registerQuestionPopup.yes')}
        </Button>
      </div>
    </div>
  );
};
