export default (window) => {
  const isIPhone = /iP(hone|od|ad)/i.test(window.navigator.userAgent);

  if (isIPhone) {
    // eslint-disable-next-line no-control-regex,no-useless-escape,security/detect-unsafe-regex,max-len
    const version = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(version[1], 10), parseInt(version[2], 10), parseInt(version[3] || 0, 10)];
  }

  return null;
};
