import React from 'react';
import PropTypes from 'prop-types';

import ReactImage from 'react-image';
import Icon from '../Icon/index';

import './style.scss';

const RoundButton = ({ onClick, name, size, disabled, classNames, template, iconDirection, background, iconTempl, ...rest }) => {
  const className = `pst-roundButton pst-roundButton--${template} ${classNames} pst-roundButton--${size}`;

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <div className='pst-roundButton__background'>
        {background && (
          <ReactImage
            className='pst-roundButton__background-image'
            src={background}
          />
        )}
      </div>
      <div className='pst-roundButton__icon'>
        <Icon
          name={name}
          alternative={template === 'with-background'}
          template={iconTempl}
          direction={iconDirection}
          disabled={disabled}
          size='sm'
        />
      </div>
    </button>
  );
};

RoundButton.defaultProps = {
  onClick: () => { },
  size: 'md',
  disabled: false,
  classNames: '',
  name: 'play',
  template: 'default',
  iconDirection: 'default',
  background: '',
};

RoundButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
  name: PropTypes.string,
  template: PropTypes.string,
  iconDirection: PropTypes.string,
  background: PropTypes.string,
};

export default RoundButton;
