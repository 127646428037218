import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Button = ({ onClick, classNames, template, children, disabled, active, ...rest }) => {
  const className = `pst-button pst-button--${template} ${active ? 'active' : ''} ${classNames}`;

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children && <div className={`pst-button__text pst-button__text--${disabled ? 'disabled' : ''}`}>{children}</div>}
    </button>
  );
};

Button.defaultProps = {
  onClick: () => { },
  disabled: false,
  classNames: '',
  template: 'select',
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
  template: PropTypes.string,
};

export default Button;
