import React from 'react';

export default () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.29962 21.3121L8.10068 33.7949C8.84336 35.7259 10.6986 37 12.7674 37H24.5586C25.4194 37 26.1837 36.4491 26.4559 35.6325L29.5551 26.3348C29.848 25.4561 29.9404 24.5229 29.8256 23.6038L29.7482 22.985C29.5529 21.4228 27.8159 20.5772 26.4658 21.3872C26.2268 21.5306 25.9165 21.4158 25.8283 21.1514L25.6911 20.7398C25.1654 19.1627 23.3145 18.478 21.889 19.3333C21.6039 19.5044 21.2337 19.3675 21.1285 19.052L21.1112 19C20.541 17.2894 18.5334 16.5467 16.9872 17.4744L16.6667 17.6667L14.9542 6.82052C14.7887 5.77215 13.885 5 12.8237 5C11.6324 5 10.6667 5.96569 10.6667 7.15692V25.6667L7.07047 19.5016C6.48146 18.4919 5.18086 18.1582 4.17848 18.7596C3.30444 19.2841 2.93371 20.3608 3.29962 21.3121Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.6667 7.66667H24M24 7.66667L28.6667 3M24 7.66667L28.6667 12.3333"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

);
