import React from 'react';

export default ({ template }) => {
  let color;

  switch (template) {
    case 'gray':
      color = '#80868B';
      break;
    case 'purple':
      color = '#7421D9';
      break;
    case 'white':
      color = '#ffffff';
      break;
    default:
      color = 'black';
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 20 19'
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M10 4.5v13m0-13c0-2 1.5-3.5 3.5-3.5H19v14h-6.5C11 15 10 16 10 17.5m0-13C10 2.5 8.5 1 6.5 1H1v2.5m9 14C10 16 9 15 7.5 15H1V8'
      ></path>
    </svg>
  );
};
