import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@app/components/NEW_ui/Button';
import Icon from '@app/components/NEW_ui/Icon';
import './style.scss';

const ReadStoryButton = ({ history, slideId }) => {
  const { t } = useTranslation();

  const handleOpenPreview = () => {
    history.push(`/${slideId}/viewer`);
  };

  return (
    <Button
      template='select-large'
      onClick={handleOpenPreview}
      classNames='pst-readStoryButton'
    >
      <Icon name='Play' size='sm' alternative />
      {t('slideEditor.openPreview')}
    </Button>
  );
};

export default withRouter(ReadStoryButton);
