import {
  activateUserByCode,
  checkRestoreToken,
  fetchUser,
  loginUser,
  registerUser,
  restorePassword,
  setNewPassword,
} from '@app/services/UserService';
import { COMPONENT_AUTH_IDENTIFY, SET_ORDER, SHOW_LOCK_SCREEN } from '@app/store/constants/actionTypes';

import { baseURL } from '@app/services/HttpService';
import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';
import { getPrice } from '@app/services/PaymentService';
import isIos from '@helpers/isIos';

import { fetchRoistat } from '@app/services/RoistatService';

export const login = data => async (dispatch) => {
  // eslint-disable-next-line no-unused-vars
  const { user, order } = await loginUser(data);

  MixpanelService.updateUser(user._id);
  MixpanelService.track(mixpanelMap.START_USER_SESSION, {
    userId: user._id,
    userEmail: user.email,
    startDate: new Date().toString(),
  });
  MixpanelService.track(`${mixpanelMap.IDENTIFY_USER} auth-native`, { provider: 'native', action: 'auth' });

  if (order) {
    dispatch({ type: SET_ORDER, order });
  }

  dispatch({ type: COMPONENT_AUTH_IDENTIFY, user });
};

export const signup = data => async (dispatch) => {
  const response = await registerUser(data);
  const price = await getPrice(isIos(window));
  dispatch({ type: SHOW_LOCK_SCREEN, price, show: false });

  const { status, user } = response;
  if (status === 200) {
    dispatch({ type: COMPONENT_AUTH_IDENTIFY, user });
  }
  return response;
};

export const loadUser = () => async (dispatch) => {
  const { user, order } = await fetchUser();
  if (order) dispatch({ type: SET_ORDER, order });
  dispatch({ type: COMPONENT_AUTH_IDENTIFY, user });

  MixpanelService.updateUser(user._id);
  MixpanelService.track(mixpanelMap.START_USER_SESSION, { userId: user._id, userEmail: user.email, startDate: new Date().toString() });
};

export const activateUser = code => async (dispatch) => {
  const { user, order } = await activateUserByCode(code);

  fetchRoistat('registration', {
    userEmail: user.email,
  });

  MixpanelService.setUser(user);
  MixpanelService.track(mixpanelMap.REGISTER_USER, { userId: user._id, userEmail: user.email });
  MixpanelService.track(`${mixpanelMap.IDENTIFY_USER} register-native`, { provider: 'native', action: 'register' });

  window.dataLayer.push({ event: 'entrance' });

  if (order) dispatch({ type: SET_ORDER, order });
  dispatch({ type: COMPONENT_AUTH_IDENTIFY, user });
  return user;
};

export const socialLogin = state => () => {
  let url;
  switch (state) {
    case 'vkontakte':
      url = '/auth/vkontakte';
      break;
    case 'odnoklassniki':
      url = '/auth/odnoklassniki';
      break;
    case 'facebook':
      url = '/auth/facebook';
      break;
    case 'google':
      url = '/auth/google';
      break;
    case 'apple':
      url = '/auth/apple';
      break;

    default:
      throw new Error('Unknown social state');
  }

  // eslint-disable-next-line
  return () => window.location.href = `${baseURL}${url}`;
};

export const checkToken = data => () => checkRestoreToken(data);
export const restore = data => () => restorePassword(data);
export const setNewPass = data => () => setNewPassword(data);
