import React from 'react';

export default () => (
  <svg
    height="24"
    width="24"
    className="icon icon-share"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.969 15.938C19.3765 15.9379 18.7912 16.0686 18.2549 16.3208C17.7187 16.573 17.2448 16.9405 16.867 17.397L13.73 15.837C13.5091 15.7387 13.2588 15.7294 13.0312 15.8112C12.8037 15.893 12.6165 16.0595 12.5088 16.276C12.401 16.4925 12.3811 16.7422 12.453 16.9731C12.525 17.204 12.6834 17.3981 12.895 17.515L16.037 19.078C15.9043 19.6681 15.9058 20.2804 16.0415 20.8698C16.1773 21.4592 16.4437 22.0105 16.8212 22.4831C17.1987 22.9556 17.6776 23.3373 18.2225 23.5999C18.7673 23.8624 19.3642 23.9992 19.969 24C21.0377 23.9987 22.0622 23.5736 22.8179 22.8179C23.5736 22.0622 23.9987 21.0377 24 19.969C23.999 18.9001 23.574 17.8754 22.8183 17.1195C22.0626 16.3636 21.0379 15.9383 19.969 15.937V15.938ZM19.969 22.125C19.614 22.1244 19.2646 22.0363 18.9518 21.8685C18.639 21.7006 18.3724 21.4582 18.1756 21.1628C17.9788 20.8673 17.8579 20.5278 17.8236 20.1745C17.7893 19.8211 17.8427 19.4648 17.979 19.137C18.0273 19.0655 18.0657 18.9878 18.093 18.906C18.2812 18.5743 18.554 18.2984 18.8836 18.1064C19.2131 17.9144 19.5876 17.8132 19.969 17.813C21.158 17.813 22.125 18.78 22.125 19.969C22.1242 20.5406 21.8968 21.0885 21.4927 21.4926C21.0885 21.8968 20.5406 22.1242 19.969 22.125V22.125Z"
      fill="#721FD9"
    />
    <path
      d="M19.969 0C19.363 0.000690958 18.7649 0.137846 18.2191 0.401287C17.6734 0.664728 17.1939 1.04769 16.8164 1.52176C16.4389 1.99582 16.173 2.54882 16.0384 3.13971C15.9038 3.73059 15.904 4.3442 16.039 4.935L7.118 9.409C6.74017 8.95796 6.26803 8.59526 5.73484 8.34646C5.20165 8.09766 4.62038 7.96881 4.032 7.969C2.96315 7.97006 1.93836 8.39506 1.18247 9.15076C0.426579 9.90646 0.00132363 10.9311 0 12C0.00132312 13.0687 0.426441 14.0932 1.18211 14.8489C1.93779 15.6046 2.96232 16.0297 4.031 16.031C4.617 16.0312 5.19598 15.9035 5.72746 15.6566C6.25894 15.4098 6.7301 15.0499 7.108 14.602L8.622 15.372C8.84359 15.4847 9.10088 15.5048 9.33727 15.4278C9.57366 15.3508 9.76978 15.1831 9.8825 14.9615C9.99522 14.7399 10.0153 14.4826 9.93831 14.2462C9.86132 14.0098 9.69359 13.8137 9.472 13.701L7.954 12.929C8.09798 12.3229 8.09935 11.6917 7.958 11.085L16.875 6.613C17.2528 7.06685 17.7258 7.43203 18.2605 7.68261C18.7952 7.9332 19.3785 8.06307 19.969 8.063C21.038 8.06168 22.0629 7.63628 22.8186 6.88018C23.5743 6.12407 23.9992 5.09903 24 4.03C23.9984 2.96149 23.5732 1.93722 22.8175 1.18176C22.0619 0.426304 21.0375 0.00132254 19.969 0V0ZM4.03 14.156C3.45861 14.1549 2.91094 13.9274 2.507 13.5233C2.10306 13.1192 1.87579 12.5714 1.875 12C1.875 10.811 2.842 9.844 4.031 9.844C5.221 9.844 6.188 10.811 6.188 12C6.18721 12.5717 5.95966 13.1198 5.55529 13.524C5.15092 13.9282 4.60274 14.1555 4.031 14.156H4.03ZM19.97 6.187C19.3983 6.18647 18.8501 5.95918 18.4457 5.555C18.0413 5.15081 17.8138 4.60274 17.813 4.031C17.813 2.842 18.781 1.875 19.97 1.875C21.159 1.875 22.126 2.842 22.126 4.031C22.1255 4.60274 21.8982 5.15092 21.494 5.55529C21.0898 5.95966 20.5417 6.18721 19.97 6.188V6.187Z"
      fill="black"
    />
    <defs />
  </svg>
);
