import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import logo from './pochemusha2.jpg';

import './style.scss';

class Why extends Component {
  render() {
    return (
      <div className="pst-why">
        <div className="pst-why__logo">
          <img src={logo} width={81} height={81}/>
        </div>
        <div className="pst-why__title">Почемуша</div>
        <div className="pst-why__text">с заботой <strong>ответит</strong> на любой детский вопрос</div>
        <button className="pst-why__btn" onClick={() => this.props.toggleChat()}>задать вопрос</button>
      </div>
    );
  }
}

Why.propTypes = {
  toggleChat: PropTypes.func,
};

export default withTranslation()(Why);
