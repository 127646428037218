import React from 'react';
import withClassname from '../../../helpers/hocs/withClassname';

import './style.scss';

export default withClassname(({ level, template = 'default', weight = '400', className, children, onClick, inline }) => {
  const params = { className: `pst-typography pst-typography--${template} pst-typography--${weight} ${className} pst-typography--${inline && 'inline'}`, onClick };

  switch (level) {
    case '6':
      return <h6 {...params}>{children}</h6>;
    case '5':
      return <h5 {...params}>{children}</h5>;
    case '4':
      return <h4 {...params}>{children}</h4>;
    case '3':
      return <h3 {...params}>{children}</h3>;
    case '2':
      return <h2 {...params}>{children}</h2>;
    case '1':
    default:
      return <h1 {...params}>{children}</h1>;
  }
});
