import { COMPONENT_USERMENU_OPEN_FEEDBACK } from '@app/components/NEW_ui/UserMenu/redux/types';
import {
  COMPONENT_DASHBOARD_ADDTAG,
  COMPONENT_DASHBOARD_DELETETAG,
  COMPONENT_DASHBOARD_FETCH,
  COMPONENT_MYPRESENTATION_FETCH,
} from '../constants/actionTypes';

const initialState = {
  items: [],
  filters: {
    tags: [],
  },
  myPresentation: [],
  enTopics: [],
  ruTopics: [],
  showFeedback: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPONENT_DASHBOARD_FETCH:
      return {
        ...state,
        items: action.topics.items,
        enTopics: action.topics.enTopics,
        ruTopics: action.topics.ruTopics,
      };
    case COMPONENT_DASHBOARD_ADDTAG:
      return {
        ...state,
        filters: {
          tags: state.filters.tags.concat(action.tag),
        },
      };
    case COMPONENT_DASHBOARD_DELETETAG:
      return {
        ...state,
        filters: {
          ...state.filters,
          tags: state.filters.tags
            .filter(name => name !== action.tag),
        },
      };
    case COMPONENT_MYPRESENTATION_FETCH:
      return {
        ...state,
        myPresentation: action.myPresentation,
      };
    case COMPONENT_USERMENU_OPEN_FEEDBACK:
      return {
        ...state,
        showFeedback: action.show,
      };
    default:
      return state;
  }
};
