import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '../Text';
import decl from './helpers';

import './style.scss';

const SymbolCounter = ({ max, count = max }) => {
  const { t } = useTranslation();

  const err = +count >= +max;
  const displayedNumber = +max - +count;

  const declension = decl(displayedNumber);
  const counterText = displayedNumber === 0
    ? t('symbols.limit')
    : `${displayedNumber} ${t(`symbols.${declension.simbols}`)} ${!err ? `${t(`symbols.${declension.rest}`)}` : ''}`;

  return (
    <div className={'pst-symbolCounter'}>
      <div className={`pst-symbolCounter__separator ${err ? 'pst-symbolCounter__separator--error' : ''}`} />
      <Text level="6" weight="600" template={err ? 'reddish-pink' : 'grey'} className="pst-symbolCounter__counter">
        {counterText}
      </Text>
    </div>
  );
};

SymbolCounter.propTypes = {
  max: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default SymbolCounter;
