import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Icon } from './logo.svg';

import './style.scss';

export default ({ className, template = 'default' }) => (
  <Link
    className={`pst-logo pst-logo--${template} ${className}`}
    to="/"
  >
    <span className='pst-logo__wrapper'>
      <Icon/>
    </span>
  </Link>
);
