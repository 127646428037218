import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@app/components/NEW_ui/Button';

export default class Radio extends Component {
  static contextTypes = {
    radioGroup: PropTypes.object,
  };

  static propTypes = {
    value: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    ucFirst: PropTypes.bool,
  };

  static defaultProps = {
    selected: false,
    ucFirst: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected,
      value: props.value,
    };
  }

  handleClick(e) {
    e.preventDefault();
    if (this.state.selected === true) return;

    const { onChange } = this.context.radioGroup;
    const selected = !this.state.selected;
    this.setState({ selected });
    onChange(selected, this, this.state.value);
  }

  setSelected(selected) {
    this.setState({ selected });
  }

  renderValue() {
    const { value, ucFirst } = this.props;

    if (!value || !ucFirst) return value;

    return value[0].toUpperCase() + value.slice(1);
  }

  render() {
    return (
      <Button
        template='select-large'
        active={this.state.selected}
        onClick={this.handleClick.bind(this)}
        disabled={this.props.disabled}>
        {this.renderValue()}
      </Button>
    );
  }
}
