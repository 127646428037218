export const COMPONENT_AUTH_IDENTIFY = 'COMPONENT_AUTH_IDENTIFY';

export const COMPONENT_DASHBOARD_FETCH = 'COMPONENT_DASHBOARD_FETCH';
export const COMPONENT_DASHBOARD_ADDTAG = 'COMPONENT_DASHBOARD_ADDTAG';
export const COMPONENT_DASHBOARD_DELETETAG = 'COMPONENT_DASHBOARD_DELETETAG';
export const COMPONENT_MYPRESENTATION_FETCH = 'COMPONENT_MYPRESENTATION_FETCH';

export const SET_ORDER = 'SET_ORDER';

export const SHOW_LOCK_SCREEN = 'SHOW_LOCK_SCREEN';
export const SHOW_DELETE_ACCOUNT_POPUP = 'SHOW_DELETE_ACCOUNT_POPUP';

export const GET_TOPIC = 'GET_TOPIC';

export const GET_PRESENTATION = 'GET_PRESENTATION';
export const GET_PRESENTATION_CHARGE = 'GET_PRESENTATION_CHARGE';
export const ERASE_DATA = 'ERASE_DATA';

export const SHOW_CANCEL_AUTOSUB_POPUP = 'SHOW_CANCEL_AUTOSUB_POPUP';
export const AUTOSUB_CANCELLATION_STATUS = 'AUTOSUB_CANCELLATION_STATUS';

export const COMPONENT_DESIGN_FETCH = 'COMPONENT_DESIGN_FETCH';
export const COMPONENT_DESIGN_UPDATE_PASTYLLA = 'COMPONENT_DESIGN_UPDATE_PASTYLLA';
export const FREE_TOPIC = 'FREE_TOPIC';
export const COMPONENT_DESIGN_SET_CURRENT_SLIDE = 'COMPONENT_DESIGN_SET_CURRENT_SLIDE';
export const FETCH_IMAGES_FROM_TEXT = 'FETCH_IMAGES_FROM_TEXT';
export const SET_IMAGES = 'SET_IMAGES';
export const REMOVE_IMGS_FROM_BRIEF = 'REMOVE_IMGS_FROM_BRIEF';
export const ADD_IMGS = 'ADD_IMGS';
export const REMOVE_IMGS_FROM_DASHBOADRD = 'REMOVE_IMGS_FROM_DASHBOADRD';

export const SHOW_SERTIFICATE_POPUP = 'SHOW_SERTIFICATE_POPUP';
export const SERTIFICATE_STATUS = 'SERTIFICATE_STATUS';
export const DELETE_ACCOUNT_STATUS = 'DELETE_ACCOUNT_STATUS';

export const FETCH_QUIZZES = 'FETCH_QUIZZES';
export const SHOW_QUIZ = 'SHOW_QUIZ';
export const HIDE_QUIZ = 'HIDE_QUIZ';
