import React from 'react';

export default () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-2-inside-1" fill="white">
      <rect x="19" y="19" width="26" height="26" rx="2" />
    </mask>
    <rect x="19" y="19" width="26" height="26" rx="2" stroke="#FA3E5F" strokeWidth="6" mask="url(#path-2-inside-1)" />
    <path d="M39 15H17C15.8954 15 15 15.8954 15 17V39" stroke="#FA3E5F" strokeWidth="3" />
  </svg>
);
