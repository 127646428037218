import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { init, update } from '@helpers/adaptiveHeight';

// eslint-disable-next-line import/no-cycle
import SlidesSlider from '../SlidesSlider';

import './style.scss';

const Slide = ({
  showActions,
  children,
  templates,
  template,
  _id,
  onTemplateSelect,
  setSlide,
  designId,
  showSlider = false,
  isBought,
}) => {
  const [firstRender, handler] = useState(true);
  const iframe = useRef(null);

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '0px 0px 300px 0px',
  });

  useEffect(() => {
    if (inView) {
      const _document = iframe.current.contentDocument;
      _document.body.innerHTML = children;

      if (!isBought) {
        const textElement = _document.querySelector('.ah');

        if (!textElement.classList.contains('a__a')) {
          textElement.style.textShadow = '#000 0 0 1rem';
          textElement.style.color = 'hsla(0,0%,0%,0)';
        }
      }

      if (!firstRender) update(_document.body);
      else {
        handler(false);
        init(_document.body);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, inView, isBought]);

  return (
    <div className={`pst-slideItem pst-slideItem--${template}`} ref={ref}>
      <iframe className="pst-slideItem__content" ref={iframe} scrolling="no" />

      <div className="pst-slideItem__clickable" onClick={setSlide}>
        {showActions && inView && (
          <div className={`pst-slideItem__sliderImagesContainer pst-slideItem__sliderImagesContainer--${showSlider ? 'active' : 'inactive'}`}>
            <SlidesSlider
              templates={templates}
              slideId={_id}
              designId={designId}
              onClick={onTemplateSelect}
              isBought={isBought}
            />
          </div>
        )}
      </div>

    </div>
  );
};

export default Slide;
