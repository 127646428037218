import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@app/components/NEW_ui/Button';
import Text from '@app/components/NEW_ui/Text';
import Icon from '@app/components/NEW_ui/Icon';

import './style.scss';

const StoryTypeSelect = ({ story, interactive }) => {
  const { t } = useTranslation();

  return (
    <div className="pst-storyTypeSelect">
      <div className="pst-storyTypeSelect__title">
        <Text level="2" weight={800} inline>{t('storyTypeSelect.title')}</Text>
      </div>

      <div className="pst-storyTypeSelect__message">
        <Text level="6" template='grey' inline>{t('storyTypeSelect.message')}</Text>
      </div>

      <div className="pst-storyTypeSelect__selectType">
        <Button
          template='select-large'
          onClick={story}
          className="pst-storyTypeSelect__select"
        >
          <Icon name="Book" template="white" />
          {t('storyTypeSelect.storyBtn')}
        </Button>

        <Button
          template='select-large'
          onClick={interactive}
          className="pst-storyTypeSelect__select"
        >
          <Icon name="Zap" />
          {t('storyTypeSelect.interactiveBtn')}
        </Button>
      </div>
    </div>
  );
};

StoryTypeSelect.defaultProps = {
  story: () => { },
  interactive: () => { },
};

StoryTypeSelect.propTypes = {
  story: PropTypes.func,
  interactive: PropTypes.func,
};

export default StoryTypeSelect;
