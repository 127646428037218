import React from 'react';

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    fill='none'
    viewBox='0 0 70 50'
  >
    <path
      fill='#000'
      d='M15 0c0 10.2-4.8 15-15 15 10.2 0 15 4.8 15 15 0-10.2 4.8-15 15-15-10.2 0-15-4.8-15-15zM25 30c0 6.8-3.2 10-10 10 6.8 0 10 3.2 10 10 0-6.8 3.2-10 10-10-6.8 0-10-3.2-10-10z'
    ></path>
    <path
      fill='#000'
      d='M45 0c0 17-8 25-25 25 17 0 25 8 25 25 0-17 8-25 25-25-17 0-25-8-25-25z'
    ></path>
  </svg>
);
