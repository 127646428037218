import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import replaceSpaces from '@app/helpers/replaceSpaces';
import Text from '@app/components/NEW_ui/Text';
import Textarea from '@app/components/NEW_ui/Textarea';
import QuestionInfo from '@app/components/NEW_ui/QuestionInfo';
import Button from '@app/components/NEW_ui/Button';
import Icon from '@app/components/NEW_ui/Icon';

import './style.scss';

const TextEditing = ({
  changeText,
  contains,
  answers,
  questions,
  title,
  handleNextStep,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const renderFields = contains.filter(
    item => item.field !== 'background'
      && item.field !== 'counter'
      && !item.field.includes('system_')
      && item.param,
  );

  const handleChangeTextarea = (e, index) => {
    const value = e ? e.target.value : '';
    const content = replaceSpaces(value);

    const questionData = questions.find(question => question.name === renderFields[index].param);
    const max = questionData.settings.symbols;
    const newContent = content.length < max ? content : content.slice(0, max);

    const field = {
      [renderFields[index].param]: newContent,
    };
    setData(field);
  };

  const handleBlur = () => {
    const obj = {};

    Object.keys(data).forEach((item) => {
      obj[item] = data[item];
      return null;
    });

    changeText(obj);
  };

  return (
    <div className='pst-textEditing' >
      <div className='pst-textEditing__title'>
        <Text
          weight={800}
          level='2'
          template='default'
          className='pst-textEditing__title-text'
        >
          {title}
        </Text>
      </div>

      <div className='pst-textEditing__description'>
        <Text
          level='6'
          template='default'
          className='pst-textEditing__description-text'
        >
          {t('slideEditor.textDescription')}
        </Text>
      </div>

      <div className='pst-textEditing__fieldsWrap'>
        {renderFields.map((item, index) => {
          const defaultAnswer = answers.find(answer => answer.id === item.param);
          const questionData = questions.find(question => question.name === item.param);
          const max = questionData.settings.symbols;
          const value = data[item.param] || (data[item.param] === '' && ' ') || defaultAnswer.value;
          const { title: titleQuestion, description, recommendation } = questionData;

          return (
            <div className='pst-textEditing__textarea' key={item._id}>
              <QuestionInfo
                heading={titleQuestion}
                description={description}
                recommendations={recommendation} />
              <Textarea
                name={item.field}
                onBlur={handleBlur}
                onChange={e => handleChangeTextarea(e, index)}
                value={value}
                withAutoSize
                counter
                max={max}
              />
            </div>
          );
        })}
      </div>

      <Button
        template='select-large'
        onClick={handleNextStep}
        classNames='pst-textEditing__nextButton'
      >
        <Icon name='Picture' size='sm' template='white' />
        {t('slideEditor.nextButton')}
      </Button>
    </div>
  );
};

export default TextEditing;
