import React from 'react';

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    fill='none'
    viewBox='0 0 20 20'
  >
    <circle cx='10' cy='10' r='7' fill='#FAE5C8'></circle>
    <path
      fill='#000'
      d='M10.028 2.774c-4.032 0-6.68 2.896-7.232 6.249 2.09 1.358 8.945 1.058 10.734-1.436.5 1.5 2.578 1.94 3.578 1.94-.1-3.578-3.049-6.753-7.08-6.753z'
    ></path>
  </svg>
);
