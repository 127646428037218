import petrovich from 'petrovich';
import isRussianLanguage from './isRussianLanguage';

/** *
 * Смотрим, если последняя буква не "y", то добавляем в конец paramValue " 's ".
 * Если, "y", то оставляем все кроме последнего символа буквы и добавляем в конец paramValue "ies".
 * @param name
 */
const enDeclension = (name) => {
  const arr = name.split('');
  const len = arr.length - 1;
  const isNotY = arr[len] !== 'y';
  return isNotY ? `${arr.join('')}'s` : `${name.substring(0, len)}ies`;
};

export default (finalGender, replacement, caseName) => (isRussianLanguage() ? petrovich({ gender: finalGender, first: replacement }, caseName).first : enDeclension(replacement));
