import React from 'react';
import PropTypes from 'prop-types';
import getClassName from '@app/helpers/getClassName';

const Tabs = ({
  elementType,
  className,
  style,
  children,
  ...extraProps
}) => {
  const enhancedChildren = React.Children.map(children, (tab) => {
    if (!tab) return;

    if (typeof tab.type === 'string') {
      return tab;
    }

    return React.cloneElement(tab, extraProps);
  });

  const El = elementType;

  return (
    <El className={getClassName(['pst-tabs', className])} style={style}>
      {enhancedChildren}
    </El>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  elementType: PropTypes.string,
};

Tabs.defaultProps = {
  elementType: 'div',
};

export default Tabs;
