import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabPane } from '@app/components/NEW_ui/Tabs';
import Icon from '@app/components/NEW_ui/Icon';
import './style.scss';

export default ({ showStep, changeStepHandler, forMobile }) => {
  const { t } = useTranslation();

  const handleChangeTab = (step) => {
    changeStepHandler(step);
  };

  return (
    <div className={`pst-tabsDesign ${forMobile ? 'pst-tabsDesign--forMobile' : ''}`}>
      <TabPane
        className='pst-tabsDesign__button'
        onClick={() => handleChangeTab('step1')}
        active={showStep === 'step1'}
      >
        <Icon name='Edit' size='sm' template={showStep === 'step1' ? 'purple' : 'gray'} />
        <span>{t('designSteps.step1')}</span>
      </TabPane>

      <TabPane
        className='pst-tabsDesign__button'
        onClick={() => handleChangeTab('step2')}
        active={showStep === 'step2'}
      >
        <Icon name='Picture' size='sm' template={showStep === 'step2' ? 'purple' : 'gray'} />
        <span>{t('designSteps.step2')}</span>
      </TabPane>
    </div>
  );
};
