// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';

export default function useWindowSize() {
  const [dimension, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    const debouncedResizeHandler = debounce(() => {
      setDimension({ width: window.innerWidth, height: window.innerHeight });
    }, 100);
    window.addEventListener('resize', debouncedResizeHandler);
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []);
  return dimension;
}

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
    }, ms);
  };
}
