import mixpanel from 'mixpanel-browser';

const { REACT_APP_MIXPANEL_TOKEN, REACT_APP_MIXPANEL_USE } = process.env;

class MixpanelService {
  isInit = false;

  constructor() {
    this.init();
  }

  init() {
    // env variables are always strings
    if (REACT_APP_MIXPANEL_USE === 'true') {
      mixpanel.init(REACT_APP_MIXPANEL_TOKEN);
      // init config...
      mixpanel.set_config({
        ip: false, // block location(Region, City, Country) data
        debug: REACT_APP_MIXPANEL_USE === 'false', // debug mode is off
        property_blacklist: [
          '$browser',
          '$screen_width',
          '$screen_height',
          '$browser_version',
          '$os',
        ], // never be sent with track() calls
      });

      this.isInit = true;
    }
  }

  setUser(data) {
    const { _id, email, name } = data;

    if (this.isInit) {
      mixpanel.identify(_id);
      // set User Data...
      mixpanel.people.set({
        Name: name,
        Email: email,
        ID: _id,
        'Upgrade date': new Date(),
        'Sessions per user': 1,
      });
    }
  }

  updateUser(id) {
    if (this.isInit) {
      mixpanel.identify(id);
      mixpanel.people.set({ 'Upgrade date': new Date() });
      mixpanel.people.increment('Sessions per user');
    }
  }

  track(eventKey, eventData) {
    if (this.isInit) mixpanel.track(eventKey, eventData);
  }
}

export default new MixpanelService();
