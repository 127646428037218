import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-shadow,no-unused-vars
function ProblemContent({ options, fixed, isSelected, handleSelect, handleSubmit }) {
  const { t } = useTranslation();

  return (
    <div className={`pst-problemQuiz ${fixed ? 'pst-problemQuiz--fixed' : ''}`}>
      <div className='pst-problemQuiz__content'>
        <div className='pst-problemQuiz__main'>
          <div className='pst-problemQuiz__title'>
            {t('problemQuiz.title')}
          </div>
          <div className='pst-problemQuiz__description'>
            {t('problemQuiz.description')}
          </div>
        </div>
      </div>

      <div className='pst-problemQuiz__optionsWrapper'>
        {options.map(item => <div
          className={`pst-problemQuiz__optionButton ${isSelected(item) && 'pst-problemQuiz__selected'}`}
          onClick={() => handleSelect(item)}>
          {item}
        </div>)}
      </div>

      <div className={'pst-problemQuiz__submitButton'} onClick={() => handleSubmit()}>
        {t('problemQuiz.submit')}
      </div>
    </div>
  );
}

export default ProblemContent;
