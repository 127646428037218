import React from 'react';

export default ({ alternative = false, template }) => {
  let color;

  switch (template) {
    case 'white':
      color = '#fff';
      break;
    case 'green':
      color = '#0BD92D';
      break;
    default:
      color = '#fff';
  }

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 10L8 15L17 6" stroke={alternative ? color : 'black'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
