import Cookies from 'js-cookie';

class CookiesService {
  constructor() {
    this.expires = 36500;
  }

  // eslint-disable-next-line class-methods-use-this
  getCookie(name) {
    return Cookies.get(name);
  }

  setCookie(name, value = 'true', options = {}) {
    Cookies.set(name, value, { expires: this.expires, ...options });
  }

  // eslint-disable-next-line class-methods-use-this
  deleteCookie(name, options = {}) {
    Cookies.remove(name, { ...options });
  }

  // eslint-disable-next-line class-methods-use-this
  clearTourCookies(cookies) {
    cookies.map(item => Cookies.remove(item));
  }
}

// export session cookies for the tour
export const BRIEF = 'shownBrief';
export const FEEDBACK = 'shownFeedback';
export const FEEDBACK_VIEWER = 'shownFeedbackViewer';
export const SEND_VIEWER_FEEDBACK = 'sendFeedbackViewer';
export const SHOW_PAYMENT = 'showPayment';
export const PAYMENT_PERIOD = 'paymentPeriod';
export const LOGIN_FACEBOOK = 'loginFacebook';
export const LOGIN_VKONTAKTE = 'loginVkontakte';
export const LOGIN_ODNOKLASSNIKI = 'loginOdnoklassniki';
export const LOGIN_GOOGLE = 'loginGoogle';
export const HIDE_LOCK_SCREEN_AFTER_LOGIN = 'hideLockScreenAfterLogin';
export const HIDE_PROBLEM_QUIZ = 'hideProblemQuiz';
export const HIDE_FEEDBACK_QUIZ = 'hideFeedbackQuiz';

export default new CookiesService();
