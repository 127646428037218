import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Text from '@app/components/NEW_ui/Text';

import './style.scss';

const PaymentStatusCard = ({ link, isSuccess, title, description, children }) => {
  const { t } = useTranslation();
  const status = isSuccess ? 'success' : 'fail';

  return (
    <div className="pst-paymentStatusCard">
      <div className={`pst-paymentStatusCard__hanger pst-paymentStatusCard__hanger--${status}`}>
        <div className="pst-paymentStatusCard__subtract"></div>
      </div>

      <div className="pst-paymentStatusCard__content">
        <div className="pst-paymentStatusCard__title">
          <Text level="2" weight={800} inline>{title}</Text>
        </div>

        <div className="pst-paymentStatusCard__message">
          <Text level="6" template="grey" inline>{description}</Text>
        </div>

        {link && <div className="pst-paymentStatusCard__backLink">
          <Link to={link} className="pst-paymentStatusCard__link">{t(`paymentStatus.backLink.${status}`)}</Link>
        </div>}

        {children && <div className="pst-paymentStatusCard__childrens">{children}</div>}
      </div>
    </div>
  );
};

PaymentStatusCard.defaultProps = {
  link: '',
  isSuccess: false,
  title: '',
  description: '',
};

PaymentStatusCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  isSuccess: PropTypes.bool,
};

export default PaymentStatusCard;
