import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';

import CardPresentation from '@app/components/NEW_ui/CardPresentation';
import Text from '@app/components/NEW_ui/Text';

import './style.scss';

const MyPresentation = ({ myPresentation, deleteMethod, history }) => {
  const { t } = useTranslation();

  const handleClickPres = (e, id, file, title) => {
    const icon = e.target.closest('.pst-card-presentation__icon');
    const confirmation = e.target.closest('.pst-card-presentation__confirmation-actionBtn');
    if (!icon && !confirmation) {
      const { created_at: created, updated_at: updated, size } = file;

      MixpanelService.track(mixpanelMap.CONTINUE_PRESENTATION, {
        size,
        name: title,
        created_at: new Date(created),
        updated_at: new Date(updated),
      });
      history.push(`/${id}/viewer`);
    }
  };

  return (
    <div className='pst-myPresentation'>
      {myPresentation.length > 0 && (
        <div className='pst-myPresentationList'>
          {myPresentation.map(({ _id, files, title }) => (
            <div
              key={_id}
              className='pst-myPresentationList__item'
              onClick={e => handleClickPres(e, _id, files[files.length - 1], title)}
            >
              <CardPresentation timeChange={files[files.length - 1].updated_at} title={title} _id={_id} deleteMethod={deleteMethod} />
            </div>
          ))}
        </div>
      )}

      {myPresentation.length === 0 && (
        <div className='pst-myPresentationList__no-info'>
          <Text level="4" template="default" weight={600} inline>{t('dashboard.emptyList')}</Text>
        </div>
      )}
    </div>
  );
};

MyPresentation.propTypes = {
  myPresentation: PropTypes.array,
};

export default withRouter(MyPresentation);
