/* eslint-disable no-console */
import http from './HttpService';

export const fetchDesign = design => http.get(`/account/design/${design}`)
  .then(({ data }) => data);

export const fetchDesignBySlideType = (_type, title) => http.get(`/account/design/${_type}/${title}`)
  .then(({ data }) => data);

export const translateText = strings => http.post('/amazon/translate', JSON.stringify(strings), {
  headers: {
    'Content-Type': 'application/json',
  },
}).then(({ data }) => data)
  .catch(err => console.error(err));

export const analyzeText = text => http.post('/analytics/text/ru/key-phrases', JSON.stringify(text), {
  headers: {
    'Content-Type': 'text/plain',
  },
}).then(({ data }) => data)
  .catch(err => console.error(err));

export const fetchSlideDesign = (design, slide) => http.get(`/account/design/${design}/${slide}`)
  .then(({ data }) => data);

export const getImages = imageQuery => http.get(`stock/unsplash?query=${imageQuery}`)
  .then(({ data }) => data)
  .catch(err => console.error(err));
