import {
  GET_PRESENTATION,
  GET_PRESENTATION_CHARGE,
  ERASE_DATA,
} from '../constants/actionTypes';

const initialState = {
  presentation: null,
  chargeData: {
    promo: null,
    promoAmount: null,
    monthAmount: null,
    yearAmount: null,
    monthDiscount: null,
    yearDiscount: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESENTATION:
      return {
        ...state,
        presentation: action.presentation,
      };
    case GET_PRESENTATION_CHARGE:
      return {
        ...state,
        chargeData: {
          promo: action.chargeData.promo,
          promoAmount: action.chargeData.promoAmount,
          monthAmount: action.chargeData.monthAmount,
          yearAmount: action.chargeData.yearAmount,
          monthDiscount: action.chargeData.monthDiscount,
          yearDiscount: action.chargeData.yearDiscount,
        },
      };
    case ERASE_DATA:
      return {
        ...state,
        presentation: null,
        chargeData: {
          promo: null,
          promoAmount: null,
          monthAmount: null,
          yearAmount: null,
          monthDiscount: null,
          yearDiscount: null,
        },
      };
    default:
      return state;
  }
};
