import axios from 'axios';
import serializeRoistatQuery from '@app/helpers/serializeRoistatQuery';

export const baseRoistatURL = process.env.REACT_APP_BASE_ROISTAT_API_URL;
export const roistartProjectID = process.env.REACT_APP_ROISTAT_PROJECT_ID;
export const formedRoistatURL = `${baseRoistatURL}/${roistartProjectID}/event/register`;

export const fetchRoistat = (event, data) => {
  const formatedQuery = serializeRoistatQuery(data);

  return axios.get(formedRoistatURL, {
    params: {
      event,
      ...formatedQuery,
    },
  });
};
