import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@app/components/NEW_ui/Button';
import './style.scss';

const Collapser = ({ children, initHeight }) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef();

  const updateHeight = () => {
    const contentHeight = ref.current.scrollHeight;
    setHeight(contentHeight);
  };

  useEffect(() => {
    updateHeight();

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [children]);

  const handleOpen = () => {
    updateHeight();
    setOpen(!open);
  };

  return (
    <div className='pst-collapser'>
      {height > initHeight && <div className='pst-collapser__openBtn'>
        <Button template='select-small' active={open} onClick={handleOpen}>
          {open ? '×' : '•••'}
        </Button>
      </div>}

      <div
        className={`pst-collapser__wrapper pst-collapser__wrapper--${open ? 'open' : 'close'}`}
        style={{ height: `${open ? height : initHeight}px` }}
      >
        <div className="pst-collapser__content" ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
};

Collapser.defaultProps = {
  initHeight: 0,
};
Collapser.propTypes = {
  initHeight: PropTypes.number,
};

export default Collapser;
