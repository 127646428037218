import React from 'react';
import SertificateCard from '@app/components/NEW_ui/SertificateCard';

import './style.scss';

const SertificatePopup = () => (
  <div className='pst-sertificatePopup'>
    <div className='pst-sertificatePopup__inner'>
      <SertificateCard />
    </div>
  </div>
);

export default SertificatePopup;
