import http from '@app/services/HttpService';
import getLanguage from '../helpers/getLanguage';

export const deleteSub = subId => http.delete(`/subscription/${subId}`).then(({ data }) => data);
export const getPrice = isIos => http.get(`/order/price?lang=${getLanguage()}&ios=${isIos}`).then(({ data }) => data);
export const charge = (period, ios) => http.post('/payment/charge', { period, ios }).then(({ data }) => data);
export const order = () => http.get('/order').then(({ data }) => data);
export const getSubscriptions = () => http.get('/subscription').then(({ data: { subscriptions } }) => subscriptions);
export const onFail = invoiceId => http.get(`/order/fail/${invoiceId}`).then(({ data }) => data);
export const onSuccess = invoiceId => http.get(`/order/success/${invoiceId}`).then(({ data }) => data);
export const cancelSubscription = subscriptionId => http.post(`/subscription/cancel/${subscriptionId}`).then(({ data }) => data);
export const payment = (chargeOptions, lang) => new Promise(((resolve, reject) => {
  let language;

  switch (lang) {
    case 'en':
      language = 'en-US';
      break;
    case 'ru':
      language = 'ru-RU';
      break;
    default:
      language = 'ru-RU';
  }

  // eslint-disable-next-line no-undef
  const widget = new cp.CloudPayments({ language });

  widget.charge(chargeOptions,
    async ({ invoiceId }) => {
      try {
        await onSuccess(invoiceId);
        resolve();
      } catch (e) {
        reject(e);
      }
    },
    async (reason, { invoiceId }) => {
      if (reason !== 'User has cancelled') {
        try {
          await onFail(invoiceId);
        } finally {
          reject(reason);
        }
      } else {
        reject(reason);
      }
    });
}));
