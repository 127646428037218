import Angle from './Angle';
import Arrow from './Arrow';
import Comment from './Comment';
import Edit from './Edit';
import Picture from './Picture';
import Play from './Play';
import Share from './Share';
import Download from './Download';
import Upload from './Upload';
import Close from './Close';
import Done from './Done';
import Menu from './Menu';
import CommentOutlined from './CommentOutlined';
import Presentation from './Presentation';
import Trash from './Trash';
import Question from './Question';
import Eye from './Eye';
import EyeOff from './EyeOff';
import Palette from './Palette';
import EditOutlined from './EditOutlined';
import DoneOutlined from './DoneOutlined';
import CommentSmall from './CommentSmall';
import PictureSmall from './PictureSmall';
import EditSmall from './EditSmall';
import Babel from './Babel';
import Burger from './Burger';
import BackArrow from './BackArrow';
import PlayColored from './PlayColored';
import WhiteArrow from './WhiteArrow';
import Copy from './Copy';
import Gmail from './Gmail';
import ArrowLine from './ArrowLine';
import Boy from './Boy';
import Girl from './Girl';
import Stars from './Stars';
import Plus from './Plus';
import Book from './Book';
import Lock from './Lock';
import Zap from './Zap';
import FingerSwipe from './FingerSwipe';
import Like from './Like';
import Dislike from './Dislike';
import FullscreenOpen from './FullscreenOpen';
import FullscreenClose from './FullscreenClose';
import AAIcon from './AAIcon';
import ResizeArrows from './ResizeArrows';
import RotateDevice from './RotateDevice';
import Audio from './Audio';
import PauseIcon from './PauseIcon';
import PlayAudio from './PlayAudio';
import DictorWoman from './DictorWoman';
import DictorWoman2 from './DictorWoman2';
import DictorMan from './DictorMan';

// socials icons
import Vk from './Vk';
import Ok from './Ok';
import Reddit from './Reddit';
import Twitter from './Twitter';
import Telegram from './Telegram';
import Facebook from './Facebook';
import Google from './Google';
import GoogleSignin from './GoogleSignin';
// фикс кодировки
export default {
  Arrow,
  Comment,
  Edit,
  Picture,
  Menu,
  Angle,
  Share,
  Download,
  Upload,
  Play,
  CommentOutlined,
  Question,
  Close,
  Presentation,
  Trash,
  CommentSmall,
  PictureSmall,
  Palette,
  EditOutlined,
  EditSmall,
  Done,
  DoneOutlined,
  Eye,
  EyeOff,
  Babel,
  Burger,
  BackArrow,
  PlayColored,
  WhiteArrow,
  Vk,
  Ok,
  Reddit,
  Twitter,
  Telegram,
  Facebook,
  Google,
  GoogleSignin,
  Copy,
  Gmail,
  ArrowLine,
  Boy,
  Girl,
  Stars,
  Plus,
  Book,
  Lock,
  Zap,
  FingerSwipe,
  Like,
  Dislike,
  FullscreenOpen,
  FullscreenClose,
  AAIcon,
  ResizeArrows,
  RotateDevice,
  Audio,
  PauseIcon,
  PlayAudio,
  DictorWoman,
  DictorWoman2,
  DictorMan,
};
