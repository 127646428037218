import React from 'react';

export default ({ template }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='14'
    fill='none'
    viewBox='0 0 12 14'
  >
    <path
      fill={template}
      fillRule='evenodd'
      d='M2 5.686V4.333a4 4 0 118 0v1.353c.392.024.67.078.908.199a2 2 0 01.874.874c.218.428.218.988.218 2.108v1.6c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874c-.428.218-.988.218-2.108.218H3.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C0 12.147 0 11.587 0 10.467v-1.6c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874c.238-.121.516-.175.908-.199zm1.333-1.353a2.667 2.667 0 015.334 0v1.334H3.333V4.334zm3 5.61a1 1 0 10-.666 0V11a.333.333 0 00.666 0V9.943z'
      clipRule='evenodd'
    ></path>
  </svg>
);
