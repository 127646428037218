import React from 'react';

import './style.scss';

const SocialBtn = ({ className, template, bgColor, onClick, children }) => (
  <div
    className={`pst-socialBtn pst-socialBtn--${template} ${className}`}
    style={{ backgroundColor: `${bgColor}` }}
    onClick={onClick}
  >
    {children}
  </div>
);

export default SocialBtn;
